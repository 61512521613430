import {createSlice} from "@reduxjs/toolkit";

const initialGuideDocumentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideDocumentForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guideDocumentsSlice = createSlice({
  name: "guideDocuments",
  initialState: initialGuideDocumentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideDocumentById
    guideDocumentFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideDocumentForEdit = action.payload.guideDocumentForEdit;
      state.error = null;
    },
    // findGuideDocuments
    guideDocumentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuideDocument
    guideDocumentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guideDocument);
    },
    // updateGuideDocument
    guideDocumentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiDocumentId === action.payload.guideDocument.guiDocumentId) {
          return action.payload.guideDocument;
        }
        return entity;
      });
    },
    // deleteGuideDocument
    guideDocumentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiDocumentId !== action.payload.guiDocumentId);
    },
    // deleteGuideDocuments
    guideDocumentsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiDocumentId)
      );
    },
  }
});
