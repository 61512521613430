import {createSlice} from "@reduxjs/toolkit";

const initialSchPublicationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  schPublicationForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const schPublicationsSlice = createSlice({
  name: "schPublications",
  initialState: initialSchPublicationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSchPublicationById
    schPublicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.schPublicationForEdit = action.payload.schPublicationForEdit;
      state.error = null;
    },
    // findSchPublications
    schPublicationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSchPublication
    schPublicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.schPublication);
    },
    // updateSchPublication
    schPublicationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schPublicationId === action.payload.schPublication.schPublicationId) {
          return action.payload.schPublication;
        }
        return entity;
      });
    },
    // deleteSchPublication
    schPublicationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schPublicationId !== action.payload.schPublicationId);
    },
    // deleteSchPublications
    schPublicationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schPublicationId)
      );
    },
  }
});
