import * as requestFromServer from "./schPayReceiptsCrud";
import { schPayReceiptsSlice, callTypes } from "./schPayReceiptsSlice";

const { actions } = schPayReceiptsSlice;

export const fetchSchPayReceipts = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchPayReceipts(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schPayReceiptsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schPayReceipts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchPayReceipt = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schPayReceiptFetched({ schPayReceiptForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchPayReceiptById(id)
    .then((response) => {
      const schPayReceipt = response.data;
      dispatch(actions.schPayReceiptFetched({ schPayReceiptForEdit: schPayReceipt }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schPayReceipt";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchPayReceipt = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchPayReceipt(id)
    .then((response) => {
      dispatch(actions.schPayReceiptDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schPayReceipt";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchPayReceipt = (schPayReceiptForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schPayReceiptForCreation.isActive = true;
  return requestFromServer
    .createSchPayReceipt(schPayReceiptForCreation)
    .then((response) => {
      const { schPayReceipt } = response.data;
      schPayReceipt.id = schPayReceipt.schPayReceiptId;
      dispatch(actions.schPayReceiptCreated({ schPayReceipt }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schPayReceipt";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchPayReceipt = (schPayReceipt) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchPayReceipt(schPayReceipt)
    .then(() => {
      dispatch(actions.schPayReceiptUpdated({ schPayReceipt }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schPayReceipt";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchPayReceipts = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchPayReceipts(ids)
    .then(() => {
      dispatch(actions.schPayReceiptsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schPayReceipts";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
