import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SchJobInfosUIHelpers";

const SchJobInfosUIContext = createContext();

export function useSchJobInfosUIContext() {
  return useContext(SchJobInfosUIContext);
}

export const SchJobInfosUIConsumer = SchJobInfosUIContext.Consumer;

export function SchJobInfosUIProvider({ schJobInfosUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newSchJobInfoButtonClick: schJobInfosUIEvents.newSchJobInfoButtonClick,
    openEditSchJobInfoPage: schJobInfosUIEvents.openEditSchJobInfoPage,
    openDeleteSchJobInfoDialog: schJobInfosUIEvents.openDeleteSchJobInfoDialog,
    openDeleteSchJobInfosDialog: schJobInfosUIEvents.openDeleteSchJobInfosDialog,
    openFetchSchJobInfosDialog: schJobInfosUIEvents.openFetchSchJobInfosDialog,
  };

  return (
    <SchJobInfosUIContext.Provider value={value}>
      {children}
    </SchJobInfosUIContext.Provider>
  );
}
