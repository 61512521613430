import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Select({
  field,
  form: { touched, errors },
  label,
  withFeedbackLabel = false,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  // const [field, meta] = useField(props);
  // const { touched, error } = meta;
  return (
    <>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={errors}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
