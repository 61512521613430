import {createSlice} from "@reduxjs/toolkit";

const initialScholarConferencesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarConferenceForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const scholarConferencesSlice = createSlice({
  name: "scholarConferences",
  initialState: initialScholarConferencesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScholarConferenceById
    scholarConferenceFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarConferenceForEdit = action.payload.scholarConferenceForEdit;
      state.error = null;
    },
    // findScholarConferences
    scholarConferencesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createScholarConference
    scholarConferenceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.scholarConference);
    },
    // updateScholarConference
    scholarConferenceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schConferenceId === action.payload.scholarConference.schConferenceId) {
          return action.payload.scholarConference;
        }
        return entity;
      });
    },
    // deleteScholarConference
    scholarConferenceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schConferenceId !== action.payload.schConferenceId);
    },
    // deleteScholarConferences
    scholarConferencesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schConferenceId)
      );
    },
  }
});
