import * as requestFromServer from "./guiPatentsCrud";
import { guiPatentsSlice, callTypes } from "./guiPatentsSlice";

const { actions } = guiPatentsSlice;

export const fetchGuiPatents = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiPatents(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiPatentsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiPatents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiPatent = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiPatentFetched({ guiPatentForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiPatentById(id)
    .then((response) => {
      const guiPatent = response.data;
      dispatch(actions.guiPatentFetched({ guiPatentForEdit: guiPatent }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiPatent";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiPatent = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiPatent(id)
    .then((response) => {
      dispatch(actions.guiPatentDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiPatent";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiPatent = (guiPatentForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiPatentForCreation.isActive = true;
  return requestFromServer
    .createGuiPatent(guiPatentForCreation)
    .then((response) => {
      const { guiPatent } = response.data;
      guiPatent.id = guiPatent.guiPatentId;
      dispatch(actions.guiPatentCreated({ guiPatent }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiPatent";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiPatent = (guiPatent) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiPatent(guiPatent)
    .then(() => {
      dispatch(actions.guiPatentUpdated({ guiPatent }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiPatent";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiPatents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiPatents(ids)
    .then(() => {
      dispatch(actions.guiPatentsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiPatents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
