import React, { useState } from "react";
import axios from "axios";

const API_BASE = `${process.env.REACT_APP_API_URL}/api/pagecontent`;

function submitForm(contentType, data, setResponse) {
  axios({
    url: API_BASE,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": contentType,
    },
  })
    .then((response) => {
      setResponse(response.data);
    })
    .catch((error) => {
      setResponse("error");
    });
}

function BaseFile() {
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [webMenuId, setWebMenuId] = useState("");
  const [sectionImage, setSectionImage] = useState(null);
  const [pageContentTag, setPageContentTag] = useState("");
  const [pageContentSynopsys, setPageContentSynopsys] = useState("");

  async function uploadWithJSON() {
    const toBase64 = (sectionImage) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(sectionImage);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const data = {
      sectionTitle: sectionTitle,
      sectionDescription: sectionDescription,
      webMenuId: parseInt(webMenuId),
      sectionImage: await toBase64(sectionImage),
      pageContentTag: pageContentTag,
      pageContentSynopsys: pageContentSynopsys,
    };

    submitForm("application/json", data, (msg) => console.log(msg));
  }

  return (
    <div className="App">
      <h2>Page Content Form</h2>
      <form>
        <label>
          Section Title
          <input
            type="text"
            value={sectionTitle}
            onChange={(e) => {
              setSectionTitle(e.target.value);
            }}
            placeholder=""
          />
        </label>
        <label>
          Section Description
          <input
            type="text"
            value={sectionDescription}
            onChange={(e) => {
              setSectionDescription(e.target.value);
            }}
            placeholder=""
          />
        </label>
        <label>
          Web Menu Id
          <input
            type="text"
            value={webMenuId}
            onChange={(e) => {
              setWebMenuId(e.target.value);
            }}
            placeholder=""
          />
        </label>

        <label>
          Section Image
          <input
            type="file"
            name="sectionImage"
            onChange={(e) => setSectionImage(e.target.files[0])}
          />
        </label>
        <label>
          Page Content Tag
          <input
            type="text"
            vaue={pageContentTag}
            onChange={(e) => {
              setPageContentTag(e.target.value);
            }}
            placeholder=""
          />
        </label>
        <label>
          Page Content Synopsys
          <input
            type="text"
            vaue={pageContentSynopsys}
            onChange={(e) => {
              setPageContentSynopsys(e.target.value);
            }}
            placeholder=""
          />
        </label>

        <input type="button" value="Upload as JSON" onClick={uploadWithJSON} />
      </form>
    </div>
  );
}

export default BaseFile;
