import {createSlice} from "@reduxjs/toolkit";

const initialProgramsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  programForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const programsSlice = createSlice({
  name: "programs",
  initialState: initialProgramsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProgramById
    programFetched: (state, action) => {
      state.actionsLoading = false;
      state.programForEdit = action.payload.programForEdit;
      state.error = null;
    },
    // findPrograms
    programsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createProgram
    programCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.program);
    },
    // updateProgram
    programUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.programId === action.payload.program.programId) {
          return action.payload.program;
        }
        return entity;
      });
    },
    // deleteProgram
    programDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.programId !== action.payload.programId);
    },
    // deletePrograms
    programsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.programId)
      );
    },
  }
});
