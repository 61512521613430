import {createSlice} from "@reduxjs/toolkit";

const initialConferencesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  conferenceForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const conferencesSlice = createSlice({
  name: "conferences",
  initialState: initialConferencesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getConferenceById
    conferenceFetched: (state, action) => {
      state.actionsLoading = false;
      state.conferenceForEdit = action.payload.conferenceForEdit;
      state.error = null;
    },
    // findConferences
    conferencesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createConference
    conferenceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.conference);
    },
    // updateConference
    conferenceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.conferenceId === action.payload.conference.conferenceId) {
          return action.payload.conference;
        }
        return entity;
      });
    },
    // deleteConference
    conferenceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.conferenceId !== action.payload.conferenceId);
    },
    // deleteConferences
    conferencesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.conferenceId)
      );
    },
  }
});
