// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
//import TextField from "@material-ui/core/TextField";
import "date-fns";
//import DateFnsAdapter from "@date-io/date-fns";
import { Input } from "../../../../../../_metronic/_partials/controls";
// import {
//   FormControl,
//   FormControlLabel,
//   FormLabel,
//   RadioGroup,
//   Radio,
// } from "@material-ui/core";

// Validation schema
const SchEducationEditSchema = Yup.object().shape({
  examName: Yup.string().required("Title is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/Scholar`;

export function SchEducationEditForm({
  schEducation,
  btnRef,
  saveSchEducation,
}) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={schEducation}
        validationSchema={SchEducationEditSchema}
        onSubmit={(values) => {
          try {
            values.scholarId = parseInt(values.scholarId);
          } catch {}
          saveSchEducation(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
              Scholar Id:  {schEducation.scholarId}
               
              </div>
              <div className="form-group">
                <Field
                  name="degreeType"
                  component={Input}
                  placeholder="Degree Type"
                  label="Degree Type"
                />
              </div>
              <div className="form-group">
                <Field
                  name="rollNo"
                  component={Input}
                  placeholder="Roll No"
                  label="Roll No"
                />
              </div>
              <div className="form-group">
                <Field
                  name="examName"
                  component={Input}
                  placeholder="Exam Name"
                  label="Exam Name"
                />
              </div>
              <div className="form-group">
                <Field
                  name="passingYear"
                  component={Input}
                  placeholder="Passing Year"
                  label="passing Year"
                />
              </div>
              <div className="form-group">
                <Field
                  name="subjectStudied"
                  component={Input}
                  placeholder="Subject Studied"
                  label="Subject Studied"
                />
              </div>
              <div className="form-group">
                <Field
                  name="percentage"
                  component={Input}
                  placeholder="Percentage"
                  label="Percentage"
                />
              </div>
              <div className="form-group">
                <Field
                  name="university"
                  component={Input}
                  placeholder="University"
                  label="university"
                />
              </div>
            

              

            

              

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
