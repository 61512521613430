import {createSlice} from "@reduxjs/toolkit";

const initialSchJobInfosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  schJobInfoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const schJobInfosSlice = createSlice({
  name: "schJobInfos",
  initialState: initialSchJobInfosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSchJobInfoById
    schJobInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.schJobInfoForEdit = action.payload.schJobInfoForEdit;
      state.error = null;
    },
    // findSchJobInfos
    schJobInfosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSchJobInfo
    schJobInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.schJobInfo);
    },
    // updateSchJobInfo
    schJobInfoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schJobInfoId === action.payload.schJobInfo.schJobInfoId) {
          return action.payload.schJobInfo;
        }
        return entity;
      });
    },
    // deleteSchJobInfo
    schJobInfoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schJobInfoId !== action.payload.schJobInfoId);
    },
    // deleteSchJobInfos
    schJobInfosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schJobInfoId)
      );
    },
  }
});
