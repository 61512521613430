/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/schThesises/schThesisesActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchThesisEditForm } from "./SchThesisEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import { Profile } from "../../profile/Profile";
import axios from "axios";

const initSchThesis = {
  id: undefined,

  plagiarismPercentage: "",
  plagiarismCheckedDate: "2020-10-17T04:28:21.707Z",
  preThesisPresentation: true,
  preThesisDate: "2020-10-17T04:28:21.707Z",
  isSubmitted: true,
  dateOfthesisSubmission: "2020-10-17T04:28:21.707Z",
  softCopy: true,
  softCopySubmissionDate: "2020-10-17T04:28:21.707Z",
  hardCopy: true,
  hardCopySubmissionDate: "2020-10-17T04:28:21.707Z",
  nameOfthePannel: "",
  vivaVoceDate: "2020-10-17T04:28:21.707Z",
  shodhGanga: true,
  dateUploaded: "2020-10-17T04:28:21.707Z",
  provisinalDegreeIssuedDate: "2020-10-17T04:28:21.707Z",
  degreeIssuedDate: "2020-10-17T04:28:21.707Z",
  notifcationNumber: 0,
  referenceNumber: 0,
  migrationCertificate: true,
  migrationCertificateDate: "2020-10-17T04:28:21.707Z",
  preThesisPresentationDate: "2020-10-17T04:28:21.707Z",
};
export function SchThesisEdit({
  history,
  match: {
    params: { id, sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schThesisForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schThesises.actionsLoading,
      schThesisForEdit: state.schThesises.schThesisForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchThesis(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar Thesis";
    if (schThesisForEdit && id) {
      _title = `Edit Scholar Thesis`;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schThesisForEdit, id]);

  const saveSchThesis = (values) => {
    if (!id) {
      dispatch(actions.createSchThesis(values)).then(() =>
        backToSchThesisesList()
      );
    } else {
      dispatch(actions.updateSchThesis(values)).then(() =>
        backToSchThesisesList()
      );
    }
  };

  const btnRef = useRef();
  const saveSchThesisClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const id1 = id===undefined?'':id
  const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schThesis/${id1}`;

  const [profile, setProfile] = useState([]);
  useEffect(() => {
    const getScholar = async () => {
      const response = await axios.get(`${ScholarUrl}`);
      setProfile(response.data);
    };
    getScholar();
  }, [setProfile, ScholarUrl]);
  const ScholarId = sid ? sid : profile.scholarId;

  initSchThesis.scholarId = ScholarId;

  console.log(ScholarId);
  const backToSchThesisesList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div>
      <Profile />
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchThesisesList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchThesisClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {!actionsLoading && (
                <SchThesisEditForm
                  actionsLoading={actionsLoading}
                  schThesis={schThesisForEdit || initSchThesis}
                  btnRef={btnRef}
                  saveSchThesis={saveSchThesis}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
