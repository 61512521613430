import {createSlice} from "@reduxjs/toolkit";

const initialScholarDocumentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarDocumentForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const scholarDocumentsSlice = createSlice({
  name: "scholarDocuments",
  initialState: initialScholarDocumentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScholarDocumentById
    scholarDocumentFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarDocumentForEdit = action.payload.scholarDocumentForEdit;
      state.error = null;
    },
    // findScholarDocuments
    scholarDocumentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createScholarDocument
    scholarDocumentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.schDocument);
    },
    // updateScholarDocument
    scholarDocumentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schDocumentId === action.payload.schDocument.schDocumentId) {
          return action.payload.schDocument;
        }
        return entity;
      });
    },
    // deleteScholarDocument
    scholarDocumentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schDocumentId !== action.payload.schDocumentId);
    },
    // deleteScholarDocuments
    scholarDocumentsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schDocumentId)
      );
    },
  }
});
