import axios from "axios";

export const GUI_PUBLICATIONS_URL = `${process.env.REACT_APP_API_URL}/api/GuiPublication`;

// CREATE =>  POST: add a new guiPublication to the server
export function createGuiPublication(guiPublication) {
  return axios.post(GUI_PUBLICATIONS_URL, guiPublication);
}

// READ
export function getAllGuiPublications() {
  return axios.get(GUI_PUBLICATIONS_URL);
}

export function getGuiPublicationById(guiPublicationId) {
  return axios.get(`${GUI_PUBLICATIONS_URL}/${guiPublicationId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiPublications(queryParams) {
  return axios.post(`${GUI_PUBLICATIONS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiPublication(guiPublication) {
  return axios.put(`${GUI_PUBLICATIONS_URL}/${guiPublication.guiPublicationId}`, guiPublication);
}

// // UPDATE Status
// export function updateStatusForGuiPublications(ids, status) {
//   return axios.post(`${GUI_PUBLICATIONS_URL}/updateStatusForGuiPublications`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiPublication from the server
export function deleteGuiPublication(guiPublicationId) {
  return axios.delete(`${GUI_PUBLICATIONS_URL}/${guiPublicationId}`);
}

// DELETE GuiPublications by ids
export function deleteGuiPublications(ids) {
  return axios.post(`${GUI_PUBLICATIONS_URL}/deleteGuiPublications`, { ids });
}
