import * as requestFromServer from "./guiDocumentsCrud";
import { guiDocumentsSlice, callTypes } from "./guiDocumentsSlice";

const { actions } = guiDocumentsSlice;

export const fetchGuiDocuments = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiDocuments(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiDocumentsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiDocuments";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiDocument = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiDocumentFetched({ guiDocumentForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiDocumentById(id)
    .then((response) => {
      const guiDocument = response.data;
      dispatch(actions.guiDocumentFetched({ guiDocumentForEdit: guiDocument }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiDocument = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiDocument(id)
    .then((response) => {
      dispatch(actions.guiDocumentDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiDocument = (guiDocumentForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiDocumentForCreation.isActive = true;
  return requestFromServer
    .createGuiDocument(guiDocumentForCreation)
    .then((response) => {
      const { guiDocument } = response.data;
      guiDocument.id = guiDocument.guiDocumentId;
      dispatch(actions.guiDocumentCreated({ guiDocument }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiDocument = (guiDocument) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiDocument(guiDocument)
    .then(() => {
      dispatch(actions.guiDocumentUpdated({ guiDocument }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiDocuments = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiDocuments(ids)
    .then(() => {
      dispatch(actions.guiDocumentsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiDocuments";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
