import {createSlice} from "@reduxjs/toolkit";

const initialGuideConfsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideConfForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guideConfsSlice = createSlice({
  name: "guideConfs",
  initialState: initialGuideConfsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideConfById
    guideConfFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideConfForEdit = action.payload.guideConfForEdit;
      state.error = null;
    },
    // findGuideConfs
    guideConfsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuideConf
    guideConfCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guideConf);
    },
    // updateGuideConf
    guideConfUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiConfId === action.payload.guideConf.guiConfId) {
          return action.payload.guideConf;
        }
        return entity;
      });
    },
    // deleteGuideConf
    guideConfDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiConfId !== action.payload.guiConfId);
    },
    // deleteGuideConfs
    guideConfsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiConfId)
      );
    },
  }
});
