import {createSlice} from "@reduxjs/toolkit";

const initialUniversityInfosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  universityInfoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const universityInfosSlice = createSlice({
  name: "universityInfos",
  initialState: initialUniversityInfosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUniversityInfoById
    universityInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.universityInfoForEdit = action.payload.universityInfoForEdit;
      state.error = null;
    },
    // findUniversityInfos
    universityInfosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUniversityInfo
    universityInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.universityInfo);
    },
    // updateUniversityInfo
    universityInfoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.universityInfoId === action.payload.universityInfo.universityInfoId) {
          return action.payload.universityInfo;
        }
        return entity;
      });
    },
    // deleteUniversityInfo
    universityInfoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.universityInfoId !== action.payload.universityInfoId);
    },
    // deleteUniversityInfos
    universityInfosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.universityInfoId)
      );
    },
  }
});
