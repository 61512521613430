import * as requestFromServer from "./schConferencesCrud";
import { schConferencesSlice, callTypes } from "./schConferencesSlice";

const { actions } = schConferencesSlice;

export const fetchSchConferences = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchConferences(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schConferencesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schConferences";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchConference = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schConferenceFetched({ schConferenceForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchConferenceById(id)
    .then((response) => {
      const schConference = response.data;
      dispatch(actions.schConferenceFetched({ schConferenceForEdit: schConference }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schConference";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchConference = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchConference(id)
    .then((response) => {
      dispatch(actions.schConferenceDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schConference";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchConference = (schConferenceForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schConferenceForCreation.isActive = true;
  return requestFromServer
    .createSchConference(schConferenceForCreation)
    .then((response) => {
      const { schConference } = response.data;
      schConference.id = schConference.schConferenceId;
      dispatch(actions.schConferenceCreated({ schConference }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schConference";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchConference = (schConference) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchConference(schConference)
    .then(() => {
      dispatch(actions.schConferenceUpdated({ schConference }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schConference";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchConferences = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchConferences(ids)
    .then(() => {
      dispatch(actions.schConferencesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schConferences";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
