import axios from "axios";

export const GUI_JJTU_SCHOLARS_URL = `${process.env.REACT_APP_API_URL}/api/GuiJjtuScholar`;

// CREATE =>  POST: add a new guiJjtuScholar to the server
export function createGuiJjtuScholar(guiJjtuScholar) {
  return axios.post(GUI_JJTU_SCHOLARS_URL, guiJjtuScholar);
}

// READ
export function getAllGuiJjtuScholars() {
  return axios.get(GUI_JJTU_SCHOLARS_URL);
}

export function getGuiJjtuScholarById(guiJjtuScholarId) {
  return axios.get(`${GUI_JJTU_SCHOLARS_URL}/${guiJjtuScholarId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiJjtuScholars(queryParams) {
  return axios.post(`${GUI_JJTU_SCHOLARS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiJjtuScholar(guiJjtuScholar) {
  return axios.put(`${GUI_JJTU_SCHOLARS_URL}/${guiJjtuScholar.guiJjtuScholarId}`, guiJjtuScholar);
}

// // UPDATE Status
// export function updateStatusForGuiJjtuScholars(ids, status) {
//   return axios.post(`${GUI_JJTU_SCHOLARS_URL}/updateStatusForGuiJjtuScholars`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiJjtuScholar from the server
export function deleteGuiJjtuScholar(guiJjtuScholarId) {
  return axios.delete(`${GUI_JJTU_SCHOLARS_URL}/${guiJjtuScholarId}`);
}

// DELETE GuiJjtuScholars by ids
export function deleteGuiJjtuScholars(ids) {
  return axios.post(`${GUI_JJTU_SCHOLARS_URL}/deleteGuiJjtuScholars`, { ids });
}
