import {createSlice} from "@reduxjs/toolkit";

const initialGuiEducationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guiEducationForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guiEducationsSlice = createSlice({
  name: "guiEducations",
  initialState: initialGuiEducationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuiEducationById
    guiEducationFetched: (state, action) => {
      state.actionsLoading = false;
      state.guiEducationForEdit = action.payload.guiEducationForEdit;
      state.error = null;
    },
    // findGuiEducations
    guiEducationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuiEducation
    guiEducationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guiEducation);
    },
    // updateGuiEducation
    guiEducationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiEducationId === action.payload.guiEducation.guiEducationId) {
          return action.payload.guiEducation;
        }
        return entity;
      });
    },
    // deleteGuiEducation
    guiEducationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiEducationId !== action.payload.guiEducationId);
    },
    // deleteGuiEducations
    guiEducationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiEducationId)
      );
    },
  }
});
