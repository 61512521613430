import {createSlice} from "@reduxjs/toolkit";

const initialPageContentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  pageContentForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const pageContentsSlice = createSlice({
  name: "pageContents",
  initialState: initialPageContentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPageContentById
    pageContentFetched: (state, action) => {
      state.actionsLoading = false;
      state.pageContentForEdit = action.payload.pageContentForEdit;
      state.error = null;
    },
    // findPageContents
    pageContentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createPageContent
    pageContentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.pageContent);
    },
    // updatePageContent
    pageContentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.pageContentId === action.payload.pageContent.pageContentId) {
          return action.payload.pageContent;
        }
        return entity;
      });
    },
    // deletePageContent
    pageContentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.pageContentId !== action.payload.pageContentId);
    },
    // deletePageContents
    pageContentsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.pageContentId)
      );
    },
  }
});
