import {createSlice} from "@reduxjs/toolkit";

const initialInstitutesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  instituteForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const institutesSlice = createSlice({
  name: "institutes",
  initialState: initialInstitutesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getInstituteById
    instituteFetched: (state, action) => {
      state.actionsLoading = false;
      state.instituteForEdit = action.payload.instituteForEdit;
      state.error = null;
    },
    // findInstitutes
    institutesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createInstitute
    instituteCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.institute);
    },
    // updateInstitute
    instituteUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.instituteId === action.payload.institute.instituteId) {
          return action.payload.institute;
        }
        return entity;
      });
    },
    // deleteInstitute
    instituteDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.instituteId !== action.payload.instituteId);
    },
    // deleteInstitutes
    institutesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.instituteId)
      );
    },
  }
});
