import {createSlice} from "@reduxjs/toolkit";

const initialSchConferencesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  schConferenceForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const schConferencesSlice = createSlice({
  name: "schConferences",
  initialState: initialSchConferencesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSchConferenceById
    schConferenceFetched: (state, action) => {
      state.actionsLoading = false;
      state.schConferenceForEdit = action.payload.schConferenceForEdit;
      state.error = null;
    },
    // findSchConferences
    schConferencesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSchConference
    schConferenceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.schConference);
    },
    // updateSchConference
    schConferenceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schConferenceId === action.payload.schConference.schConferenceId) {
          return action.payload.schConference;
        }
        return entity;
      });
    },
    // deleteSchConference
    schConferenceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schConferenceId !== action.payload.schConferenceId);
    },
    // deleteSchConferences
    schConferencesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schConferenceId)
      );
    },
  }
});
