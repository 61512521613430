import {createSlice} from "@reduxjs/toolkit";

const initialScholarCourseWorkInfosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarCourseWorkInfoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const scholarCourseWorkInfosSlice = createSlice({
  name: "scholarCourseWorkInfos",
  initialState: initialScholarCourseWorkInfosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScholarCourseWorkInfoById
    scholarCourseWorkInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarCourseWorkInfoForEdit = action.payload.scholarCourseWorkInfoForEdit;
      state.error = null;
    },
    // findScholarCourseWorkInfos
    scholarCourseWorkInfosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createScholarCourseWorkInfo
    scholarCourseWorkInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.scholarCourseWorkInfo);
    },
    // updateScholarCourseWorkInfo
    scholarCourseWorkInfoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schCourseWorkInfoId === action.payload.scholarCourseWorkInfo.schCourseWorkInfoId) {
          return action.payload.scholarCourseWorkInfo;
        }
        return entity;
      });
    },
    // deleteScholarCourseWorkInfo
    scholarCourseWorkInfoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schCourseWorkInfoId !== action.payload.schCourseWorkInfoId);
    },
    // deleteScholarCourseWorkInfos
    scholarCourseWorkInfosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schCourseWorkInfoId)
      );
    },
  }
});
