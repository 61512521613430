import {createSlice} from "@reduxjs/toolkit";

const initialGuideJobInfosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideJobInfoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guideJobInfosSlice = createSlice({
  name: "guideJobInfos",
  initialState: initialGuideJobInfosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideJobInfoById
    guideJobInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideJobInfoForEdit = action.payload.guideJobInfoForEdit;
      state.error = null;
    },
    // findGuideJobInfos
    guideJobInfosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuideJobInfo
    guideJobInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guideJobInfo);
    },
    // updateGuideJobInfo
    guideJobInfoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiJobInfoId === action.payload.guideJobInfo.guiJobInfoId) {
          return action.payload.guideJobInfo;
        }
        return entity;
      });
    },
    // deleteGuideJobInfo
    guideJobInfoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiJobInfoId !== action.payload.guiJobInfoId);
    },
    // deleteGuideJobInfos
    guideJobInfosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiJobInfoId)
      );
    },
  }
});
