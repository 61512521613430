import axios from "axios";

export const GUI_CONFS_URL = `${process.env.REACT_APP_API_URL}/api/GuiConf`;

// CREATE =>  POST: add a new guiConf to the server
export function createGuiConf(guiConf) {
  return axios.post(GUI_CONFS_URL, guiConf);
}

// READ
export function getAllGuiConfs() {
  return axios.get(GUI_CONFS_URL);
}

export function getGuiConfById(guiConfId) {
  return axios.get(`${GUI_CONFS_URL}/${guiConfId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiConfs(queryParams) {
  return axios.post(`${GUI_CONFS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiConf(guiConf) {
  return axios.put(`${GUI_CONFS_URL}/${guiConf.guiConfId}`, guiConf);
}

// // UPDATE Status
// export function updateStatusForGuiConfs(ids, status) {
//   return axios.post(`${GUI_CONFS_URL}/updateStatusForGuiConfs`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiConf from the server
export function deleteGuiConf(guiConfId) {
  return axios.delete(`${GUI_CONFS_URL}/${guiConfId}`);
}

// DELETE GuiConfs by ids
export function deleteGuiConfs(ids) {
  return axios.post(`${GUI_CONFS_URL}/deleteGuiConfs`, { ids });
}
