import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { GuideProfile } from "./pages/profile/GuideProfile";
import { GuiConfEdit } from "./pages/guiConfs/guiConf-edit/GuiConfEdit";
import { GuiDocumentEdit } from "./pages/guiDocuments/guiDocument-edit/GuiDocumentEdit";
import { GuiPatentEdit } from "./pages/guiPatents/guiPatent-edit/GuiPatentEdit";
import { GuiEducationEdit } from "./pages/guiEducations/guiEducation-edit/GuiEducationEdit";
import { GuiJobInfoEdit } from "./pages/guiJobInfos/guiJobInfo-edit/GuiJobInfoEdit";
import  GuiAwardEdit from "./pages/guiAwards/guiAward-edit/GuiAwardEdit";
import { GuiPublicationEdit } from "./pages/guiPublications/guiPublication-edit/GuiPublicationEdit";
import { GuiJjtuScholarEdit } from "./pages/guiJjtuScholars/guiJjtuScholar-edit/GuiJjtuScholarEdit";
// import { GuiAddressEdit } from "../Guide/Pages/guiAddresses/guiAddress-edit/GuiAddressEdit";

// import { GuiAddressDeleteDialog } from "../Guide/Pages/guiAddresses/guiAddress-delete-dialog/GuiAddressDeleteDialog";
//import { GuiAwardDeleteDialog } from "../Guide/Pages/guiAwards/guiAward-delete-dialog/GuiAwardDeleteDialog";
// import { GuiConfDeleteDialog } from "../Guide/Pages/guiConfs/guiConf-delete-dialog/GuiConfDeleteDialog";
// import { GuiDocumentDeleteDialog } from "../Guide/Pages/guiDocuments/guiDocument-delete-dialog/GuiDocumentDeleteDialog";
// import { GuiEducationDeleteDialog } from "../Guide/Pages/guiEducations/guiEducation-delete-dialog/GuiEducationDeleteDialog";
// import { GuiJjtuScholarDeleteDialog } from "../Guide/Pages/guiJjtuScholars/guiJjtuScholardelete-dialog/GuiJjtuScholarDeleteDialog";
// import { GuiJobInfoDeleteDialog } from "../Guide/Pages/guiJobInfos/GuiJobInfo-delete-dialog/GuiJobInfoDeleteDialog";
// import { GuiPatentDeleteDialog } from "../Guide/Pages/guiPatents/guiPatent-delete-dialog/GuiPatentDeleteDialog";
// import { GuiPublicationDeleteDialog } from "../Guide/Pages/guiPublications/guiPublication-delete-dialog/GuiPublicationDeleteDialog";

export default function GuidePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact={true} from="/guide" to="/guide/guideprofile" />
        <ContentRoute path="/guide/guideprofile" component={GuideProfile} />
        <ContentRoute path="/guide/guiconfs/new/:gid" component={GuiConfEdit} />
        <ContentRoute
          path="/guide/guidocuments/new/:gid"
          component={GuiDocumentEdit}
        />
        <ContentRoute
          path="/guide/guieducations/new/:gid"
          component={GuiEducationEdit}
        />
        <ContentRoute
          path="/guide/guijobinfos/new/:gid"
          component={GuiJobInfoEdit}
        />
        <ContentRoute
          path="/guide/guiawards/new/:gid"
          component={GuiAwardEdit}
        />
        <ContentRoute
          path="/guide/guipublications/new/:gid"
          component={GuiPublicationEdit}
        />
        <ContentRoute
          path="/guide/guipatents/new/:gid"
          component={GuiPatentEdit}
        />
        <ContentRoute
          path="/guide/guijjtuscholars/new/:gid"
          component={GuiJjtuScholarEdit}
        />
        {/* <ContentRoute
          path="/guide/guiaddresses/new/:gid"
          component={GuiAddressEdit}
        /> */}
        <ContentRoute
          path="/guide/guijobinfos/:id/edit"
          component={GuiJobInfoEdit}
        />
        <ContentRoute
          path="/guide/guipatents/:id/edit"
          component={GuiPatentEdit}
        />
        <ContentRoute
          path="/guide/guipublications/:id/edit"
          component={GuiPublicationEdit}
        />
        <ContentRoute
          path="/guide/guijjtuscholars/:id/edit"
          component={GuiJjtuScholarEdit}
        />
        <ContentRoute
          path="/guide/guieducations/:id/edit"
          component={GuiEducationEdit}
        />
        <ContentRoute
          path="/guide/guidocuments/:id/edit"
          component={GuiDocumentEdit}
        />
        <ContentRoute path="/guide/guiconfs/:id/edit" component={GuiConfEdit} />
        <ContentRoute
          path="/guide/guiawards/:id/edit"
          component={GuiAwardEdit}
        />
        {/* <ContentRoute
          path="/guide/guiaddress/:id/edit"
          component={GuiAddressEdit}
        /> */}
      </Switch>
    </Suspense>
  );
}
