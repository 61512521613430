import * as requestFromServer from "./guiAwardsCrud";
import { guiAwardsSlice, callTypes } from "./guiAwardsSlice";

const { actions } = guiAwardsSlice;

export const fetchGuiAwards = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiAwards(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiAwardsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiAwards";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiAward = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiAwardFetched({ guiAwardForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiAwardById(id)
    .then((response) => {
      const guiAward = response.data;
      dispatch(actions.guiAwardFetched({ guiAwardForEdit: guiAward }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiAward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiAward = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiAward(id)
    .then((response) => {
      dispatch(actions.guiAwardDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiAward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiAward = (guiAwardForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiAwardForCreation.isActive = true;
  return requestFromServer
    .createGuiAward(guiAwardForCreation)
    .then((response) => {
      const { guiAward } = response.data;
      guiAward.id = guiAward.guiAwardId;
      dispatch(actions.guiAwardCreated({ guiAward }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiAward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiAward = (guiAward) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiAward(guiAward)
    .then(() => {
      dispatch(actions.guiAwardUpdated({ guiAward }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiAward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiAwards = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiAwards(ids)
    .then(() => {
      dispatch(actions.guiAwardsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiAwards";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
