import * as requestFromServer from "./schCourseWorkInfosCrud";
import { schCourseWorkInfosSlice, callTypes } from "./schCourseWorkInfosSlice";

const { actions } = schCourseWorkInfosSlice;

export const fetchSchCourseWorkInfos = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchCourseWorkInfos(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schCourseWorkInfosFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schCourseWorkInfos";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchCourseWorkInfo = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schCourseWorkInfoFetched({ schCourseWorkInfoForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchCourseWorkInfoById(id)
    .then((response) => {
      const schCourseWorkInfo = response.data;
      dispatch(actions.schCourseWorkInfoFetched({ schCourseWorkInfoForEdit: schCourseWorkInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schCourseWorkInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchCourseWorkInfo = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchCourseWorkInfo(id)
    .then((response) => {
      dispatch(actions.schCourseWorkInfoDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schCourseWorkInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchCourseWorkInfo = (schCourseWorkInfoForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schCourseWorkInfoForCreation.isActive = true;
  return requestFromServer
    .createSchCourseWorkInfo(schCourseWorkInfoForCreation)
    .then((response) => {
      const { schCourseWorkInfo } = response.data;
      schCourseWorkInfo.id = schCourseWorkInfo.schCourseWorkInfoId;
      dispatch(actions.schCourseWorkInfoCreated({ schCourseWorkInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schCourseWorkInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchCourseWorkInfo = (schCourseWorkInfo) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchCourseWorkInfo(schCourseWorkInfo)
    .then(() => {
      dispatch(actions.schCourseWorkInfoUpdated({ schCourseWorkInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schCourseWorkInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchCourseWorkInfos = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchCourseWorkInfos(ids)
    .then(() => {
      dispatch(actions.schCourseWorkInfosDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schCourseWorkInfos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
