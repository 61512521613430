import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SchEducationsUIHelpers";

const SchEducationsUIContext = createContext();

export function useSchEducationsUIContext() {
  return useContext(SchEducationsUIContext);
}

export const SchEducationsUIConsumer = SchEducationsUIContext.Consumer;

export function SchEducationsUIProvider({ schEducationsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newSchEducationButtonClick: schEducationsUIEvents.newSchEducationButtonClick,
    openEditSchEducationPage: schEducationsUIEvents.openEditSchEducationPage,
    openDeleteSchEducationDialog: schEducationsUIEvents.openDeleteSchEducationDialog,
    openDeleteSchEducationsDialog: schEducationsUIEvents.openDeleteSchEducationsDialog,
    openFetchSchEducationsDialog: schEducationsUIEvents.openFetchSchEducationsDialog,
  };

  return (
    <SchEducationsUIContext.Provider value={value}>
      {children}
    </SchEducationsUIContext.Provider>
  );
}
