import * as requestFromServer from "./guiEducationsCrud";
import { guiEducationsSlice, callTypes } from "./guiEducationsSlice";

const { actions } = guiEducationsSlice;

export const fetchGuiEducations = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiEducations(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiEducationsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiEducations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiEducation = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiEducationFetched({ guiEducationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiEducationById(id)
    .then((response) => {
      const guiEducation = response.data;
      dispatch(actions.guiEducationFetched({ guiEducationForEdit: guiEducation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiEducation = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiEducation(id)
    .then((response) => {
      dispatch(actions.guiEducationDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiEducation = (guiEducationForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiEducationForCreation.isActive = true;
  return requestFromServer
    .createGuiEducation(guiEducationForCreation)
    .then((response) => {
      const { guiEducation } = response.data;
      guiEducation.id = guiEducation.guiEducationId;
      dispatch(actions.guiEducationCreated({ guiEducation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiEducation = (guiEducation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiEducation(guiEducation)
    .then(() => {
      dispatch(actions.guiEducationUpdated({ guiEducation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiEducations = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiEducations(ids)
    .then(() => {
      dispatch(actions.guiEducationsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiEducations";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
