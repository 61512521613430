import {createSlice} from "@reduxjs/toolkit";

const initialGuidesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guidesSlice = createSlice({
  name: "guides",
  initialState: initialGuidesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideById
    guideFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideForEdit = action.payload.guideForEdit;
      state.error = null;
    },
    // findGuides
    guidesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuide
    guideCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guide);
    },
    // updateGuide
    guideUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guideId === action.payload.guide.guideId) {
          return action.payload.guide;
        }
        return entity;
      });
    },
    // deleteGuide
    guideDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guideId !== action.payload.guideId);
    },
    // deleteGuides
    guidesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guideId)
      );
    },
  }
});
