import React, { useState, useEffect } from "react";
import { Image, Container, Card, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import axios from "axios";
//import { ListGroup, Form } from "react-bootstrap";

export function Profile({ history, props, cellContent, row, rowIndex, match }) {
  const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/scholar/profile`;
  const [profile, setProfile] = useState([]);
  useEffect(() => {
    const getScholar = async () => {
      const response = await axios.get(ScholarUrl);
      setProfile(response.data);
    };
    getScholar();
  }, [setProfile, ScholarUrl]);
  //const ScholarId = profile.scholarId;
  const ScholarId = profile.scholarId===undefined?0:profile.scholarId;
  

  //const schvisitUrl = `${process.env.REACT_APP_API_URL}/api/schvisit`;
  const SchThesisUrl = `${process.env.REACT_APP_API_URL}/api/SchThesis/GetSchThesisBySchId?id=${ScholarId}`;
  //const SchPatentUrl = `${process.env.REACT_APP_API_URL}/api/SchPatent`;
  let SchJobInfoUrl = `${process.env.REACT_APP_API_URL}/api/SchJobInfo/GetSchJobInfoBySchId?id=${ScholarId}`;
  //const SchFeeInfoUrl = `${process.env.REACT_APP_API_URL}/api/SchFeeInfo`;
  const SchAddressUrl = `${process.env.REACT_APP_API_URL}/api/SchAddress/GetSchAddressBySchId?id=${ScholarId}`;

  const SchDocumentUrl = `${process.env.REACT_APP_API_URL}/api/SchDocument/GetSchDocumentBySchId?id=${ScholarId}`;
  const SchSynopsysUrl = `${process.env.REACT_APP_API_URL}/api/SchSynopsys/GetSchSynopsysBySchId?id=${ScholarId}`;
  //const SchPrintDocUrl = `${process.env.REACT_APP_API_URL}/api/SchPrintDoc`;
  const SchEducationUrl = `${process.env.REACT_APP_API_URL}/api/SchEducation/GetSchEducationBySchId?id=${ScholarId}`;
  const SchInterviewUrl = `${process.env.REACT_APP_API_URL}/api/SchInterview/GetSchInterviewBySchId?id=${ScholarId}`;
  const SchConferenceUrl = `${process.env.REACT_APP_API_URL}/api/SchConference/GetSchConferenceBySchId?id=${ScholarId}`;
  const SchPayReceiptUrl = `${process.env.REACT_APP_API_URL}/api/SchPayReceipt/GetSchPayReceiptBySchId?id=${ScholarId}`;
  const SchPublicationUrl = `${process.env.REACT_APP_API_URL}/api/SchPublication/GetSchPublicationBySchId?id=${ScholarId}`;
  //const SchPrintTemplateUrl = `${process.env.REACT_APP_API_URL}/api/SchPrintTemplate`;
  const SchCourseWorkMarkUrl = `${process.env.REACT_APP_API_URL}/api/SchCourseWorkInfo/GetSchCourseWorkInfoBySchId?id=${ScholarId}`;
  //const SchCourseWorkInfoUrl = `${process.env.REACT_APP_API_URL}/api/SchCourseWorkInfo`;
  //const SchThesisEvaluatorUrl = `${process.env.REACT_APP_API_URL}/api/SchThesisEvaluator`;

  const [document, setDocument] = useState([]);
  const [address, setAddress] = useState([]);
  const [interview, setInterview] = useState([]);
  const [thesis, setThesis] = useState([]);
  const [education, setEducation] = useState([]);
  const [courseWorkMark, setCourseWorkMark] = useState([]);
  const [synopsys, setSynopsys] = useState([]);
  const [jobInfo, setJobInfo] = useState([]);
  const [publication, setPublication] = useState([]);
  const [conference, setConference] = useState([]);
  const [payReceipt, setPayReceipt] = useState([]);

  useEffect(() => {
    const getDocument = async () => {
      const response = await axios.get(SchDocumentUrl);
      setDocument(response.data);
    };
    getDocument();
    const getThesis = async () => {
      const response = await axios.get(SchThesisUrl);
      setThesis(response.data);
    };
    getThesis();

    const getCourseWorkMark = async () => {
      const response = await axios.get(SchCourseWorkMarkUrl);
      setCourseWorkMark(response.data);
    };
    getCourseWorkMark();

    const getEducation = async () => {
      const response = await axios.get(SchEducationUrl);
      setEducation(response.data);
    };
    getEducation();

    const getSynopsys = async () => {
      const response = await axios.get(SchSynopsysUrl);
      setSynopsys(response.data);
    };
    getSynopsys();

    const getJobInfo = async () => {
      const response = await axios.get(SchJobInfoUrl);
      setJobInfo(response.data);
    };
    getJobInfo();

    const getPublication = async () => {
      const response = await axios.get(SchPublicationUrl);
      setPublication(response.data);
    };
    getPublication();

    const getConference = async () => {
      const response = await axios.get(SchConferenceUrl);
      setConference(response.data);
    };
    getConference();

    const getPayReceipt = async () => {
      const response = await axios.get(SchPayReceiptUrl);
      setPayReceipt(response.data);
    };
    getPayReceipt();

    const getInterview = async () => {
      const response = await axios.get(SchInterviewUrl);
      setInterview(response.data);
    };
    getInterview();
    const getAddress = async () => {
      const response = await axios.get(SchAddressUrl);
      setAddress(response.data);
    };
    getAddress();
  }, [
    SchAddressUrl,
    SchInterviewUrl,
    SchPayReceiptUrl,
    SchConferenceUrl,
    SchPublicationUrl,
    SchJobInfoUrl,
    SchSynopsysUrl,
    SchEducationUrl,
    SchCourseWorkMarkUrl,
    SchThesisUrl,
    SchDocumentUrl,
    setAddress,
    ScholarId,
    setThesis,
    setCourseWorkMark,
    setEducation,
    setSynopsys,
    setJobInfo,
    setPublication,
    setConference,
    setPayReceipt,
    setInterview,
    setDocument,
  ]);

  // Push to Create PAGE

  const backToSchEducationsForm = (id) => {
    history.push(`/scholar/scheducations/new/${id}`);
  };
  const backToSchCourseWorkInfosForm = (id) => {
    history.push(`/scholar/schcourseworkinfos/new/${id}`);
  };
  const backToSchConferencesForm = (id) => {
    history.push(`/scholar/schConferences/new/${id}`);
  };
  const backToSchDocumentsForm = (id) => {
    history.push(`/scholar/schdocuments/new/${id}`);
  };

  const backToPayReceiptsForm = (id) => {
    history.push(`/scholar/schpayreceipts/new/${id}`);
  };
  const backToJobInfosForm = (id) => {
    history.push(`/scholar/schjobinfos/new/${id}`);
  };
  const backToSchPublicationsForm = (id) => {
    history.push(`/scholar/schpublications/new/${id}`);
  };
  const backToSchSynopsysesForm = (id) => {
    history.push(`/scholar/schsynopsyses/new/${id}`);
  };
  const backToSchThesisesForm = (id) => {
    history.push(`/scholar/SchThesises/new/${id}`);
  };

  // Push to Edit/Update PAGE

  const openEditSchEducationPage = (schEducationId) => {
    history.push(`/scholar/schEducations/${schEducationId}/edit`);
  };
  const openEditPayReceiptsPage = (schPayReceiptId) => {
    history.push(`/scholar/schpayreceipts/${schPayReceiptId}/edit`);
  };

  const openEditSchJobInfosPage = (schJobInfoId) => {
    history.push(`/scholar/schjobinfos/${schJobInfoId}/edit`);
  };
  const openEditSchPublicationsPage = (schPublicationId) => {
    history.push(`/scholar/schpublications/${schPublicationId}/edit`);
  };
  const openEditSchSynopsysesPage = (schSynopsysId) => {
    history.push(`/scholar/schsynopsyses/${schSynopsysId}/edit`);
  };
  const openEditSchThesisesPage = (schThesisId) => {
    history.push(`/scholar/SchThesises/${schThesisId}/edit`);
  };
  const openEditSchCourseWorkInfosPage = (schCourseWorkInfoId) => {
    history.push(`/scholar/schcourseworkinfos/${schCourseWorkInfoId}/edit`);
  };
  const openEditSchConferencesPage = (schConferenceId) => {
    history.push(`/scholar/schConferences/${schConferenceId}/edit`);
  };
  const openEditSchDocumentsPage = (id) => {
    history.push(`/scholar/schdocuments/${id}/edit`);
  };

  const SCH_JOB_INFOS_URL = `${process.env.REACT_APP_API_URL}/api/SchJobInfo`;
  const deleteSchJobInfo = (schJobInfoId) => {
    axios.delete(`${SCH_JOB_INFOS_URL}/${schJobInfoId}`).then((response) => {
      history.push("/scholar/profile");
    });
  };

  const SCH_EDUCATIONS_URL = `${process.env.REACT_APP_API_URL}/api/SchEducation`;
  const deleteSchEducation = (schEducationId) => {
    axios.delete(`${SCH_EDUCATIONS_URL}/${schEducationId}`).then((response) => {
      history.push("/scholar/profile");
    });
  };

  const SCH_CONFERENCES_URL = `${process.env.REACT_APP_API_URL}/api/SchConference`;
  const deleteSchConference = (schConferenceId) => {
    axios
      .delete(`${SCH_CONFERENCES_URL}/${schConferenceId}`)
      .then((response) => {
        history.push("/scholar/profile");
      });
  };

  const SCH_COURSE_WORK_INFOS_URL = `${process.env.REACT_APP_API_URL}/api/SchCourseWorkInfo`;
  const deleteSchCourseWorkInfo = (schCourseWorkInfoId) => {
    axios
      .delete(`${SCH_COURSE_WORK_INFOS_URL}/${schCourseWorkInfoId}`)
      .then((response) => {
        history.push("/scholar/profile");
      });
  };

  const SCH_DOCUMENTS_URL = `${process.env.REACT_APP_API_URL}/api/SchDocument`;
  const deleteSchDocument = (schDocumentId) => {
    axios.delete(`${SCH_DOCUMENTS_URL}/${schDocumentId}`).then((response) => {
      history.push("/scholar/profile");
    });
  };

  const SCH_PAY_RECIEPTS_URL = `${process.env.REACT_APP_API_URL}/api/SchPayReceipt`;
  const deleteSchPayReciept = (schPayReceiptId) => {
    axios
      .delete(`${SCH_PAY_RECIEPTS_URL}/${schPayReceiptId}`)
      .then((response) => {
        history.push("/scholar/profile");
      });
  };

  const SCH_PUBLICATIONS_URL = `${process.env.REACT_APP_API_URL}/api/SchPublication`;
  const deleteSchPublication = (schPublicationId) => {
    axios
      .delete(`${SCH_PUBLICATIONS_URL}/${schPublicationId}`)
      .then((response) => {
        history.push("/scholar/profile");
      });
  };

  const SCH_SYNOPSYSES_URL = `${process.env.REACT_APP_API_URL}/api/SchSynopsys`;
  const deleteSchSynopsys = (schSynopsysId) => {
    axios.delete(`${SCH_SYNOPSYSES_URL}/${schSynopsysId}`).then((response) => {
      history.push("/scholar/profile");
    });
  };

  const SCH_THESISES_URL = `${process.env.REACT_APP_API_URL}/api/SchThesis`;
  const deleteSchThesis = (schThesisId) => {
    axios.delete(`${SCH_THESISES_URL}/${schThesisId}`).then((response) => {
      history.push("/scholar/profile");
    });
  };
  // const openDeleteSchEducationsDialog = (schEducationId) => {
  //   history.push(`/sch/schEducations/${schEducationId}/delete`);
  // };
  return (
    <div>
      <div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-xxl-3 bg border-right">
              <Container>
                <Image
                  className="profileimg"
                  style={{
                    width: "170px",
                    borderRadius: "50",
                    margin: "0px 30px",
                  }}
                  src={profile.profilePic}
                />
              </Container>
              <h4 className="font-semibold mt-5 mb-5 mgbt-xs-5">
                {!(profile.scholarName)?"-" : (profile.scholarName) } ({!(profile.batchCode)?"-" : (profile.batchCode)} )
              </h4>

              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Reg No : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                  {!(profile.registrationNo)?"-" : (profile.registrationNo) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Enrol No : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(profile.enrollmentNo)?"-" : (profile.enrollmentNo) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Contact No : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(profile.primaryContact)?"-" : (profile.primaryContact) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Subject : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(profile.subject)?"-" : (profile.subject) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> DOB : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                  {!(profile.dateofBirth)?"-" : (profile.dateofBirth) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Gender : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">{!(profile.gender)?"-" : (profile.gender) }</div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Caste : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(profile.castCategory)?"-" : (profile.castCategory) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Spouse Name : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(profile.spouseName)?"-" : (profile.spouseName) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Parent's Name : </b>
                  </label>
                  <div className="col-xs-7 mb-2 controls">
                    {!(profile.fatherName)?"-" : (profile.fatherName) }/{!(profile.motherName)?"-" : (profile.motherName) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Interview Date : </b>
                  </label>
                  <div className="col-xs-7  ml-2 controls">
                    {!(interview.interviewDate)?"-" : (interview.interviewDate) } | {!(interview.interviewType)?"-" : (interview.interviewType) }
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                {address.map((addresses) => (
                  <div className="row mgbt-xs-0">
                    <label className="col-xs-5 control-label">
                      <b> {addresses.addressType} : </b>
                    </label>
                    {addresses.addressLine1}
                    <div className="col-xs-7 mb-2  controls">
                      {addresses.addressLine2},{addresses.city},
                      {addresses.state}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-6 col-xxl-9">
              <div>
                <h2>{profile.subject}</h2>
                <h3>Guided By : {profile.guideId}</h3>

                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          My Documents
                        </Accordion.Toggle>
                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToSchDocumentsForm}
                            onClick={() => {
                              backToSchDocumentsForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="row">
                            {document.map((documents, schDocumentId) => (
                              <div
                                key={schDocumentId}
                                className="col-lg-6 mb-5 col-xxl-6"
                              >
                                {documents.docTitle} :
                                <span style={{ float: "right" }}>
                                  <a
                                    style={{ padding: "5px" }}
                                    href={documents.docPath} 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="19"
                                      height="19"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="css-i6dzq1"
                                    >
                                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                  </a>

                                  <button
                                    style={{ padding: "5px" , border: "none" ,
                                    backgroundColor: "white", }}
                                    // href="{openEditSchDocumentsPage} "
                                    onClick={() => {
                                      openEditSchDocumentsPage(
                                        documents.schDocumentId
                                      );
                                    }}
                                  >
                                    <i
                                      style={{ color: "#37b582", border: "none" }}
                                      className="fas fa-edit"
                                    ></i>
                                  </button>
                                  <button
                                    style={{ padding: "5px", border: "none" , backgroundColor: "white" }}
                                    onClick={() => {
                                      deleteSchDocument(
                                        documents.schDocumentId
                                      );
                                    }}
                                  >
                                    <i
                                      style={{ color: "#d4224f", border: "none" }}
                                      className="fas fa-trash"
                                    ></i>
                                  </button>
                                </span>
                              </div>
                            ))}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>

                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Educational Info
                        </Accordion.Toggle>
                        <span
                          style={{
                            float: "right",
                            padding: "9px 15px",
                            border: "none",
                          }}
                        >
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            // onClick={backToSchEducationsForm}
                            onClick={() => {
                              backToSchEducationsForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th>Degree</th>
                                <th>Year</th>
                                <th>Subject</th>
                                <th>Percentage</th>
                                <th>Board/ University</th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>
                              {education.map(
                                (educationalInfo, schEducationId) => (
                                  <tr key={schEducationId}>
                                    <td>{educationalInfo.degreeType}</td>
                                    <td>{educationalInfo.passingYear}</td>
                                    <td>{educationalInfo.subjectStudied}</td>
                                    <td>{educationalInfo.percentage}</td>
                                    <td>{educationalInfo.university}</td>
                                    <td className="actions">
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditSchEducationPage(
                                              educationalInfo.schEducationId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteSchEducation(
                                              educationalInfo.schEducationId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>

                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Course Work
                        </Accordion.Toggle>
                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToSchCourseWorkInfosForm}
                            onClick={() => {
                              backToSchCourseWorkInfosForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th>First Course Work StartDate</th>
                                <th>First Course Work EndDate</th>
                                <th>certificateIssueDate</th>
                                <th>markSheetIssuedDate</th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>
                              {courseWorkMark.map(
                                (courseWork, schCourseWorkInfoId) => (
                                  <tr key={schCourseWorkInfoId}>
                                    <td>{courseWork.firstCwStartDate}</td>
                                    <td>{courseWork.fristCwEndDate}</td>
                                    <td>{courseWork.certificateIssueDate}</td>
                                    <td>{courseWork.markSheetIssuedDate}</td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditSchCourseWorkInfosPage(
                                              courseWork.schCourseWorkInfoId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteSchCourseWorkInfo(
                                              courseWork.schCourseWorkInfoId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                          {/* <div className="kt-separator kt-separator--dashed"></div>
                          <br></br>
                           <Form>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Coursework Period</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Enter email"
                                />
                              </Form.Group>

                              <Form.Group as={Col} controlId="StartDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="text" placeholder=" " />
                              </Form.Group>
                              <Form.Group as={Col} controlId="EndDate">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="text" placeholder=" " />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                controlId="CertificateIssueDate"
                              >
                                <Form.Label>Certificate Issue Date</Form.Label>
                                <Form.Control type="text" placeholder=" " />
                              </Form.Group>
                              <button
                                    style={{ border: "none" }}
                                    onClick={() => {
                                      openEditSchCourseWorkInfosPage(
                                        courseWorkMark.schCourseWorkInfoId
                                      );
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </button>
                                  <button
                                    style={{ border: "none" }}
                                    onClick={() => {
                                      deleteSchCourseWorkInfo(
                                        courseWorkMark.schCourseWorkInfoId
                                      );
                                    }}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                            </Form.Row>
                          </Form>  */}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>

                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Thesis
                        </Accordion.Toggle>
                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToSchThesisesForm}
                            onClick={() => {
                              backToSchThesisesForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {thesis.map((thesises, schThesisId) => (
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="first"
                              key={schThesisId}
                            >
                              <Row>
                                <Col sm={12}>
                                  <Nav variant="pills" className="flex-column">
                                    <Col sm={12}>
                                      <Row>
                                        <Col sm={3}>
                                          <Nav.Item>
                                            <Nav.Link eventKey="first">
                                              Thesis Summary Detail
                                            </Nav.Link>
                                          </Nav.Item>
                                        </Col>
                                        {/* <Col sm={3}>
                                        <Nav.Item>
                                          <Nav.Link eventKey="second">
                                            Thesis Detail
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Col> */}
                                      </Row>
                                    </Col>
                                  </Nav>
                                </Col>
                                <Col sm={12}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                      <Row>
                                        <Col sm={6}>
                                          <ListGroup>
                                            <ListGroup.Item>
                                              Plagiarism Percentage
                                              <span style={{ float: "right" }}>
                                                {thesises.plagiarismPercentage}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Pre Thesis Date
                                              <span style={{ float: "right" }}>
                                                {thesises.preThesisDate}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Soft Copy Submission Date
                                              <span style={{ float: "right" }}>
                                                {
                                                  thesises.softCopySubmissionDate
                                                }
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Provisional Degree Issued Date
                                              <span style={{ float: "right" }}>
                                                {
                                                  thesises.provisinalDegreeIssuedDate
                                                }
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Uploaded Date
                                              <span style={{ float: "right" }}>
                                                {thesises.dateUploaded}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Plagiarism Checked Date
                                              <span style={{ float: "right" }}>
                                                {thesises.plagiarismCheckedDate}
                                              </span>
                                            </ListGroup.Item>
                                          </ListGroup>
                                        </Col>
                                        <Col sm={6}>
                                          <ListGroup>
                                            <ListGroup.Item>
                                              Date Of Thesis Submission
                                              <span style={{ float: "right" }}>
                                                {
                                                  thesises.dateOfthesisSubmission
                                                }
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Hard Copy Submission Date
                                              <span style={{ float: "right" }}>
                                                {
                                                  thesises.hardCopySubmissionDate
                                                }
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Degree Issued Date
                                              <span style={{ float: "right" }}>
                                                {thesises.degreeIssuedDate}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Viva Voce Date
                                              <span style={{ float: "right" }}>
                                                {thesises.vivaVoceDate}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Reference Number
                                              <span style={{ float: "right" }}>
                                                {thesises.referenceNumber}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                              Actions
                                              <span style={{ float: "right" }}>
                                                <button
                                                  style={{
                                                    border: "none",
                                                    backgroundColor: "white",
                                                  }}
                                                  onClick={() => {
                                                    openEditSchThesisesPage(
                                                      thesises.schThesisId
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      color: "#37b582",
                                                      border: "none",
                                                    }}
                                                    className="fas fa-edit"
                                                  ></i>
                                                </button>
                                                <button
                                                  style={{
                                                    border: "none",
                                                    backgroundColor: "white",
                                                  }}
                                                  onClick={() => {
                                                    deleteSchThesis(
                                                      thesises.schThesisId
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      color: "#d4224f",
                                                      border: "none",
                                                    }}
                                                    className="fas fa-trash"
                                                  ></i>
                                                </button>
                                              </span>
                                            </ListGroup.Item>
                                          </ListGroup>
                                        </Col>
                                      </Row>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          ))}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Synopsys
                        </Accordion.Toggle>
                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToSchSynopsysesForm}
                            onClick={() => {
                              backToSchSynopsysesForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th>Soft Copy</th>
                                <th>Hard Copy</th>
                                <th>Date Of DRC Presentation</th>
                                <th> Date Of DRC</th>
                                <th>DRC Remark</th>
                                <th>Shodh Gangotri Link</th>
                                <th>Date Uploaded</th>
                                <th>Lab Applicable</th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>
                              {synopsys.map((synopsyses, schSynopsysId) => (
                                <tr key={schSynopsysId}>
                                  <td>{synopsyses.softCopySubmissionDate}</td>
                                  <td>{synopsyses.hardCopySubmissionDate}</td>
                                  <td> {synopsyses.dateOfDrcPresentation} </td>
                                  <td> {synopsyses.dateOfDrc} </td>
                                  <td>{synopsyses.drcRemark}</td>
                                  <td>
                                    <a href={synopsyses.shodhGangotriLink}>
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="css-i6dzq1"
                                      >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>
                                    </a>
                                  </td>
                                  <td>{synopsyses.dateUploaded} </td>
                                  <td> {synopsyses.isLabApplicable} </td>
                                  <td>
                                    <span style={{ float: "right" }}>
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "white",
                                        }}
                                        onClick={() => {
                                          openEditSchSynopsysesPage(
                                            synopsyses.schSynopsysId
                                          );
                                        }}
                                      >
                                        <i
                                          style={{
                                            color: "#37b582",
                                            border: "none",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </button>
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "white",
                                        }}
                                        onClick={() => {
                                          deleteSchSynopsys(
                                            synopsyses.schSynopsysId
                                          );
                                        }}
                                      >
                                        <i
                                          style={{
                                            color: "#d4224f",
                                            border: "none",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Profession
                        </Accordion.Toggle>

                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToJobInfosForm}
                            onClick={() => {
                              backToJobInfosForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            {/* <SchJobInfosCard/> */}
                            <tbody>
                              <tr>
                                <th>Company Name</th>

                                <th>Designation</th>
                                <th> Emplyment Type</th>
                                <th> Start Date</th>
                                <th> End Date </th>
                                <th> Total Experience</th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>

                              {jobInfo.map((profession, schJobInfoId) => (
                                <tr key={schJobInfoId}>
                                  <td>{profession.companyName}</td>

                                  <td> {profession.jobTitle} </td>
                                  <td>{profession.jobType} </td>
                                  <td>{profession.startDate} </td>
                                  <td> {profession.endDate} </td>
                                  <td>{profession.expInYear} </td>
                                  <td>
                                    <span style={{ float: "right" }}>
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "white",
                                        }}
                                        onClick={() => {
                                          openEditSchJobInfosPage(
                                            profession.schJobInfoId
                                          );
                                        }}
                                      >
                                        <i
                                          style={{
                                            color: "#37b582",
                                            border: "none",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </button>
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "white",
                                        }}
                                        onClick={() => {
                                          deleteSchJobInfo(
                                            profession.schJobInfoId
                                          );
                                        }}
                                      >
                                        <i
                                          style={{
                                            color: "#d4224f",
                                            border: "none",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Publication
                        </Accordion.Toggle>

                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToSchPublicationsForm}
                            onClick={() => {
                              backToSchPublicationsForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th> Author Names </th>
                                <th> Title Of Paper </th>
                                <th> Journal Name</th>
                                <th> Year Of Publication</th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>
                              {publication.map(
                                (publications, schPublicationId) => (
                                  <tr key={schPublicationId}>
                                    <td>{publications.authorNames}</td>
                                    <td>{publications.titleOfPaper}</td>
                                    <td>{publications.journalname}</td>
                                    <td> {publications.yearOfPublication} </td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditSchPublicationsPage(
                                              publications.schPublicationId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteSchPublication(
                                              publication.schPublicationId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Conference
                        </Accordion.Toggle>
                        <span style={{ float: "right", padding: "9px 15px" }}>
                          {/* <a href="#  "> */}
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            //onClick={backToSchConferencesForm}
                            onClick={() => {
                              backToSchConferencesForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                          {/* </a> */}
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th> Institute Name </th>
                                <th> Title </th>
                                <th> Date</th>
                                {/* <th> Participation </th>
                                <th>Presentation</th> */}
                                <th>Paper Name</th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>
                              {conference.map(
                                (conferences, schConferenceId) => (
                                  <tr key={schConferenceId}>
                                    <td>JJTU</td>
                                    <td> {conferences.confTitle}</td>
                                    <td>
                                      {conferences.startDate} To{" "}
                                      {conferences.endDate}
                                    </td>
                                    {/* <td>{conferences.participated}</td>
                                  <td>{conferences.presented}</td> */}
                                    <td>{conferences.paperName}</td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditSchConferencesPage(
                                              conferences.schConferenceId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteSchConference(
                                              conferences.schConferenceId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          style={{ color: "#333", fontWeight : "600" }}
                        >
                          Payment
                        </Accordion.Toggle>
                        <span style={{ float: "right", padding: "9px 15px" }}>
                          <button
                            style={{ border: "none",color:"#2E86C1" }}
                            // onClick={backToPayReceiptsForm}
                            onClick={() => {
                              backToPayReceiptsForm(profile.scholarId);
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                          </button>
                        </span>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <table className="table" style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th> Fee Type </th>
                                <th> Instamment </th>
                                <th> Amount</th>
                                <th> Receipt </th>
                                <th>Date</th>
                                <th> Remark </th>
                                <th>
                                  <span style={{ float: "right" }}>
                                    Actions
                                  </span>
                                </th>
                              </tr>
                              {payReceipt.map(
                                (payReceipts, schPayReceiptId) => (
                                  <tr key={schPayReceiptId}>
                                    <td>{payReceipts.feeType}</td>
                                    <td> {payReceipts.transactionNo}</td>
                                    <td>{payReceipts.amount}</td>
                                    <td>{payReceipts.receiptNo}</td>
                                    <td>{payReceipts.payDate}</td>
                                    <td>{payReceipts.remark}</td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditPayReceiptsPage(
                                              payReceipts.schPayReceiptId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteSchPayReciept(
                                              payReceipts.schPayReceiptId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
