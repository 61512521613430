import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

const PageUrl = `${process.env.REACT_APP_API_URL}/api/adminmenu`;
export default function LoadMenu(props) {
    const {getMenuItemActive} = props;
  const [Menu, setMenu] = useState([]);

  useEffect(() => {
    const getInnerPagesWithAxios = async () => {
      const response = await axios.get(PageUrl);
      setMenu(response.data);
    };
    getInnerPagesWithAxios();
  }, [setMenu]);

  //   Menu.map((user) => (
  //     // <MenuItem eventKey={index}>user.PageUrl</MenuItem>
  //  );
  return Menu.filter((menu) => menu.isActive).map((parent, index) => {
    return (
      <li
        key={index}
        className={`menu-item ${getMenuItemActive(parent.pageUrl)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to={parent.pageUrl}>
          <i className="menu-bulletarrow menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{parent.menuName}</span>
        </NavLink>
      </li>
    );
  });
}
