import {createSlice} from "@reduxjs/toolkit";

const initialGalleriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  galleryForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const galleriesSlice = createSlice({
  name: "galleries",
  initialState: initialGalleriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGalleryById
    galleryFetched: (state, action) => {
      state.actionsLoading = false;
      state.galleryForEdit = action.payload.galleryForEdit;
      state.error = null;
    },
    // findGalleries
    galleriesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGallery
    galleryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.gallery);
    },
    // updateGallery
    galleryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.galleryId === action.payload.gallery.galleryId) {
          return action.payload.gallery;
        }
        return entity;
      });
    },
    // deleteGallery
    galleryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.galleryId !== action.payload.galleryId);
    },
    // deleteGalleries
    galleriesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.galleryId)
      );
    },
  }
});
