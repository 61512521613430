import axios from "axios";

export const GUI_PATENTS_URL = `${process.env.REACT_APP_API_URL}/api/GuiPatent`;

// CREATE =>  POST: add a new guiPatent to the server
export function createGuiPatent(guiPatent) {
  return axios.post(GUI_PATENTS_URL, guiPatent);
}

// READ
export function getAllGuiPatents() {
  return axios.get(GUI_PATENTS_URL);
}

export function getGuiPatentById(guiPatentId) {
  return axios.get(`${GUI_PATENTS_URL}/${guiPatentId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiPatents(queryParams) {
  return axios.post(`${GUI_PATENTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiPatent(guiPatent) {
  return axios.put(`${GUI_PATENTS_URL}/${guiPatent.guiPatentId}`, guiPatent);
}

// // UPDATE Status
// export function updateStatusForGuiPatents(ids, status) {
//   return axios.post(`${GUI_PATENTS_URL}/updateStatusForGuiPatents`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiPatent from the server
export function deleteGuiPatent(guiPatentId) {
  return axios.delete(`${GUI_PATENTS_URL}/${guiPatentId}`);
}

// DELETE GuiPatents by ids
export function deleteGuiPatents(ids) {
  return axios.post(`${GUI_PATENTS_URL}/deleteGuiPatents`, { ids });
}
