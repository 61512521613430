import axios from "axios";

export const GUI_EDUCATIONS_URL = `${process.env.REACT_APP_API_URL}/api/GuiEducation`;

// CREATE =>  POST: add a new guiEducation to the server
export function createGuiEducation(guiEducation) {
  return axios.post(GUI_EDUCATIONS_URL, guiEducation);
}

// READ
export function getAllGuiEducations() {
  return axios.get(GUI_EDUCATIONS_URL);
}

export function getGuiEducationById(guiEducationId) {
  return axios.get(`${GUI_EDUCATIONS_URL}/${guiEducationId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiEducations(queryParams) {
  return axios.post(`${GUI_EDUCATIONS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiEducation(guiEducation) {
  return axios.put(`${GUI_EDUCATIONS_URL}/${guiEducation.guiEducationId}`, guiEducation);
}

// // UPDATE Status
// export function updateStatusForGuiEducations(ids, status) {
//   return axios.post(`${GUI_EDUCATIONS_URL}/updateStatusForGuiEducations`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiEducation from the server
export function deleteGuiEducation(guiEducationId) {
  return axios.delete(`${GUI_EDUCATIONS_URL}/${guiEducationId}`);
}

// DELETE GuiEducations by ids
export function deleteGuiEducations(ids) {
  return axios.post(`${GUI_EDUCATIONS_URL}/deleteGuiEducations`, { ids });
}
