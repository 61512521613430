import {createSlice} from "@reduxjs/toolkit";

const initialNewssState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  newsForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const newssSlice = createSlice({
  name: "newss",
  initialState: initialNewssState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getNewsById
    newsFetched: (state, action) => {
      state.actionsLoading = false;
      state.newsForEdit = action.payload.newsForEdit;
      state.error = null;
    },
    // findNewss
    newssFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createNews
    newsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.news);
    },
    // updateNews
    newsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.newsId === action.payload.news.newsId) {
          return action.payload.news;
        }
        return entity;
      });
    },
    // deleteNews
    newsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.newsId !== action.payload.newsId);
    },
    // deleteNewss
    newssDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.newsId)
      );
    },
  }
});
