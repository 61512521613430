import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SchSynopsysesUIHelpers";

const SchSynopsysesUIContext = createContext();

export function useSchSynopsysesUIContext() {
  return useContext(SchSynopsysesUIContext);
}

export const SchSynopsysesUIConsumer = SchSynopsysesUIContext.Consumer;

export function SchSynopsysesUIProvider({ schSynopsysesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newSchSynopsysButtonClick: schSynopsysesUIEvents.newSchSynopsysButtonClick,
    openEditSchSynopsysPage: schSynopsysesUIEvents.openEditSchSynopsysPage,
    openDeleteSchSynopsysDialog: schSynopsysesUIEvents.openDeleteSchSynopsysDialog,
    openDeleteSchSynopsysesDialog: schSynopsysesUIEvents.openDeleteSchSynopsysesDialog,
    openFetchSchSynopsysesDialog: schSynopsysesUIEvents.openFetchSchSynopsysesDialog,
  };

  return (
    <SchSynopsysesUIContext.Provider value={value}>
      {children}
    </SchSynopsysesUIContext.Provider>
  );
}
