import {createSlice} from "@reduxjs/toolkit";

const initialScholarPayReceiptsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarPayReceiptForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const scholarPayReceiptsSlice = createSlice({
  name: "scholarPayReceipts",
  initialState: initialScholarPayReceiptsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScholarPayReceiptById
    scholarPayReceiptFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarPayReceiptForEdit = action.payload.scholarPayReceiptForEdit;
      state.error = null;
    },
    // findScholarPayReceipts
    scholarPayReceiptsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createScholarPayReceipt
    scholarPayReceiptCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.scholarPayReceipt);
    },
    // updateScholarPayReceipt
    scholarPayReceiptUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schPayReceiptId === action.payload.scholarPayReceipt.schPayReceiptId) {
          return action.payload.scholarPayReceipt;
        }
        return entity;
      });
    },
    // deleteScholarPayReceipt
    scholarPayReceiptDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schPayReceiptId !== action.payload.schPayReceiptId);
    },
    // deleteScholarPayReceipts
    scholarPayReceiptsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schPayReceiptId)
      );
    },
  }
});
