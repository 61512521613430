/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/schSynopsyses/schSynopsysesActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchSynopsysEditForm } from "./SchSynopsysEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import { Profile } from "../../profile/Profile";
import axios from "axios";

const initSchSynopsys = {
  id: undefined,
  scholarId: 0,
  synopsysSubmitted: true,
  submissionDate: "2020-10-17T04:27:17.290Z",
  softCopy: true,
  softCopySubmissionDate: "2020-10-17T04:27:17.290Z",
  hardCopy: true,
  hardCopySubmissionDate: "2020-10-17T04:27:17.290Z",
  drcHeld: true,
  dateOfDrc: "2020-10-17T04:27:17.290Z",
  drcRemark: "",
  shodhGangotri: true,
  shodhGangotriLink: "",
  dateUploaded: "2020-10-17T04:27:17.290Z",
  isDrcLetterIssued: true,
  drcPresentationHeld: true,
  dateOfDrcPresentation: "2020-10-17T04:27:17.290Z",
  isLabApplicable: true,
  labName: "",
  labCity: "",
};
export function SchSynopsysEdit({
  history,
  match: {
    params: { id,sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schSynopsysForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schSynopsyses.actionsLoading,
      schSynopsysForEdit: state.schSynopsyses.schSynopsysForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchSynopsys(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar Synopsys";
    if (schSynopsysForEdit && id) {
      _title = `Edit Scholar Synopsys`;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schSynopsysForEdit, id]);

  const saveSchSynopsys = (values) => {
    if (!id) {
      dispatch(actions.createSchSynopsys(values)).then(() =>
        backToSchSynopsysesList()
      );
    } else {
      dispatch(actions.updateSchSynopsys(values)).then(() =>
        backToSchSynopsysesList()
      );
    }
  };

  const btnRef = useRef();
  const saveSchSynopsysClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const id1 = id===undefined?'':id
  const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schSynopsys/${id1}`;

 const [profile, setProfile] = useState([]);
 useEffect(() => {
   const getScholar = async () => {
     const response = await axios.get(`${ScholarUrl}`);
     setProfile(response.data);
   };
   getScholar();
 }, [setProfile, ScholarUrl]);
 const ScholarId = sid?sid:profile.scholarId;
 
 
 
 initSchSynopsys.scholarId=ScholarId;

 console.log(ScholarId)

  const backToSchSynopsysesList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div>
      <Profile />
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchSynopsysesList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchSynopsysClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {!actionsLoading && (
                <SchSynopsysEditForm
                  actionsLoading={actionsLoading}
                  schSynopsys={schSynopsysForEdit || initSchSynopsys}
                  btnRef={btnRef}
                  saveSchSynopsys={saveSchSynopsys}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
