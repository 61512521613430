// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
//import axios from "axios";
import * as Yup from "yup";
import {
  Input,
 
} from "../../../../../../_metronic/_partials/controls";


// Validation schema
const SchPayReceiptEditSchema = Yup.object().shape({
  receiptNo: Yup.string().required("No. is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/scholar`;

export function SchPayReceiptEditForm({ schPayReceipt, btnRef, saveSchPayReceipt }) {
  //const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

   const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }

    return classes.join(" ");
  };

  const toBase64 = (uploadImage) =>
    new Promise((resolve, reject) => {
      if (uploadImage && uploadImage !== "" && uploadImage !== " ") {
        const reader = new FileReader();
        reader.readAsDataURL(uploadImage);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        resolve("");
      }
    });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={schPayReceipt}
        validationSchema={SchPayReceiptEditSchema}
        onSubmit={(values) => {
          try {
            values.scholarId = parseInt(values.scholarId);
            values.amount = parseInt(values.amount);
            values.verifiedBy = parseInt(values.verifiedBy);
          }catch {}
          saveSchPayReceipt(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values, touched, errors }) => (
          <>
            <Form className="form form-label-right">
            
            <div className="form-group">
            Scholar Id:  {schPayReceipt.scholarId}
                {/* <Field
                  name="scholarId"
                  component={Select}
                  placeholder="Scholar Name"
                  label="Scholar Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.scholarId}>
                                {menu.scholarName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              
              <div className="form-group">
                <Field
                  name="receiptNo"
                  component={Input}
                  placeholder="Receipt No"
                  label="Receipt No"
                />
              </div>


              <div className="form-group">
                <TextField
                  id="date"
                  label="Pay Date"
                  type="date"
                  name="payDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("payDate", e.target.value);
                  }}
                />
              </div>
              

              <div className="form-group">
                <Field
                  name="feeType"
                  component={Input}
                  placeholder="Fee Type"
                  label="Fee Type"
                />
              </div>

              <div className="form-group">
                <Field
                  name="amount"
                  component={Input}
                  placeholder="Amount"
                  label="Amount"
                />
              </div>
              <div className="form-group">
                <Field
                  name="transactionNo"
                  component={Input}
                  placeholder="Transaction No"
                  label="Transaction No"
                />
              </div>

              <div className="form-group">
                <Field
                  name="downlodReceipt"
                  component={Input}
                  placeholder="Download Receipt"
                  label="Download Receipt"
                />
              </div>

              {/* <div className="form-group">
                <label>Select Download Receipt</label>
                <input
                  className={getFieldCSSClasses(touched["downlodReceipt"], errors["downlodReceipt"])}
                  name="downlodReceipt"
                  placeholder="Download Receipt"
                  label="Download Receipt"
                  type="file"
                  onChange={async (e) => {
                    setFieldValue(
                      "downlodReceipt",
                      await toBase64(e.target.files[0])
                    );
                  }}
                  accept="file"
                />
              </div> */}

              <div className="form-group">
                <Field
                  name="verifiedBy"
                  component={Input}
                  placeholder="Verified By"
                  label="Verified By"
                />
              </div>

              <div className="form-group">
                <Field
                  name="remark"
                  component={Input}
                  placeholder="Remark"
                  label="Remark"
                />
              </div>



              <div className="form-group">
                <label>Select Upload Document</label>
                <input
                  className={getFieldCSSClasses(touched["uploadDoc"], errors["uploadDoc"])}
                  name="uploadDoc"
                  placeholder="Upload Doc"
                  label="Upload Doc"
                  type="file"
                  onChange={async (e) => {
                    setFieldValue(
                      "uploadDoc",
                      await toBase64(e.target.files[0])
                    );
                  }}
                  accept="file"
                />
              </div>
             
              <div className="form-group">
                <Field
                  name="status"
                  component={Input}
                  placeholder="Status"
                  label="Status"
                />
              </div>
              
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
