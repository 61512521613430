import {createSlice} from "@reduxjs/toolkit";

const initialDownloadDocsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  downloadDocForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const downloadDocsSlice = createSlice({
  name: "downloadDocs",
  initialState: initialDownloadDocsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getDownloadDocById
    downloadDocFetched: (state, action) => {
      state.actionsLoading = false;
      state.downloadDocForEdit = action.payload.downloadDocForEdit;
      state.error = null;
    },
    // findDownloadDocs
    downloadDocsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createDownloadDoc
    downloadDocCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.downloadDoc);
    },
    // updateDownloadDoc
    downloadDocUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.downloadDocId === action.payload.downloadDoc.downloadDocId) {
          return action.payload.downloadDoc;
        }
        return entity;
      });
    },
    // deleteDownloadDoc
    downloadDocDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.downloadDocId !== action.payload.downloadDocId);
    },
    // deleteDownloadDocs
    downloadDocsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.downloadDocId)
      );
    },
  }
});
