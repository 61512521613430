import * as requestFromServer from "./schThesisesCrud";
import { schThesisesSlice, callTypes } from "./schThesisesSlice";

const { actions } = schThesisesSlice;

export const fetchSchThesises = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchThesises(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schThesisesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schThesises";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchThesis = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schThesisFetched({ schThesisForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchThesisById(id)
    .then((response) => {
      const schThesis = response.data;
      dispatch(actions.schThesisFetched({ schThesisForEdit: schThesis }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schThesis";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchThesis = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchThesis(id)
    .then((response) => {
      dispatch(actions.schThesisDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schThesis";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchThesis = (schThesisForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schThesisForCreation.isActive = true;
  return requestFromServer
    .createSchThesis(schThesisForCreation)
    .then((response) => {
      const { schThesis } = response.data;
      schThesis.id = schThesis.schThesisId;
      dispatch(actions.schThesisCreated({ schThesis }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schThesis";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchThesis = (schThesis) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchThesis(schThesis)
    .then(() => {
      dispatch(actions.schThesisUpdated({ schThesis }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schThesis";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchThesises = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchThesises(ids)
    .then(() => {
      dispatch(actions.schThesisesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schThesises";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
