// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
// import CKEditor from "ckeditor4-react";
// import { Config } from "./../../../../Admin/utils/CkeditorConfig";
import {
  Input,
  
} from "../../../../../../_metronic/_partials/controls";


// Validation schema
const GuiPatentEditSchema = Yup.object().shape({
  patentTitle: Yup.string().required("Name is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/guide`;

export function GuiPatentEditForm({ guiPatent, btnRef, saveGuiPatent }) {
  //const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);
  const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }

    return classes.join(" ");
  };
  const toBase64 = (uplaodDoc) =>
    new Promise((resolve, reject) => {
      if (uplaodDoc && uplaodDoc !== "" && uplaodDoc !== " ") {
        const reader = new FileReader();
        reader.readAsDataURL(uplaodDoc);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        resolve("");
      }
    });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={guiPatent}
        validationSchema={GuiPatentEditSchema}
        onSubmit={(values) => {
          try {
            values.guideId = parseInt(values.guideId);
          }catch {}
          saveGuiPatent(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values, touched, errors }) => (
          <>
            <Form className="form form-label-right">
              
              <div className="form-group">
              Guide Id: {guiPatent.guideId}
                {/* <Field
                  name="guideId"
                  component={Select}
                  placeholder="Guide Name"
                  label="Guide Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.guideId}>
                                {menu.guideName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
                <Field
                  name="patentTitle"
                  component={Input}
                  placeholder="Patent Title"
                  label="Patent Title"
                />
              </div>
              {/* <div className="form-group">
                <label>Enter Patent Description</label>

                <CKEditor
                  type="Classic"
                  data={values.patentDesc}
                  config={Config}
                  onChange={(e) => {
                    setFieldValue("patentDesc", e.editor.getData());
                  }}
                />
              </div> */}
               <div className="form-group">
                <Field
                  name="patentDesc"
                  component={Input}
                  placeholder="Patent Description"
                  label="Enter Patent Description"
                />
              </div>
              {/* <div className="form-group">
                <Field
                  name="patentDesc"
                  component={Input}
                  placeholder="Patent Desc"
                  label="Patent Desc"
                />
              </div> */}
                <div className="form-group">
                <Field
                  name="patentRegNo"
                  component={Input}
                  placeholder="Patent Registration No."
                  label="Patent Registration No."
                />
              </div>
              <div className="form-group">
                <Field
                  name="patentingOrg"
                  component={Input}
                  placeholder="patenting Organisation"
                  label="patenting Organisation"
                />
              </div>

              <div className="form-group">
                <label>Select Upload Doc</label>
                <input
                  className={getFieldCSSClasses(
                    touched["uplaodDoc"],
                    errors["uplaodDoc"]
                  )}
                  name="uplaodDoc"
                  placeholder="Upload Doc"
                  label="Upload Doc"
                  type="file"
                  onChange={async (e) => {
                    setFieldValue(
                      "uplaodDoc",
                      await toBase64(e.target.files[0])
                    );
                  }}
                  accept="file"
                />
              </div>
             
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
