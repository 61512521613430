import axios from "axios";

export const SCH_THESISES_URL = `${process.env.REACT_APP_API_URL}/api/SchThesis`;

// CREATE =>  POST: add a new schThesis to the server
export function createSchThesis(schThesis) {
  return axios.post(SCH_THESISES_URL, schThesis);
}

// READ
export function getAllSchThesises() {
  return axios.get(SCH_THESISES_URL);
}

export function getSchThesisById(schThesisId) {
  return axios.get(`${SCH_THESISES_URL}/${schThesisId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchThesises(queryParams) {
  return axios.post(`${SCH_THESISES_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchThesis(schThesis) {
  return axios.put(`${SCH_THESISES_URL}/${schThesis.schThesisId}`, schThesis);
}

// // UPDATE Status
// export function updateStatusForSchThesises(ids, status) {
//   return axios.post(`${SCH_THESISES_URL}/updateStatusForSchThesises`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schThesis from the server
export function deleteSchThesis(schThesisId) {
  return axios.delete(`${SCH_THESISES_URL}/${schThesisId}`);
}

// DELETE SchThesises by ids
export function deleteSchThesises(ids) {
  return axios.post(`${SCH_THESISES_URL}/deleteSchThesises`, { ids });
}
