import {createSlice} from "@reduxjs/toolkit";

const initialFeeTypesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  feeTypeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const feeTypesSlice = createSlice({
  name: "feeTypes",
  initialState: initialFeeTypesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFeeTypeById
    feeTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.feeTypeForEdit = action.payload.feeTypeForEdit;
      state.error = null;
    },
    // findFeeTypes
    feeTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createFeeType
    feeTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.feeType);
    },
    // updateFeeType
    feeTypeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.feeTypeId === action.payload.feeType.feeTypeId) {
          return action.payload.feeType;
        }
        return entity;
      });
    },
    // deleteFeeType
    feeTypeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.feeTypeId !== action.payload.feeTypeId);
    },
    // deleteFeeTypes
    feeTypesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.feeTypeId)
      );
    },
  }
});
