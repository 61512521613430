/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/schSynopsyses/schSynopsysesActions";
import { useSchSynopsysesUIContext } from "../SchSynopsysesUIContext";

export function SchSynopsysDeleteDialog({ id, show, onHide }) {
  // SchSynopsyses UI Context
  const schSynopsysesUIContext = useSchSynopsysesUIContext();
  const schSynopsysesUIProps = useMemo(() => {
    return {
      setIds: schSynopsysesUIContext.setIds,
      queryParams: schSynopsysesUIContext.queryParams,
    };
  }, [schSynopsysesUIContext]);

  // SchSynopsyses Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.schSynopsyses.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteSchSynopsys = () => {
    // server request for deleting schSynopsys by id
    dispatch(actions.deleteSchSynopsys(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchSchSynopsyses(schSynopsysesUIProps.queryParams));
      // clear selections list
      schSynopsysesUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          SchSynopsys Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this schSynopsys?</span>
        )}
        {isLoading && <span>SchSynopsys is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteSchSynopsys}
            className="btn btn-delete btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
