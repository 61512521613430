// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import {
  Input,
  
} from "../../../../../../_metronic/_partials/controls";


// Validation schema
const GuiJobInfoEditSchema = Yup.object().shape({
  companyName: Yup.string().required("Name is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/guide`;

export function GuiJobInfoEditForm({ guiJobInfo, btnRef, saveGuiJobInfo }) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={guiJobInfo}
        validationSchema={GuiJobInfoEditSchema}
        onSubmit={(values) => {
          try {
            values.guideId = parseInt(values.guideId);
          }catch {}
          saveGuiJobInfo(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              
              <div className="form-group">
              Guide Id: {guiJobInfo.guideId}
                {/* <Field
                  name="guideId"
                  component={Select}
                  placeholder="Guide Name"
                  label="Guide Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.guideId}>
                                {menu.guideName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
                <Field
                  name="companyName"
                  component={Input}
                  placeholder="Company Name"
                  label="Company Name"
                />
              </div>
              <div className="form-group">
                <Field
                  name="jobTitle"
                  component={Input}
                  placeholder="Job Title"
                  label="Job Title"
                />
              </div>
              <div className="form-group">
                <Field
                  name="jobType"
                  component={Input}
                  placeholder="Job Type"
                  label="Job Type"
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  name="startDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("startDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  name="endDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("endDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <Field
                  name="expInYear"
                  component={Input}
                  placeholder="Experience In Year"
                  label="Experience In Year"
                />
              </div>
              <div className="form-group">
                <Field
                  name="remark"
                  component={Input}
                  placeholder="Remark"
                  label="Remark"
                />
              </div>
             
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
