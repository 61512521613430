import React, { useState, useEffect } from "react";
import { Image, Container, Card, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
// import { Tab, Nav, Col, Row } from "react-bootstrap";
// import { ListGroup, Form } from "react-bootstrap";
import axios from "axios";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function GuideProfile({ history, match }) {
  //const id = match.params.guideId?match.params.guideId:match.params.gid;
  const GuideUrl = `${process.env.REACT_APP_API_URL}/api/Guide/profile`;

  const [guide, setGuide] = useState([]);

  useEffect(() => {
    const getGuide = async () => {
      const response = await axios.get(GuideUrl);
      setGuide(response.data);
    };
    getGuide();
  }, [setGuide, GuideUrl]);

  const GuideId = guide.guideId===undefined?0:guide.guideId;
  
  
  const GuiPublicationUrl = `${process.env.REACT_APP_API_URL}/api/GuiPublication/GetGuiPublicationBySchId?id=${GuideId}`;
  const GuiConfUrl = `${process.env.REACT_APP_API_URL}/api/GuiConf/GetGuiConfBySchId?id=${GuideId}`;
  const GuiJobInfoUrl = `${process.env.REACT_APP_API_URL}/api/GuiJobInfo/GetGuiJobInfoBySchId?id=${GuideId}`;
  const GuiEducationUrl = `${process.env.REACT_APP_API_URL}/api/GuiEducation/GetGuiEducationBySchId?id=${GuideId}`;
  const GuiPatentUrl = `${process.env.REACT_APP_API_URL}/api/GuiPatent/GetGuiPatentBySchId?id=${GuideId}`;
  const GuiAwardUrl = `${process.env.REACT_APP_API_URL}/api/GuiAward/GetGuiAwardBySchId?id=${GuideId}`;
  const GuiAddressUrl = `${process.env.REACT_APP_API_URL}/api/GuiAddress/GetGuiAddressBySchId?id=${GuideId}`;
  const GuiDocumentUrl = `${process.env.REACT_APP_API_URL}/api/GuiDocument/GetGuiDocumentBySchId?id=${GuideId}`;
  const GuiJjtuScholarUrl = `${process.env.REACT_APP_API_URL}/api/GuiJjtuScholar/GetGuiJjtuScholorByGuideId?id=${GuideId}`;

  const [guiPublication, setGuiPublication] = useState([]);
  const [guiConf, setGuiConf] = useState([]);
  const [guiJobInfo, setGuiJobInfo] = useState([]);
  const [guiEducation, setGuiEducation] = useState([]);
  const [guiPatent, setGuiPatent] = useState([]);
  const [guiAward, setGuiAward] = useState([]);
  const [guiAddress, setGuiAddress] = useState([]);
  const [guiDocument, setGuiDocument] = useState([]);
  const [guiJjtuScholar, setGuiJjtuScholar] = useState([]);

  useEffect(() => {
    const getGuiPublication = async () => {
      const response = await axios.get(GuiPublicationUrl);
      setGuiPublication(response.data);
    };
    getGuiPublication();

    const getGuiConf = async () => {
      const response = await axios.get(GuiConfUrl);
      setGuiConf(response.data);
    };
    getGuiConf();

    const getGuiJobInfo = async () => {
      const response = await axios.get(GuiJobInfoUrl);
      setGuiJobInfo(response.data);
    };
    getGuiJobInfo();

    const getGuiEducation = async () => {
      const response = await axios.get(GuiEducationUrl);
      setGuiEducation(response.data);
    };
    getGuiEducation();

    const getGuiPatent = async () => {
      const response = await axios.get(GuiPatentUrl);
      setGuiPatent(response.data);
    };
    getGuiPatent();

    const getGuiAward = async () => {
      const response = await axios.get(GuiAwardUrl);
      setGuiAward(response.data);
    };
    getGuiAward();
    const getGuiAddress = async () => {
      const response = await axios.get(GuiAddressUrl);
      setGuiAddress(response.data);
    };
    getGuiAddress();
    const getGuiDocument = async () => {
      const response = await axios.get(GuiDocumentUrl);
      setGuiDocument(response.data);
    };
    getGuiDocument();
    const getGuiJjtuScholar = async () => {
      const response = await axios.get(GuiJjtuScholarUrl);
      setGuiJjtuScholar(response.data);
    };
    getGuiJjtuScholar();
  }, [
    GuiPublicationUrl,
    GuiConfUrl,
    GuiJobInfoUrl,
    GuiEducationUrl,
    GuiPatentUrl,
    GuiAwardUrl,
    GuiAddressUrl,
    GuiDocumentUrl,
    GuiJjtuScholarUrl,
    GuideId,
    setGuiPublication,
    setGuiConf,
    setGuiJobInfo,
    setGuiEducation,
    setGuiPatent,
    setGuiAward,
    setGuiAddress,
    setGuiDocument,
    setGuiJjtuScholar,
  ]);

  // const backToGuiAddressesList = (id) => {
  //   history.push(`/guide/guiaddresses/new/${id}`);
  // };
  const backToGuiConfsForm = (id) => {
    history.push(`/guide/guiconfs/new/${id}`);
  };
  const backToGuiDocumentsForm = (id) => {
    history.push(`/guide/guidocuments/new/${id}`);
  };
  const backToGuiEducationsForm = (id) => {
    history.push(`/guide/guieducations/new/${id}`);
  };
  const backToGuiAwardsForm = (id) => {
    history.push(`/guide/guiawards/new/${id}`);
  };
  const backToGuiJobInfosForm = (id) => {
    history.push(`/guide/guijobinfos/new/${id}`);
  };
  const backToGuiPublicationsForm = (id) => {
    history.push(`/guide/guipublications/new/${id}`);
  };
  const backToGuiPatentsForm = (id) => {
    history.push(`/guide/guipatents/new/${id}`);
  };
  const backToGuiJjtuScholarsForm = (id) => {
    history.push(`/guide/guijjtuscholars/new/${id}`);
  };

  //Edit Data By Id

  const openEditGuiConfsPage = (guiConfId) => {
    history.push(`/guide/guiconfs/${guiConfId}/edit`);
  };
  const openEditGuiDocumentsPage = (guiDocumentId) => {
    history.push(`/guide/guidocuments/${guiDocumentId}/edit`);
  };
  const openEditGuiEducationsPage = (guiEducationId) => {
    history.push(`/guide/guieducations/${guiEducationId}/edit`);
  };
  const openEditGuiAwardsPage = (guiAwardId) => {
    history.push(`/guide/guiawards/${guiAwardId}/edit`);
  };
  const openEditGuiJobInfosPage = (guiJobInfoId) => {
    history.push(`/guide/guijobinfos/${guiJobInfoId}/edit`);
  };
  const openEditGuiPublicationsPage = (guiPublicationId) => {
    history.push(`/guide/guipublications/${guiPublicationId}/edit`);
  };
  const openEditGuiPatentsPage = (guiPatentId) => {
    history.push(`/guide/guipatents/${guiPatentId}/edit`);
  };
  const openEditGuiJjtuScholarsPage = (guiJjtScholorId) => {
    history.push(`/guide/guijjtuscholars/${guiJjtScholorId}/edit`);
  };

  const GUI_AWARDS_URL = `${process.env.REACT_APP_API_URL}/api/GuiAward`;
  const deleteGuiAward = (guiAwardId) => {
    axios.delete(`${GUI_AWARDS_URL}/${guiAwardId}`).then((response) => {
      history.push("/guide/guideprofile");
    });
  };

  const GUI_CONFS_URL = `${process.env.REACT_APP_API_URL}/api/GuiConf`;
  const deleteGuiConf = (guiConfId) => {
    axios.delete(`${GUI_CONFS_URL}/${guiConfId}`).then((response) => {
      history.push("/guide/guideprofile");
    });
  };

  const GUI_DOCUMENTS_URL = `${process.env.REACT_APP_API_URL}/api/GuiDocument`;
  const deleteGuiDocument = (guiDocumentId) => {
    axios.delete(`${GUI_DOCUMENTS_URL}/${guiDocumentId}`).then((response) => {
      history.push("/guide/guideprofile");
    });
  };

  const GUI_EDUCATIONS_URL = `${process.env.REACT_APP_API_URL}/api/guiEducation`;
  const deleteGuiEducation = (guiEducationId) => {
    axios.delete(`${GUI_EDUCATIONS_URL}/${guiEducationId}`).then((response) => {
      history.push("/guide/guideprofile");
    });
  };

  const GUI_JJTU_SCHOLARS_URL = `${process.env.REACT_APP_API_URL}/api/GuiJjtuScholar`;
  const deleteGuiJjtuScholar = (guiJjtScholorId) => {
    axios
      .delete(`${GUI_JJTU_SCHOLARS_URL}/${guiJjtScholorId}`)
      .then((response) => {
        history.push("/guide/guideprofile");
      });
  };

  const GUI_JOBINFOS_URL = `${process.env.REACT_APP_API_URL}/api/guijobinfo`;
  const deleteGuiJobInfo = (guiJobInfoId) => {
    axios.delete(`${GUI_JOBINFOS_URL}/${guiJobInfoId}`).then((response) => {
      history.push("/guide/guideprofile");
    });
  };

  const GUI_PATENTS_URL = `${process.env.REACT_APP_API_URL}/api/guipatent`;
  const deleteGuiPatent = (guiPatentId) => {
    axios.delete(`${GUI_PATENTS_URL}/${guiPatentId}`).then((response) => {
      history.push("/guide/guideprofile");
    });
  };

  const GUI_PUBLICATIONS_URL = `${process.env.REACT_APP_API_URL}/api/guipublication`;
  const deleteGuiPublications = (guiPublicationId) => {
    axios
      .delete(`${GUI_PUBLICATIONS_URL}/${guiPublicationId}`)
      .then((response) => {
        history.push("/guide/guideprofile");
      });
  };


  
  return (
    <div>
      <div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-xxl-3 bg border-right">
              <Container>
                <Image
                  className="profileimg"
                  style={{
                    width: "170px",
                    borderRadius: "50",
                    margin: "0px 30px",
                  }}
                  src={guide.guidePhoto}
                />
              </Container>
              <h4 className="font-semibold mt-5 mb-5 mgbt-xs-5">
                {!(guide.guideName)?"-" : (guide.guideName) } ({!(guide.division)?"-" : (guide.division) })
              </h4>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Reg No : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.registrationNumber)?"-" : (guide.registrationNumber) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Specilization : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.specilization)?"-" : (guide.specilization) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Contact No : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.primaryContact)?"-" : (guide.primaryContact) }/{guide.secondaryContact}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Subject : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">{!(guide.subject)?"-" : (guide.subject) }</div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> DOB : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.dateOfBirth)?"-" : (guide.dateOfBirth) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Gender : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">{!(guide.gender)?"-" : (guide.gender) }</div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Primary Email : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.primaryEmail)?"-" : (guide.primaryEmail) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Marital Status : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.maritalStatus)?"-" : (guide.maritalStatus) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Spouse Name : </b>
                  </label>
                  <div className="col-xs-7 ml-2 controls">
                    {!(guide.spouseName)?"-" : (guide.spouseName) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Parent's Name : </b>
                  </label>
                  <div className="col-xs-7 mb-2 controls">
                    {!(guide.fatherName)?" -" : (guide.fatherName) }/{!(guide.motherName)?"-" : (guide.motherName) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Designation : </b>
                  </label>
                  <div className="col-xs-7  ml-2 controls">
                    {!(guide.designation)?" -" : (guide.designation) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Secondary Email : </b>
                  </label>
                  
                  <div className="col-xs-7 mb-2  controls">
                    {!(guide.secondaryEmail)?" -" : (guide.secondaryEmail) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mgbt-xs-0">
                  <label className="col-xs-5 control-label">
                    <b> Guide Grade : </b>
                  </label>
                  <div className="col-xs-7 mb-2 controls">
                    {!(guide.guideGrade)?" -" : (guide.guideGrade) }
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                {guiAddress.map((addresses,guiAddressId) => (
                  <div className="row mgbt-xs-0" key={guiAddressId}>
                    <label className="col-xs-5 control-label">
                      <b> {addresses.addressType}: </b>
                    </label>
                    {addresses.addressLine1}
                    <div className="col-xs-7 mb-2  controls">
                      {addresses.addressLine2},{addresses.city},
                      {addresses.state}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-xxl-9">
              <div>
                <h2>SUBJECT: {guide.subject}</h2>

                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            My Documents
                          </Accordion.Toggle>
                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none" ,color:"#2E86C1" }}
                              onClick={() => {
                                backToGuiDocumentsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="row">
                              {guiDocument.map((documents, guiDocumentId) => (
                                <div
                                  key={guiDocumentId}
                                  className="col-lg-6 mb-5 col-xxl-6"
                                >
                                  {documents.docTitle} :
                                  <span style={{ float: "right" }}>
                                    <a
                                      style={{ padding: "5px" }}
                                      href={documents.docPath} 
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="19"
                                        height="19"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="css-i6dzq1"
                                      >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>
                                    </a>

                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "white",
                                      }}
                                      onClick={() => {
                                        openEditGuiDocumentsPage(
                                          documents.guiDocumentId
                                        );
                                      }}
                                    >
                                      <i
                                        style={{
                                          color: "#37b582",
                                          border: "none",
                                        }}
                                        className="fas fa-edit"
                                      ></i>
                                    </button>
                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "white",
                                      }}
                                      onClick={() => {
                                        deleteGuiDocument(
                                          documents.guiDocumentId
                                        );
                                      }}
                                    >
                                      <i
                                        style={{
                                          color: "#d4224f",
                                          border: "none",
                                        }}
                                        className="fas fa-trash"
                                      ></i>
                                    </button>
                                  </span>
                                </div>
                              ))}
                            </div>
                            {/* ))} */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Educational Info
                          </Accordion.Toggle>
                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none" ,color:"#2E86C1"}}
                              onClick={() => {
                                backToGuiEducationsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th>Degree</th>
                                  <th>Year</th>
                                  <th>Subject</th>
                                  <th>Percentage</th>
                                  <th>Board/ University</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>
                                </tr>
                                {guiEducation.map(
                                  (Education, guiEducationId) => (
                                    <tr key={guiEducationId}>
                                      <td>{Education.degreeType}</td>
                                      <td>{Education.passingYear}</td>
                                      <td>{Education.subjectStudied}</td>
                                      <td>{Education.percentage}</td>
                                      <td>{Education.university}</td>
                                      <td>
                                        <span style={{ float: "right" }}>
                                          <button
                                            style={{
                                              border: "none",
                                              backgroundColor: "white",
                                            }}
                                            onClick={() => {
                                              openEditGuiEducationsPage(
                                                Education.guiEducationId
                                              );
                                            }}
                                          >
                                            <i
                                              style={{
                                                color: "#37b582",
                                                border: "none",
                                              }}
                                              className="fas fa-edit"
                                            ></i>
                                          </button>
                                          <button
                                            style={{
                                              border: "none",
                                              backgroundColor: "white",
                                            }}
                                            onClick={() => {
                                              deleteGuiEducation(
                                                Education.guiEducationId
                                              );
                                            }}
                                          >
                                            <i
                                              style={{
                                                color: "#d4224f",
                                                border: "none",
                                              }}
                                              className="fas fa-trash"
                                            ></i>
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Profession
                          </Accordion.Toggle>

                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none",color:"#2E86C1" }}
                              onClick={() => {
                                backToGuiJobInfosForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th>Company Name</th>
                                  {/* <th>Company Address</th> */}
                                  <th>Designation</th>
                                  <th> Emplyment Type</th>
                                  <th> Start Date</th>
                                  <th> End Date </th>
                                  <th> Total Experience</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>
                                </tr>
                                {guiJobInfo.map((JobInfo, guiJobInfoId) => (
                                  <tr key={guiJobInfoId}>
                                    <td>{JobInfo.companyName}</td>
                                    {/* <td>{jobInfo.companyName} </td> */}
                                    <td> {JobInfo.jobTitle} </td>
                                    <td>{JobInfo.jobType} </td>
                                    <td>{JobInfo.startDate} </td>
                                    <td> {JobInfo.endDate} </td>
                                    <td>{JobInfo.expInYear} </td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditGuiJobInfosPage(
                                              JobInfo.guiJobInfoId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteGuiJobInfo(
                                              JobInfo.guiJobInfoId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Publication
                          </Accordion.Toggle>

                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none",color:"#2E86C1" }}
                              // onClick={backToGuiPublicationsForm}
                              onClick={() => {
                                backToGuiPublicationsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th> Publication Title </th>
                                  <th> Category </th>
                                  <th> Published Date</th>
                                  <th> Publish Location</th>
                                  <th> Remark</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>
                                </tr>
                                {guiPublication.map(
                                  (Publication, guiPublicationId) => (
                                    <tr key={guiPublicationId}>
                                      <td>{Publication.publicationTitle}</td>
                                      <td>{Publication.category}</td>
                                      <td>{Publication.publishedDate}</td>
                                      <td>{Publication.publishLocation} </td>
                                      <td>{Publication.remark} </td>
                                      <td>
                                        <span style={{ float: "right" }}>
                                          <button
                                            style={{
                                              border: "none",
                                              backgroundColor: "white",
                                            }}
                                            onClick={() => {
                                              openEditGuiPublicationsPage(
                                                Publication.guiPublicationId
                                              );
                                            }}
                                          >
                                            <i
                                              style={{
                                                color: "#37b582",
                                                border: "none",
                                              }}
                                              className="fas fa-edit"
                                            ></i>
                                          </button>
                                          <button
                                            style={{
                                              border: "none",
                                              backgroundColor: "white",
                                            }}
                                            onClick={() => {
                                              deleteGuiPublications(
                                                Publication.guiPublicationId
                                              );
                                            }}
                                          >
                                            <i
                                              style={{
                                                color: "#d4224f",
                                                border: "none",
                                              }}
                                              className="fas fa-trash"
                                            ></i>
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Patent
                          </Accordion.Toggle>

                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none",color:"#2E86C1" }}
                              onClick={() => {
                                backToGuiPatentsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th> Patent Title </th>
                                  <th> Patent Reg No </th>
                                  <th> Patenting Org </th>
                                  <th> Patent Desc</th>
                                  <th> Upload Doc</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>
                                </tr>
                                {guiPatent.map((Patent, guiPatentId) => (
                                  <tr key={guiPatentId}>
                                    <td>{Patent.patentTitle}</td>
                                    <td>{Patent.patentRegNo}</td>
                                    <td>{Patent.patentingOrg}</td>
                                    <td>{Patent.patentDesc}</td>
                                    <td>{Patent.uploadDoc}</td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditGuiPatentsPage(
                                              Patent.guiPatentId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteGuiPatent(Patent.guiPatentId);
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Conference
                          </Accordion.Toggle>
                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none",color:"#2E86C1" }}
                              onClick={() => {
                                backToGuiConfsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th> Category</th>
                                  <th> Conference Title </th>
                                  <th> Held Date</th>
                                  <th> Location </th>
                                  <th>Remark</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>
                                  {/* <th>Paper Name</th> */}
                                </tr>
                                {guiConf.map((Conference, guiConfId) => (
                                  <tr key={guiConfId}>
                                    <td>{Conference.category}</td>
                                    <td> {Conference.confTitle}</td>
                                    <td>{Conference.heldDate}</td>
                                    <td>{Conference.location}</td>
                                    <td>{Conference.remark}</td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditGuiConfsPage(
                                              Conference.guiConfId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteGuiConf(Conference.guiConfId);
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>
                                    {/* <td>{guiConf.paperName}</td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Guide JJTU Scholar
                          </Accordion.Toggle>
                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none",color:"#2E86C1" }}
                              onClick={() => {
                                backToGuiJjtuScholarsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th> Scholar Id</th>
                                  <th> Allocation Date </th>
                                  <th> Remark</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>

                                  {/* <th>Paper Name</th> */}
                                </tr>
                                {guiJjtuScholar.map(
                                  (guiJjtuScholars, guiJjtScholorId) => (
                                    <tr key={guiJjtScholorId}>
                                      <td>{guiJjtuScholars.scholarId}</td>
                                      <td> {guiJjtuScholars.allocationDate}</td>
                                      <td>{guiJjtuScholars.remark}</td>

                                      <td>
                                        <div>
                                          <span style={{ float: "right" }}>
                                            <button
                                              style={{
                                                border: "none",
                                                backgroundColor: "white",
                                              }}
                                              onClick={() => {
                                                openEditGuiJjtuScholarsPage(
                                                  guiJjtuScholars.guiJjtScholorId
                                                );
                                              }}
                                            >
                                              <i
                                                style={{
                                                  color: "#37b582",
                                                  border: "none",
                                                }}
                                                className="fas fa-edit"
                                              ></i>
                                            </button>
                                            <button
                                              style={{
                                                border: "none",
                                                backgroundColor: "white",
                                              }}
                                              onClick={() => {
                                                deleteGuiJjtuScholar(
                                                  guiJjtuScholars.guiJjtScholorId
                                                );
                                              }}
                                            >
                                              <i
                                                style={{
                                                  color: "#d4224f",
                                                  border: "none",
                                                }}
                                                className="fas fa-trash"
                                              ></i>
                                            </button>
                                          </span>
                                        </div>
                                      </td>

                                      {/* <td>{guiConf.paperName}</td> */}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            style={{ color: "#333", fontWeight : "600" }}
                          >
                            Awards
                          </Accordion.Toggle>
                          <span style={{ float: "right", padding: "9px 15px" }}>
                            <button
                              style={{ border: "none",color:"#2E86C1" }}
                              onClick={() => {
                                backToGuiAwardsForm(guide.guideId);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                              </svg>
                            </button>
                          </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <table className="table" style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th> Award Title</th>
                                  <th> Award Date </th>
                                  <th> Remark</th>
                                  <th>
                                    <span style={{ float: "right" }}>
                                      Actions
                                    </span>
                                  </th>

                                  {/* <th>Paper Name</th> */}
                                </tr>
                                {guiAward.map((Award, guiAwardId) => (
                                  <tr key={guiAwardId}>
                                    <td>{Award.awardTitle}</td>
                                    <td> {Award.awardDate}</td>
                                    <td>{Award.remark}</td>
                                    <td>
                                      <span style={{ float: "right" }}>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            openEditGuiAwardsPage(
                                              Award.guiAwardId
                                            );
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#37b582",
                                              border: "none",
                                            }}
                                            className="fas fa-edit"
                                          ></i>
                                        </button>
                                        <button
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => {
                                            deleteGuiAward(Award.guiAwardId);
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: "#d4224f",
                                              border: "none",
                                            }}
                                            className="fas fa-trash"
                                          ></i>
                                        </button>
                                      </span>
                                    </td>

                                    {/* <td>{guiConf.paperName}</td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
