import {createSlice} from "@reduxjs/toolkit";

const initialGuideEducationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideEducationForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guideEducationsSlice = createSlice({
  name: "guideEducations",
  initialState: initialGuideEducationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideEducationById
    guideEducationFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideEducationForEdit = action.payload.guideEducationForEdit;
      state.error = null;
    },
    // findGuideEducations
    guideEducationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuideEducation
    guideEducationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guideEducation);
    },
    // updateGuideEducation
    guideEducationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiEducationId === action.payload.guideEducation.guiEducationId) {
          return action.payload.guideEducation;
        }
        return entity;
      });
    },
    // deleteGuideEducation
    guideEducationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiEducationId !== action.payload.guiEducationId);
    },
    // deleteGuideEducations
    guideEducationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiEducationId)
      );
    },
  }
});
