import axios from "axios";

export const SCH_CONFERENCES_URL = `${process.env.REACT_APP_API_URL}/api/SchConference`;

// CREATE =>  POST: add a new schConference to the server
export function createSchConference(schConference) {
  return axios.post(SCH_CONFERENCES_URL, schConference);
}

// READ
export function getAllSchConferences() {
  return axios.get(SCH_CONFERENCES_URL);
}

export function getSchConferenceById(schConferenceId) {
  return axios.get(`${SCH_CONFERENCES_URL}/${schConferenceId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchConferences(queryParams) {
  return axios.post(`${SCH_CONFERENCES_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchConference(schConference) {
  return axios.put(`${SCH_CONFERENCES_URL}/${schConference.schConferenceId}`, schConference);
}

// // UPDATE Status
// export function updateStatusForSchConferences(ids, status) {
//   return axios.post(`${SCH_CONFERENCES_URL}/updateStatusForSchConferences`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schConference from the server
export function deleteSchConference(schConferenceId) {
  return axios.delete(`${SCH_CONFERENCES_URL}/${schConferenceId}`);
}

// DELETE SchConferences by ids
export function deleteSchConferences(ids) {
  return axios.post(`${SCH_CONFERENCES_URL}/deleteSchConferences`, { ids });
}
