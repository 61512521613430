import {createSlice} from "@reduxjs/toolkit";

const initialScholarThesisesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarThesisForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const scholarThesisesSlice = createSlice({
  name: "scholarThesises",
  initialState: initialScholarThesisesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScholarThesisById
    scholarThesisFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarThesisForEdit = action.payload.scholarThesisForEdit;
      state.error = null;
    },
    // findScholarThesises
    scholarThesisesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createScholarThesis
    scholarThesisCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.scholarThesis);
    },
    // updateScholarThesis
    scholarThesisUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schThesisId === action.payload.scholarThesis.schThesisId) {
          return action.payload.scholarThesis;
        }
        return entity;
      });
    },
    // deleteScholarThesis
    scholarThesisDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schThesisId !== action.payload.schThesisId);
    },
    // deleteScholarThesises
    scholarThesisesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schThesisId)
      );
    },
  }
});
