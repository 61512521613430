import {createSlice} from "@reduxjs/toolkit";

const initialGuiConfsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guiConfForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guiConfsSlice = createSlice({
  name: "guiConfs",
  initialState: initialGuiConfsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuiConfById
    guiConfFetched: (state, action) => {
      state.actionsLoading = false;
      state.guiConfForEdit = action.payload.guiConfForEdit;
      state.error = null;
    },
    // findGuiConfs
    guiConfsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuiConf
    guiConfCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guiConf);
    },
    // updateGuiConf
    guiConfUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiConfId === action.payload.guiConf.guiConfId) {
          return action.payload.guiConf;
        }
        return entity;
      });
    },
    // deleteGuiConf
    guiConfDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiConfId !== action.payload.guiConfId);
    },
    // deleteGuiConfs
    guiConfsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiConfId)
      );
    },
  }
});
