// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

// Validation schema
const SchThesisEditSchema = Yup.object().shape({
  plagiarismPercentage: Yup.string().required("Percentage is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/scholar`;

export function SchThesisEditForm({ schThesis, btnRef, saveSchThesis }) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={schThesis}
        validationSchema={SchThesisEditSchema}
        onSubmit={(values) => {
          try {
            values.scholarId = parseInt(values.scholarId);
            values.notifcationNumber = parseInt(values.notifcationNumber);
            values.referenceNumber = parseInt(values.referenceNumber);
          } catch {}
          saveSchThesis(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
                Scholar Id: {schThesis.scholarId}
               
              </div>
              <div className="form-group">
                <Field
                  name="plagiarismPercentage"
                  component={Input}
                  placeholder="Plagiarism Percentage"
                  label="Plagiarism Percentage"
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Plagiarism Checked Date"
                  type="date"
                  name="plagiarismCheckedDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("plagiarismCheckedDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Pre Thesis Presentation:{" "}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Pre Thesis Presentation"
                    name="preThesisPresentation"
                    value={schThesis.preThesisPresentation}
                    onChange={(e) => {
                      setFieldValue(
                        "preThesisPresentation",
                        JSON.parse(e.target.value)
                      );
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          color="primary"
                          checked={values.preThesisPresentation}
                        />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          color="primary"
                          checked={!values.preThesisPresentation}
                        />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Pre Thesis Date"
                  type="date"
                  name="preThesisDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("preThesisDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Is Submitted: </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Is Submitted"
                    name="isSubmitted"
                    value={schThesis.isSubmitted}
                    onChange={(e) => {
                      setFieldValue("isSubmitted", JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio color="primary" checked={values.isSubmitted} />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio color="primary" checked={!values.isSubmitted} />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Date Of thesis Submission"
                  type="date"
                  name="dateOfthesisSubmission"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("dateOfthesisSubmission", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Soft Copy: </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Soft Copy"
                    name="softCopy"
                    value={schThesis.softCopy}
                    onChange={(e) => {
                      setFieldValue("softCopy", JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio color="primary" checked={values.softCopy} />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio color="primary" checked={!values.softCopy} />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Soft Copy Submission Date"
                  type="date"
                  name="softCopySubmissionDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("softCopySubmissionDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Hard Copy: </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Hard Copy"
                    name="hardCopy"
                    value={schThesis.hardCopy}
                    onChange={(e) => {
                      setFieldValue("hardCopy", JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio color="primary" checked={values.hardCopy} />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio color="primary" checked={!values.hardCopy} />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Hard Copy Submission Date"
                  type="date"
                  name="hardCopySubmissionDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("hardCopySubmissionDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <Field
                  name="nameOfthePannel"
                  component={Input}
                  placeholder="Name Of the Pannel"
                  label="Name Of the Pannel"
                />
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Viva Voce Date"
                  type="date"
                  name="vivaVoceDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("vivaVoceDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Shodh Ganga: </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Shodh Ganga"
                    name="shodhGanga"
                    value={schThesis.shodhGanga}
                    onChange={(e) => {
                      setFieldValue("shodhGanga", JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio color="primary" checked={values.shodhGanga} />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio color="primary" checked={!values.shodhGanga} />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Date Uploaded"
                  type="date"
                  name="dateUploaded"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("dateUploaded", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="provisinal Degree Issued Date"
                  type="date"
                  name="provisinalDegreeIssuedDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("provisinalDegreeIssuedDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Degree Issued Date"
                  type="date"
                  name="degreeIssuedDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("degreeIssuedDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <Field
                  name="notifcationNumber"
                  component={Input}
                  placeholder="Notifcation Number"
                  label="Notifcation Number"
                />
              </div>

              <div className="form-group">
                <Field
                  name="referenceNumber"
                  component={Input}
                  placeholder="Reference Number"
                  label="Reference Number"
                />
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Migration Certificate:{" "}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Migration Certificate"
                    name="migrationCertificate"
                    value={schThesis.migrationCertificate}
                    onChange={(e) => {
                      setFieldValue(
                        "migrationCertificate",
                        JSON.parse(e.target.value)
                      );
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          color="primary"
                          checked={values.migrationCertificate}
                        />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          color="primary"
                          checked={!values.migrationCertificate}
                        />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Migration Certificate Date"
                  type="date"
                  name="migrationCertificateDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("migrationCertificateDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Pre Thesis Presentation Date"
                  type="date"
                  name="preThesisPresentationDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("preThesisPresentationDate", e.target.value);
                  }}
                />
              </div>
             

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
