import * as requestFromServer from "./guiJobInfosCrud";
import { guiJobInfosSlice, callTypes } from "./guiJobInfosSlice";

const { actions } = guiJobInfosSlice;

export const fetchGuiJobInfos = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiJobInfos(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiJobInfosFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiJobInfos";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiJobInfo = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiJobInfoFetched({ guiJobInfoForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiJobInfoById(id)
    .then((response) => {
      const guiJobInfo = response.data;
      dispatch(actions.guiJobInfoFetched({ guiJobInfoForEdit: guiJobInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiJobInfo = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiJobInfo(id)
    .then((response) => {
      dispatch(actions.guiJobInfoDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiJobInfo = (guiJobInfoForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiJobInfoForCreation.isActive = true;
  return requestFromServer
    .createGuiJobInfo(guiJobInfoForCreation)
    .then((response) => {
      const { guiJobInfo } = response.data;
      guiJobInfo.id = guiJobInfo.guiJobInfoId;
      dispatch(actions.guiJobInfoCreated({ guiJobInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiJobInfo = (guiJobInfo) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiJobInfo(guiJobInfo)
    .then(() => {
      dispatch(actions.guiJobInfoUpdated({ guiJobInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiJobInfos = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiJobInfos(ids)
    .then(() => {
      dispatch(actions.guiJobInfosDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiJobInfos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
