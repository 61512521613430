import {createSlice} from "@reduxjs/toolkit";

const initialDocTypesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  docTypeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const docTypesSlice = createSlice({
  name: "docTypes",
  initialState: initialDocTypesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getDocTypeById
    docTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.docTypeForEdit = action.payload.docTypeForEdit;
      state.error = null;
    },
    // findDocTypes
    docTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createDocType
    docTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.docType);
    },
    // updateDocType
    docTypeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.docTypeId === action.payload.docType.docTypeId) {
          return action.payload.docType;
        }
        return entity;
      });
    },
    // deleteDocType
    docTypeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.docTypeId !== action.payload.docTypeId);
    },
    // deleteDocTypes
    docTypesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.docTypeId)
      );
    },
  }
});
