// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import { Input,  } from "../../../../../../_metronic/_partials/controls";

// Validation schema
const GuiDocumentEditSchema = Yup.object().shape({
  docTitle: Yup.string().required("Name is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/guide`;
//const PageUrl1 = `${process.env.REACT_APP_API_URL}/api/doctype`;
export function GuiDocumentEditForm({ guiDocument, btnRef, saveGuiDocument }) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);
  // const [menuForDropdown1, setMenuForDropdown1] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl1);
  //     setMenuForDropdown1(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown1]);

  const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }

    return classes.join(" ");
  };
  const toBase64 = (uplaodDoc) =>
    new Promise((resolve, reject) => {
      if (uplaodDoc && uplaodDoc !== "" && uplaodDoc !== " ") {
        const reader = new FileReader();
        reader.readAsDataURL(uplaodDoc);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        resolve("");
      }
    });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={guiDocument}
        validationSchema={GuiDocumentEditSchema}
        onSubmit={(values) => {
          try {
            values.guideId = parseInt(values.guideId);
          } catch {}
          saveGuiDocument(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values, touched, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
              Guide Id: {guiDocument.guideId}
                {/* <Field
                  name="guideId"
                  component={Select}
                  placeholder="Guide Name"
                  label="Guide Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.guideId}>
                                {menu.guideName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>

              
              <div className="form-group">
                <Field
                  name="docType"
                  component={Input}
                  placeholder="Doc Type"
                  label="Doc Type"
                />
              </div>

              <div className="form-group">
                <Field
                  name="docTitle"
                  component={Input}
                  placeholder="Doc Title"
                  label="Doc Title"
                />
              </div>
           
              

              <div className="form-group">
                <label>Select Doc Path</label>
                <input
                  className={getFieldCSSClasses(
                    touched["docPath"],
                    errors["docPath"]
                  )}
                  name="docPath"
                  placeholder="Doc Path"
                  label="Doc Path"
                  type="file"
                  onChange={async (e) => {
                    setFieldValue("docPath", await toBase64(e.target.files[0]));
                  }}
                  accept="file"
                />
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
