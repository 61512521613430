import axios from "axios";

export const GUI_AWARDS_URL = `${process.env.REACT_APP_API_URL}/api/GuiAward`;

// CREATE =>  POST: add a new guiAward to the server
export function createGuiAward(guiAward) {
  return axios.post(GUI_AWARDS_URL, guiAward);
}

// READ
export function getAllGuiAwards() {
  return axios.get(GUI_AWARDS_URL);
}

export function getGuiAwardById(guiAwardId) {
  return axios.get(`${GUI_AWARDS_URL}/${guiAwardId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiAwards(queryParams) {
  return axios.post(`${GUI_AWARDS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiAward(guiAward) {
  return axios.put(`${GUI_AWARDS_URL}/${guiAward.guiAwardId}`, guiAward);
}

// // UPDATE Status
// export function updateStatusForGuiAwards(ids, status) {
//   return axios.post(`${GUI_AWARDS_URL}/updateStatusForGuiAwards`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiAward from the server
export function deleteGuiAward(guiAwardId) {
  return axios.delete(`${GUI_AWARDS_URL}/${guiAwardId}`);
}

// DELETE GuiAwards by ids
export function deleteGuiAwards(ids) {
  return axios.post(`${GUI_AWARDS_URL}/deleteGuiAwards`, { ids });
}
