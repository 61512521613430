import axios from "axios";

export const SCH_COURSE_WORK_INFOS_URL = `${process.env.REACT_APP_API_URL}/api/SchCourseWorkInfo`;

// CREATE =>  POST: add a new schCourseWorkInfo to the server
export function createSchCourseWorkInfo(schCourseWorkInfo) {
  return axios.post(SCH_COURSE_WORK_INFOS_URL, schCourseWorkInfo);
}

// READ
export function getAllSchCourseWorkInfos() {
  return axios.get(SCH_COURSE_WORK_INFOS_URL);
}

export function getSchCourseWorkInfoById(schCourseWorkInfoId) {
  return axios.get(`${SCH_COURSE_WORK_INFOS_URL}/${schCourseWorkInfoId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchCourseWorkInfos(queryParams) {
  return axios.post(`${SCH_COURSE_WORK_INFOS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchCourseWorkInfo(schCourseWorkInfo) {
  return axios.put(`${SCH_COURSE_WORK_INFOS_URL}/${schCourseWorkInfo.schCourseWorkInfoId}`, schCourseWorkInfo);
}

// // UPDATE Status
// export function updateStatusForSchCourseWorkInfos(ids, status) {
//   return axios.post(`${SCH_COURSE_WORK_INFOS_URL}/updateStatusForSchCourseWorkInfos`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schCourseWorkInfo from the server
export function deleteSchCourseWorkInfo(schCourseWorkInfoId) {
  return axios.delete(`${SCH_COURSE_WORK_INFOS_URL}/${schCourseWorkInfoId}`);
}

// DELETE SchCourseWorkInfos by ids
export function deleteSchCourseWorkInfos(ids) {
  return axios.post(`${SCH_COURSE_WORK_INFOS_URL}/deleteSchCourseWorkInfos`, { ids });
}
