import * as requestFromServer from "./schPublicationsCrud";
import { schPublicationsSlice, callTypes } from "./schPublicationsSlice";

const { actions } = schPublicationsSlice;

export const fetchSchPublications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchPublications(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schPublicationsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schPublications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchPublication = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schPublicationFetched({ schPublicationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchPublicationById(id)
    .then((response) => {
      const schPublication = response.data;
      dispatch(actions.schPublicationFetched({ schPublicationForEdit: schPublication }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchPublication = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchPublication(id)
    .then((response) => {
      dispatch(actions.schPublicationDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchPublication = (schPublicationForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schPublicationForCreation.isActive = true;
  return requestFromServer
    .createSchPublication(schPublicationForCreation)
    .then((response) => {
      const { schPublication } = response.data;
      schPublication.id = schPublication.schPublicationId;
      dispatch(actions.schPublicationCreated({ schPublication }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchPublication = (schPublication) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchPublication(schPublication)
    .then(() => {
      dispatch(actions.schPublicationUpdated({ schPublication }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchPublications = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchPublications(ids)
    .then(() => {
      dispatch(actions.schPublicationsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schPublications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
