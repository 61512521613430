import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector } from "react-redux";

// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import BaseFile from "../components/BaseFile";
import ScholarPage from "./modules/Scholar/ScholarPage";
import GuidePage from "./modules/Guide/GuidePage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
const AdminPage = lazy(() => import("./modules/Admin/pages/AdminPage"));

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);

  // const PageUrl = `${process.env.REACT_APP_API_URL}/api/userrole/{id}`;
  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     user(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [user]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {user.role === 1 ? <Redirect exact from="/" to="/dashboard" /> : " "}
        {user.role === 2 ? <Redirect exact from="/" to="/scholar" /> : " "}
        {user.role === 3 ? <Redirect exact from="/" to="/guide" /> : " "}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/basefile" component={BaseFile} />
        {/* {user.role === 1 ? (
          <Route path="/admin" component={AdminPage} />
        ) : (
          <Route path="/pages" component={ScholarPage} />
        )} */}
        ​​​​
        {user.role === 1 ? <Route path="/admin" component={AdminPage} /> : " "}
        ​​​​ ​​​​
        {user.role === 2 || user.role === 1 ? <Route path="/scholar" component={ScholarPage} /> : ""}
        ​​​​​
        {user.role === 3 || user.role === 1 ? <Route path="/guide" component={GuidePage} /> : ""}
        ​​​​​
        <Redirect to="error/error-v1" />
        {/* <Route path="/pages" component={GuidePage} /> */}
      </Switch>
    </Suspense>
  );
}
