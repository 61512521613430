// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import { Input } from "../../../../../../_metronic/_partials/controls";


// Validation schema
const GuiPublicationEditSchema = Yup.object().shape({
  publicationTitle : Yup.string().required("Title is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/guide`;

export function GuiPublicationEditForm({
  guiPublication,
  btnRef,
  saveGuiPublication,
}) {
  //const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

  const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }

    return classes.join(" ");
  };
  const toBase64 = (uplaodDoc) =>
    new Promise((resolve, reject) => {
      if (uplaodDoc && uplaodDoc !== "" && uplaodDoc !== " ") {
        const reader = new FileReader();
        reader.readAsDataURL(uplaodDoc);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        resolve("");
      }
    });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={guiPublication}
        validationSchema={GuiPublicationEditSchema}
        onSubmit={(values) => {
          try {
            values.guideId = parseInt(values.guideId);
          }catch {}
          saveGuiPublication(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values, touched, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
               Guide Id: {guiPublication.guideId} 
                {/* <Field
                  name="guideId"
                  component={Select}
                  placeholder="Guide Name"
                  label="Guide Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.guideId}>
                                {menu.guideName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
                <Field
                  name="publicationTitle"
                  component={Input}
                  placeholder="Publication Title"
                  label="publication Title"
                />
              </div>
              <div className="form-group">
                <Field
                  name="category"
                  component={Input}
                  placeholder="Category"
                  label="Category"
                />
              </div>
              <div className="form-group">
                <Field
                  name="link"
                  component={Input}
                  placeholder="link"
                  label="link"
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Published Date "
                  type="date"
                  name="publishedDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("publishedDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <Field
                  name="publishLocation"
                  component={Input}
                  placeholder="Publish Location"
                  label="Publish Location"
                />
              </div>
              <div className="form-group">
                <Field
                  name="remark"
                  component={Input}
                  placeholder="Remark"
                  label="Remark"
                />
              </div>

              {/* <div className="form-group">
                <Field
                  name="uplaodFile"
                  component={Input}
                  placeholder="uplaodFile"
                  label="uplaodFile"
                />
              </div> */}
              <div className="form-group">
                <label>Select Upload File</label>
                <input
                  className={getFieldCSSClasses(
                    touched["uplaodFile"],
                    errors["uplaodFile"]
                  )}
                  name="uplaodFile"
                  placeholder="Upload File"
                  label="Upload File"
                  type="file"
                  onChange={async (e) => {
                    setFieldValue(
                      "uplaodFile",
                      await toBase64(e.target.files[0])
                    );
                  }}
                  accept="file"
                />
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
