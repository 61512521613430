import {createSlice} from "@reduxjs/toolkit";

const initialLetterTypesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  letterTypeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const letterTypesSlice = createSlice({
  name: "letterTypes",
  initialState: initialLetterTypesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getLetterTypeById
    letterTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterTypeForEdit = action.payload.letterTypeForEdit;
      state.error = null;
    },
    // findLetterTypes
    letterTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createLetterType
    letterTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.letterType);
    },
    // updateLetterType
    letterTypeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.letterTypeId === action.payload.letterType.letterTypeId) {
          return action.payload.letterType;
        }
        return entity;
      });
    },
    // deleteLetterType
    letterTypeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.letterTypeId !== action.payload.letterTypeId);
    },
    // deleteLetterTypes
    letterTypesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.letterTypeId)
      );
    },
  }
});
