/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import SVG from "react-inlinesvg";
import LoadMenu from "../../../../../components/LoadMenu";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  // user.role = 2;
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {user.role === 1 ? (
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {user.role === 2 ? (
          <li
            className={`menu-item ${getMenuItemActive(
              "/scholar/profile",
              true
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/scholar/profile">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Profile</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
          {user.role === 3 ? (
          <li
            className={`menu-item ${getMenuItemActive(
              "/guide/guideprofile",
              true
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/guide/guideprofile">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Profile</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}

        {/* <li
              className={`menu-item ${getMenuItemActive("/builder", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Layout Builder</span>
            </NavLink>
          </li> */}
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}
        {/* Modules */}
        {/*begin::1 Level*/}

        {user.role === 1 ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Settings</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Settings</span>
                  </span>
                </li>

                <LoadMenu getMenuItemActive={getMenuItemActive} />
              </ul>
            </div>
          </li>
        ) : (
          " "
        )}

        {user.role === 1 ? (
          <li
            className={`menu-item ${getMenuItemActive(
              "/admin/scholars",
              true
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/scholars">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Scholars</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}

        {/*end::1 Level*/}
        {user.role === 1 ? (
          <li
            className={`menu-item ${getMenuItemActive("/admin/guides", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/guides">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Guides</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}

        {user.role === 2 ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/scholar/profile",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/scholar/profile">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Scholar</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Scholar</span>
                  </span>
                </li>

                {/* <LoadMenu getMenuItemActive={getMenuItemActive} /> */}
              </ul>
            </div>
          </li>
        ) : (
          ""
        )}

        {/*end::1 Level*/}
        {user.role === 3 ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/guide/guideprofile",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/guide/guideprofile">
              <i className="menu-icon fa fa-users"></i>
              <span className="menu-text">Guide</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Guide</span>
                  </span>
                </li>

                {/* <LoadMenu getMenuItemActive={getMenuItemActive} /> */}
              </ul>
            </div>
          </li>
        ) : (
          ""
        )}

        {user.role === 4 || user.role === 1 ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "academic-students",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/academic-students">
              <i className="menu-icon fa fa-users"></i>
              <span className="menu-text">Academic Students</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Academic Students</span>
                  </span>
                </li>

                {/* <LoadMenu getMenuItemActive={getMenuItemActive} /> */}
              </ul>
            </div>
          </li>
        ) : (
          ""
        )}
      </ul>
    </>
  );
}
