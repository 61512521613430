import {createSlice} from "@reduxjs/toolkit";

const initialGuiAddressesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guiAddressForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guiAddressesSlice = createSlice({
  name: "guiAddresses",
  initialState: initialGuiAddressesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuiAddressById
    guiAddressFetched: (state, action) => {
      state.actionsLoading = false;
      state.guiAddressForEdit = action.payload.guiAddressForEdit;
      state.error = null;
    },
    // findGuiAddresses
    guiAddressesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuiAddress
    guiAddressCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guiAddress);
    },
    // updateGuiAddress
    guiAddressUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiAddressId === action.payload.guiAddress.guiAddressId) {
          return action.payload.guiAddress;
        }
        return entity;
      });
    },
    // deleteGuiAddress
    guiAddressDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiAddressId !== action.payload.guiAddressId);
    },
    // deleteGuiAddresses
    guiAddressesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiAddressId)
      );
    },
  }
});
