import * as requestFromServer from "./guiJjtuScholarsCrud";
import { guiJjtuScholarsSlice, callTypes } from "./guiJjtuScholarsSlice";

const { actions } = guiJjtuScholarsSlice;

export const fetchGuiJjtuScholars = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiJjtuScholars(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiJjtuScholarsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiJjtuScholars";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiJjtuScholar = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiJjtuScholarFetched({ guiJjtuScholarForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiJjtuScholarById(id)
    .then((response) => {
      const guiJjtuScholar = response.data;
      dispatch(actions.guiJjtuScholarFetched({ guiJjtuScholarForEdit: guiJjtuScholar }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiJjtuScholar";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiJjtuScholar = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiJjtuScholar(id)
    .then((response) => {
      dispatch(actions.guiJjtuScholarDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiJjtuScholar";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiJjtuScholar = (guiJjtuScholarForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiJjtuScholarForCreation.isActive = true;
  return requestFromServer
    .createGuiJjtuScholar(guiJjtuScholarForCreation)
    .then((response) => {
      const { guiJjtuScholar } = response.data;
      guiJjtuScholar.id = guiJjtuScholar.guiJjtuScholarId;
      dispatch(actions.guiJjtuScholarCreated({ guiJjtuScholar }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiJjtuScholar";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiJjtuScholar = (guiJjtuScholar) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiJjtuScholar(guiJjtuScholar)
    .then(() => {
      dispatch(actions.guiJjtuScholarUpdated({ guiJjtuScholar }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiJjtuScholar";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiJjtuScholars = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiJjtuScholars(ids)
    .then(() => {
      dispatch(actions.guiJjtuScholarsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiJjtuScholars";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
