import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { Profile } from "./Pages/profile/Profile";
//import { SchEducationsPage } from "../Scholar/Pages/schEducations/SchEducationsPage";
import { SchConferenceEdit } from "../Scholar/Pages/schConferences/schConference-edit/SchConferenceEdit";
import { SchDocumentEdit } from "../Scholar/Pages/schDocuments/schDocument-edit/SchDocumentEdit";
import { SchThesisEdit } from "../Scholar/Pages/schThesises/schThesis-edit/SchThesisEdit";
import { SchEducationEdit } from "../Scholar/Pages/schEducations/schEducation-edit/SchEducationEdit";
import { SchJobInfoEdit } from "../Scholar/Pages/schJobInfos/schJobInfo-edit/SchJobInfoEdit";
import { SchPayReceiptEdit } from "../Scholar/Pages/schPayReceipts/schPayReceipt-edit/SchPayReceiptEdit";
import { SchPublicationEdit } from "../Scholar/Pages/schPublications/schPublication-edit/SchPublicationEdit";
import { SchCourseWorkInfoEdit } from "../Scholar/Pages/schCourseWorkInfos/schCourseWorkInfo-edit/SchCourseWorkInfoEdit";
import { SchSynopsysEdit } from "../Scholar/Pages/schSynopsyses/schSynopsys-edit/SchSynopsysEdit";

import { SchConferenceDeleteDialog } from "../Scholar/Pages/schConferences/schConference-delete-dialog/SchConferenceDeleteDialog";
import { SchDocumentDeleteDialog } from "../Scholar/Pages/schDocuments/schDocument-delete-dialog/SchDocumentDeleteDialog";
import { SchThesisDeleteDialog } from "../Scholar/Pages/schThesises/schThesis-delete-dialog/SchThesisDeleteDialog";
import { SchEducationDeleteDialog } from "../Scholar/Pages/schEducations/schEducation-delete-dialog/SchEducationDeleteDialog";
import { SchJobInfoDeleteDialog } from "../Scholar/Pages/schJobInfos/schJobInfo-delete-dialog/SchJobInfoDeleteDialog";
import { SchPayReceiptDeleteDialog } from "../Scholar/Pages/schPayReceipts/schPayReceipt-delete-dialog/SchPayReceiptDeleteDialog";
import { SchPublicationDeleteDialog } from "../Scholar/Pages/schPublications/schPublication-delete-dialog/SchPublicationDeleteDialog";
import { SchCourseWorkInfoDeleteDialog } from "../Scholar/Pages/schCourseWorkInfos/schCourseWorkInfo-delete-dialog/SchCourseWorkInfoDeleteDialog";
import { SchSynopsysDeleteDialog } from "../Scholar/Pages/schSynopsyses/schSynopsys-delete-dialog/SchSynopsysDeleteDialog";

export default function ScholarPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact={true} from="/scholar" to="/scholar/profile " />

        <ContentRoute path="/scholar/profile" component={Profile} />

        {/* <ContentRoute path="/scholar/scheducations" component={SchEducationsPage} />   */}
        <ContentRoute
          path="/scholar/schconferences/new/:sid"
          component={SchConferenceEdit}
        />

        <ContentRoute
          path="/scholar/schdocuments/new/:sid"
          component={SchDocumentEdit}
        />
        <ContentRoute
          path="/scholar/scheducations/new/:sid"
          component={SchEducationEdit}
        />
        <ContentRoute
          path="/scholar/schjobinfos/new/:sid"
          component={SchJobInfoEdit}
        />
        <ContentRoute
          path="/scholar/schpayreceipts/new/:sid"
          component={SchPayReceiptEdit}
        />
        <ContentRoute
          path="/scholar/schpublications/new/:sid"
          component={SchPublicationEdit}
        />
        <ContentRoute
          path="/scholar/schcourseworkinfos/new/:sid"
          component={SchCourseWorkInfoEdit}
        />
        <ContentRoute
          path="/scholar/schsynopsyses/new/:sid"
          component={SchSynopsysEdit}
        />
        <ContentRoute
          path="/scholar/schthesises/new/:sid"
          component={SchThesisEdit}
        />

        <ContentRoute
          path="/scholar/schconferences/:id/edit"
          component={SchConferenceEdit}
        />

        <ContentRoute
          path="/scholar/schdocuments/:id/edit"
          component={SchDocumentEdit}
        />
        <ContentRoute
          path="/scholar/scheducations/:id/edit"
          component={SchEducationEdit}
        />
        <ContentRoute
          path="/scholar/schjobinfos/:id/edit"
          component={SchJobInfoEdit}
        />
        <ContentRoute
          path="/scholar/schpayreceipts/:id/edit"
          component={SchPayReceiptEdit}
        />
        <ContentRoute
          path="/scholar/schpublications/:id/edit"
          component={SchPublicationEdit}
        />
        <ContentRoute
          path="/scholar/schcourseworkinfos/:id/edit"
          component={SchCourseWorkInfoEdit}
        />
        <ContentRoute
          path="/scholar/schsynopsyses/:id/edit"
          component={SchSynopsysEdit}
        />
        <ContentRoute
          path="/scholar/schthesises/:id/edit"
          component={SchThesisEdit}
        />

        <ContentRoute path="/scholar/schEducations/:id/delete">
          {({ history, match }) => (
            <SchEducationDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schEducations");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schThesises/:id/delete">
          {({ history, match }) => (
            <SchThesisDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schThesises");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schSynopsyses/:id/delete">
          {({ history, match }) => (
            <SchSynopsysDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schSynopsyses");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schPublications/:id/delete">
          {({ history, match }) => (
            <SchPublicationDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schPublications");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schPayReceipts/:id/delete">
          {({ history, match }) => (
            <SchPayReceiptDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schPayReceipts");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schJobInfos/:id/delete">
          {({ history, match }) => (
            <SchJobInfoDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schJobInfos");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schDocuments/:id/delete">
          {({ history, match }) => (
            <SchDocumentDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schDocuments");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schCourseWorkInfos/:id/delete">
          {({ history, match }) => (
            <SchCourseWorkInfoDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schCourseWorkInfos");
              }}
            />
          )}
        </ContentRoute>

        <ContentRoute path="/scholar/schConferences/:id/delete">
          {({ history, match }) => (
            <SchConferenceDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/scholar/schConferences");
              }}
            />
          )}
        </ContentRoute>
      </Switch>
    </Suspense>
  );
}
