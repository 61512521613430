import * as requestFromServer from "./guiPublicationsCrud";
import { guiPublicationsSlice, callTypes } from "./guiPublicationsSlice";

const { actions } = guiPublicationsSlice;

export const fetchGuiPublications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiPublications(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiPublicationsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiPublications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiPublication = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiPublicationFetched({ guiPublicationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiPublicationById(id)
    .then((response) => {
      const guiPublication = response.data;
      dispatch(actions.guiPublicationFetched({ guiPublicationForEdit: guiPublication }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiPublication = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiPublication(id)
    .then((response) => {
      dispatch(actions.guiPublicationDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiPublication = (guiPublicationForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiPublicationForCreation.isActive = true;
  return requestFromServer
    .createGuiPublication(guiPublicationForCreation)
    .then((response) => {
      const { guiPublication } = response.data;
      guiPublication.id = guiPublication.guiPublicationId;
      dispatch(actions.guiPublicationCreated({ guiPublication }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiPublication = (guiPublication) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiPublication(guiPublication)
    .then(() => {
      dispatch(actions.guiPublicationUpdated({ guiPublication }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiPublication";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiPublications = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiPublications(ids)
    .then(() => {
      dispatch(actions.guiPublicationsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiPublications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
