import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { adminMenusSlice } from "../app/modules/Admin/_redux/adminMenus/adminMenusSlice";
import { pageContentsSlice } from "../app/modules/Admin/_redux/pageContents/pageContentsSlice";
import { webMenusSlice } from "../app/modules/Admin/_redux/webMenus/webMenusSlice";
import { countriesSlice } from "../app/modules/Admin/_redux/countries/countriesSlice";
import { institutesSlice } from "../app/modules/Admin/_redux/institutes/institutesSlice";
import { departmentsSlice } from "../app/modules/Admin/_redux/departments/departmentsSlice";
import { programsSlice } from "../app/modules/Admin/_redux/programs/programsSlice";
import { letterTypesSlice } from "../app/modules/Admin/_redux/letterTypes/letterTypesSlice";
import { conferencesSlice } from "../app/modules/Admin/_redux/conferences/conferencesSlice";
// import {galleryImageSlice} from "../app/modules/Admin/_redux/galleryImages/galleryImagesSlice";
import { galleryImagesSlice } from "../app/modules/Admin/_redux/galleryImages/galleryImagesSlice";
import { galleriesSlice } from "../app/modules/Admin/_redux/galleries/galleriesSlice";
import { newssSlice } from "../app/modules/Admin/_redux/newss/newssSlice";
import { coursesSlice } from "../app/modules/Admin/_redux/courses/coursesSlice";
import { eventInfosSlice } from "../app/modules/Admin/_redux/eventInfos/eventInfosSlice";
import { rtiUploadsSlice } from "../app/modules/Admin/_redux/rtiUploads/rtiUploadsSlice";
import { facultyMembersSlice } from "../app/modules/Admin/_redux/facultyMembers/facultyMembersSlice";
import { universityInfosSlice } from "../app/modules/Admin/_redux/universityInfos/universityInfosSlice";
import { departmentSubsSlice } from "../app/modules/Admin/_redux/departmentSubs/departmentSubsSlice";
import { docTypesSlice } from "../app/modules/Admin/_redux/docTypes/docTypesSlice";
import { downloadDocsSlice } from "../app/modules/Admin/_redux/downloadDocs/downloadDocsSlice";
import { feeTypesSlice } from "../app/modules/Admin/_redux/feeTypes/feeTypesSlice";
import { usersSlice } from "../app/modules/Admin/_redux/users/usersSlice";
import { userRolesSlice } from "../app/modules/Admin/_redux/userRoles/userRolesSlice";
import { userRoleRightsSlice } from "../app/modules/Admin/_redux/userRoleRights/userRoleRightsSlice";
import { scholarsSlice } from "../app/modules/Admin/_redux/scholars/scholarsSlice";



import { guidesSlice } from "../app/modules/Admin/_redux/guides/guidesSlice";
import { schConferencesSlice } from "../app/modules/Scholar/_redux/schConferences/schConferencesSlice";
import { schCourseWorkInfosSlice } from "../app/modules/Scholar/_redux/schCourseWorkInfos/schCourseWorkInfosSlice";
import { schDocumentsSlice } from "../app/modules/Scholar/_redux/schDocuments/schdocumentsSlice";
import { schThesisesSlice } from "../app/modules/Scholar/_redux/schThesises/schThesisesSlice";
import { schEducationsSlice } from "../app/modules/Scholar/_redux/schEducations/schEducationsSlice";
import { schJobInfosSlice } from "../app/modules/Scholar/_redux/schJobInfos/schJobInfosSlice";
import { schPayReceiptsSlice } from "../app/modules/Scholar/_redux/schPayReceipts/schPayReceiptsSlice";
import { schPublicationsSlice } from "../app/modules/Scholar/_redux/schPublications/schPublicationsSlice";
import { schSynopsysesSlice } from "../app/modules/Scholar/_redux/schSynopsyses/schSynopsysesSlice";
import { guiAddressesSlice } from "../app/modules/Guide/_redux/guiAddresses/guiAddressesSlice";
import { guiAwardsSlice } from "../app/modules/Guide/_redux/guiAwards/guiAwardsSlice";
import { guiConfsSlice } from "../app/modules/Guide/_redux/guiConfs/guiConfsSlice";
import { guiDocumentsSlice } from "../app/modules/Guide/_redux/guiDocuments/guiDocumentsSlice";
import { guiEducationsSlice } from "../app/modules/Guide/_redux/guiEducations/guiEducationsSlice";
import { guiJobInfosSlice } from "../app/modules/Guide/_redux/guiJobInfos/guiJobInfosSlice";
import { guiPatentsSlice } from "../app/modules/Guide/_redux/guiPatents/guiPatentsSlice";
import { guiPublicationsSlice } from "../app/modules/Guide/_redux/guiPublications/guiPublicationsSlice";
import { guiJjtuScholarsSlice } from "../app/modules/Guide/_redux/guiJjtuScholars/guiJjtuScholarsSlice";
import { scholarConferencesSlice } from "../app/modules/Admin/_redux/scholarConferences/scholarConferencesSlice";
import { scholarCourseWorkInfosSlice } from "../app/modules/Admin/_redux/scholarCourseWorkInfos/scholarCourseWorkInfosSlice";
import { scholarDocumentsSlice } from "../app/modules/Admin/_redux/scholarDocuments/scholarDocumentsSlice";
import { scholarThesisesSlice } from "../app/modules/Admin/_redux/scholarThesises/scholarThesisesSlice";
import { scholarEducationsSlice } from "../app/modules/Admin/_redux/scholarEducations/scholarEducationsSlice";
import { scholarJobInfosSlice } from "../app/modules/Admin/_redux/scholarJobInfos/scholarJobInfosSlice";
import { scholarPayReceiptsSlice } from "../app/modules/Admin/_redux/scholarPayReceipts/scholarPayReceiptsSlice";
import { scholarPublicationsSlice } from "../app/modules/Admin/_redux/scholarPublications/scholarPublicationsSlice";
import { scholarSynopsysesSlice } from "../app/modules/Admin/_redux/scholarSynopsyses/scholarSynopsysesSlice";
import { guideAddressesSlice } from "../app/modules/Admin/_redux/guideAddresses/guideAddressesSlice";
import { guideAwardsSlice } from "../app/modules/Admin/_redux/guideAwards/guideAwardsSlice";
import { guideConfsSlice } from "../app/modules/Admin/_redux/guideConfs/guideConfsSlice";
import { guideDocumentsSlice } from "../app/modules/Admin/_redux/guideDocuments/guideDocumentsSlice";
import { guideEducationsSlice } from "../app/modules/Admin/_redux/guideEducations/guideEducationsSlice";
import { guideJobInfosSlice } from "../app/modules/Admin/_redux/guideJobInfos/guideJobInfosSlice";
import { guidePatentsSlice } from "../app/modules/Admin/_redux/guidePatents/guidePatentsSlice";
import { guidePublicationsSlice } from "../app/modules/Admin/_redux/guidePublications/guidePublicationsSlice";
import { guideJjtuScholarsSlice } from "../app/modules/Admin/_redux/guideJjtuScholars/guideJjtuScholarsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  adminMenus: adminMenusSlice.reducer,
  pageContents: pageContentsSlice.reducer,
  webMenus: webMenusSlice.reducer,
  countries: countriesSlice.reducer,
  departments: departmentsSlice.reducer,
  institutes: institutesSlice.reducer,
  programs: programsSlice.reducer,
  letterTypes: letterTypesSlice.reducer,
  conferences: conferencesSlice.reducer,
  galleryImages: galleryImagesSlice.reducer,
  galleries: galleriesSlice.reducer,
  newss: newssSlice.reducer,
  courses: coursesSlice.reducer,
  eventInfos: eventInfosSlice.reducer,
  rtiUploads: rtiUploadsSlice.reducer,
  facultyMembers: facultyMembersSlice.reducer,
  universityInfos: universityInfosSlice.reducer,
  departmentSubs: departmentSubsSlice.reducer,
  docTypes: docTypesSlice.reducer,
  downloadDocs: downloadDocsSlice.reducer,
  users: usersSlice.reducer,
  userRoles: userRolesSlice.reducer,
  userRoleRights: userRoleRightsSlice.reducer,
  feeTypes: feeTypesSlice.reducer,
  scholars: scholarsSlice.reducer,
  schThesises: schThesisesSlice.reducer,
 
  guides: guidesSlice.reducer,
  schConferences: schConferencesSlice.reducer,
  schCourseWorkInfos: schCourseWorkInfosSlice.reducer,
  schDocuments: schDocumentsSlice.reducer,
  schEducations: schEducationsSlice.reducer,
  schJobInfos: schJobInfosSlice.reducer,
  schPayReceipts: schPayReceiptsSlice.reducer,
  schPublications: schPublicationsSlice.reducer,
  schSynopsyses: schSynopsysesSlice.reducer,
  guiAddresses: guiAddressesSlice.reducer,
  guiAwards: guiAwardsSlice.reducer,
  guiConfs: guiConfsSlice.reducer,
  guiDocuments: guiDocumentsSlice.reducer,
  guiEducations: guiEducationsSlice.reducer,
  guiJobInfos: guiJobInfosSlice.reducer,
  guiPatents: guiPatentsSlice.reducer,
  guiPublications: guiPublicationsSlice.reducer,
  guiJjtuScholars: guiJjtuScholarsSlice.reducer,
  scholarConferences: scholarConferencesSlice.reducer,
  scholarCourseWorkInfos: scholarCourseWorkInfosSlice.reducer,
  scholarDocuments: scholarDocumentsSlice.reducer,
  scholarEducations: scholarEducationsSlice.reducer,
  scholarJobInfos: scholarJobInfosSlice.reducer,
  scholarPayReceipts: scholarPayReceiptsSlice.reducer,
  scholarPublications: scholarPublicationsSlice.reducer,
  scholarSynopsyses: scholarSynopsysesSlice.reducer,
  scholarThesises: scholarThesisesSlice.reducer,
  guideAddresses: guideAddressesSlice.reducer,
  guideAwards: guideAwardsSlice.reducer,
  guideConfs: guideConfsSlice.reducer,
  guideDocuments: guideDocumentsSlice.reducer,
  guideEducations: guideEducationsSlice.reducer,
  guideJobInfos: guideJobInfosSlice.reducer,
  guidePatents: guidePatentsSlice.reducer,
  guidePublications: guidePublicationsSlice.reducer,
  guideJjtuScholars: guideJjtuScholarsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
