// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
//import { Select } from "../../../../../../_metronic/_partials/controls";

const SchCourseWorkInfoEditSchema = Yup.object().shape({
  firstCwStartDate: Yup.date().required("Date is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/scholar`;

export function SchCourseWorkInfoEditForm({
  schCourseWorkInfo,
  btnRef,
  saveSchCourseWorkInfo,
}) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={schCourseWorkInfo}
        validationSchema={SchCourseWorkInfoEditSchema}
        onSubmit={(values) => {
          try {
            values.scholarId = parseInt(values.scholarId);
          } catch {}
          saveSchCourseWorkInfo(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
                Scholar Id: {schCourseWorkInfo.scholarId}
                {/* <Field
                  name="scholarId"
                  component={Select}
                  placeholder="Scholar Name"
                  label="Scholar Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.scholarId}>
                                {menu.scholarName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                />
              </div> */}
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="First Course Work Start Date"
                  type="date"
                  name="firstCwStartDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("firstCwStartDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Frist Course Work End Date"
                  type="date"
                  name="fristCwEndDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("fristCwEndDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Second Course Work Start Date"
                  type="date"
                  name="secondCwStartDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("secondCwStartDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Second Cw End Date"
                  type="date"
                  name="secondCwEndDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("secondCwEndDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Certificate Issue Date"
                  type="date"
                  name="certificateIssueDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("certificateIssueDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Mark Sheet Issued Date"
                  type="date"
                  name="markSheetIssuedDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("markSheetIssuedDate", e.target.value);
                  }}
                />
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
