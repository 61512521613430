// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import {
  Input,
 
} from "../../../../../../_metronic/_partials/controls";


// Validation schema
const SchPublicationEditSchema = Yup.object().shape({
  authorNames: Yup.string().required("Name is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/scholar`;

export function SchPublicationEditForm({ schPublication, btnRef, saveSchPublication }) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={schPublication}
        validationSchema={SchPublicationEditSchema}
        onSubmit={(values) => {
          try {
            values.scholarId = parseInt(values.scholarId);
          }catch {}
          saveSchPublication(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
            <div className="form-group">
            Scholar Id:  {schPublication.scholarId}
                {/* <Field
                  name="scholarId"
                  component={Select}
                  placeholder="Scholar Name"
                  label="Scholar Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.scholarId}>
                                {menu.scholarName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
                <Field
                  name="authorNames"
                  component={Input}
                  placeholder="Author Names"
                  label="Author Names"
                />
              </div>

              <div className="form-group">
                <Field
                  name="titleOfPaper"
                  component={Input}
                  placeholder="Title Of Paper"
                  label="Title Of Paper"
                />
              </div>

              <div className="form-group">
                <Field
                  name="journalname"
                  component={Input}
                  placeholder="Journal Name"
                  label="Journal Name"
                />
              </div>

              <div className="form-group">
                <Field
                  name="yearOfPublication"
                  component={Input}
                  placeholder="Year Of Publication"
                  label="Year Of Publication"
                />
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
