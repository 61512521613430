import {createSlice} from "@reduxjs/toolkit";

const initialGuiJobInfosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guiJobInfoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guiJobInfosSlice = createSlice({
  name: "guiJobInfos",
  initialState: initialGuiJobInfosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuiJobInfoById
    guiJobInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.guiJobInfoForEdit = action.payload.guiJobInfoForEdit;
      state.error = null;
    },
    // findGuiJobInfos
    guiJobInfosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuiJobInfo
    guiJobInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guiJobInfo);
    },
    // updateGuiJobInfo
    guiJobInfoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiJobInfoId === action.payload.guiJobInfo.guiJobInfoId) {
          return action.payload.guiJobInfo;
        }
        return entity;
      });
    },
    // deleteGuiJobInfo
    guiJobInfoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiJobInfoId !== action.payload.guiJobInfoId);
    },
    // deleteGuiJobInfos
    guiJobInfosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiJobInfoId)
      );
    },
  }
});
