import axios from "axios";

export const SCH_EDUCATIONS_URL = `${process.env.REACT_APP_API_URL}/api/SchEducation`;

// CREATE =>  POST: add a new schEducation to the server
export function createSchEducation(schEducation) {
  return axios.post(SCH_EDUCATIONS_URL, schEducation);
}

// READ
export function getAllSchEducations() {
  return axios.get(SCH_EDUCATIONS_URL);
}

export function getSchEducationById(schEducationId) {
  return axios.get(`${SCH_EDUCATIONS_URL}/${schEducationId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchEducations(queryParams) {
  return axios.post(`${SCH_EDUCATIONS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchEducation(schEducation) {
  return axios.put(`${SCH_EDUCATIONS_URL}/${schEducation.schEducationId}`, schEducation);
}

// // UPDATE Status
// export function updateStatusForSchEducations(ids, status) {
//   return axios.post(`${SCH_EDUCATIONS_URL}/updateStatusForSchEducations`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schEducation from the server
export function deleteSchEducation(schEducationId) {
  return axios.delete(`${SCH_EDUCATIONS_URL}/${schEducationId}`);
}

// DELETE SchEducations by ids
export function deleteSchEducations(ids) {
  return axios.post(`${SCH_EDUCATIONS_URL}/deleteSchEducations`, { ids });
}
