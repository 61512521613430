/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "./../../../_redux/schDocuments/schdocumentsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchDocumentEditForm } from "./SchDocumentEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import {Profile} from "../../profile/Profile";
import axios from "axios";

const initSchDocument = {
  id: undefined,
  scholarId: 0,
  docType: "",
  docTitle: "",
  docPath: "",
};
export function SchDocumentEdit({
  history,
  match: {
    params: { id,sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schDocumentForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schDocuments.actionsLoading,
      schDocumentForEdit: state.schDocuments.schDocumentForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchDocument(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar Document";
    if (schDocumentForEdit && id) {
      _title = `Edit Scholar Document `;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schDocumentForEdit, id]);

  const saveSchDocument = (values) => {
    if (!id) {
      dispatch(actions.createSchDocument(values)).then(() => backToSchDocumentsList());
    } else {
      dispatch(actions.updateSchDocument(values)).then(() => backToSchDocumentsList());
    }
  };

  const btnRef = useRef();
  const saveSchDocumentClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const id1 = id===undefined?'':id
 //for close button
 const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schDocument/${id1}`;

 const [profile, setProfile] = useState([]);
 useEffect(() => {
   const getScholar = async () => {
     const response = await axios.get(`${ScholarUrl}`);
     setProfile(response.data);
   };
   getScholar();
 }, [setProfile, ScholarUrl]);
 const ScholarId = sid?sid:profile.scholarId;
 
 
 
 initSchDocument.scholarId=ScholarId;



  const backToSchDocumentsList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div>
      <Profile />
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchDocumentsList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchDocumentClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {!actionsLoading && (
                <SchDocumentEditForm
                  actionsLoading={actionsLoading}
                  schDocument={schDocumentForEdit || initSchDocument}
                  btnRef={btnRef}
                  saveSchDocument={saveSchDocument}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
