/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/schPublications/schPublicationsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchPublicationEditForm } from "./SchPublicationEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import { Profile } from "../../profile/Profile";
import axios from "axios";

const initSchPublication = {
  id: undefined,
  authorNames: "",
  titleOfPaper: "",
  journalname: "",
  yearOfPublication: "",
};
export function SchPublicationEdit({
  history,
  match: {
    params: { id,sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schPublicationForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schPublications.actionsLoading,
      schPublicationForEdit: state.schPublications.schPublicationForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchPublication(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar Publication";
    if (schPublicationForEdit && id) {
      _title = `Edit Scholar Publication `;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schPublicationForEdit, id]);

  const saveSchPublication = (values) => {
    if (!id) {
      dispatch(actions.createSchPublication(values)).then(() =>
        backToSchPublicationsList()
      );
    } else {
      dispatch(actions.updateSchPublication(values)).then(() =>
        backToSchPublicationsList()
      );
    }
  };

  const btnRef = useRef();
  const saveSchPublicationClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };


  const id1 = id===undefined?'':id
  //for close button
 const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schPublication/${id1}`;

 const [profile, setProfile] = useState([]);
 useEffect(() => {
   const getScholar = async () => {
     const response = await axios.get(`${ScholarUrl}`);
     setProfile(response.data);
   };
   getScholar();
 }, [setProfile, ScholarUrl]);
 const ScholarId = sid?sid:profile.scholarId;
 
 
 
 initSchPublication.scholarId=ScholarId;


  const backToSchPublicationsList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div>
      <Profile />
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchPublicationsList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchPublicationClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {!actionsLoading && (
                <SchPublicationEditForm
                  actionsLoading={actionsLoading}
                  schPublication={schPublicationForEdit || initSchPublication}
                  btnRef={btnRef}
                  saveSchPublication={saveSchPublication}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
