/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/guiDocuments/guiDocumentsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { GuiDocumentEditForm } from "./GuiDocumentEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import {GuideProfile} from "../../profile/GuideProfile"
import axios from "axios";

const initGuiDocument = {
  id: undefined,
  guideId: 0,
  docType: "",
  docTitle: "",
  docPath: "",
  
};
export function GuiDocumentEdit({
  history,
  match: {
    params: { id,gid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, guiDocumentForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.guiDocuments.actionsLoading,
      guiDocumentForEdit: state.guiDocuments.guiDocumentForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchGuiDocument(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Guide Document";
    if (guiDocumentForEdit && id) {
      _title = `Edit Guide Document `;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guiDocumentForEdit, id]);

  const saveGuiDocument = (values) => {
    if (!id) {
      dispatch(actions.createGuiDocument(values)).then(() =>
        backToGuiDocumentsList()
      );
    } else {
      dispatch(actions.updateGuiDocument(values)).then(() =>
        backToGuiDocumentsList()
      );
    }
  };

  const btnRef = useRef();
  const saveGuiDocumentClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const id1 = id===undefined?'':id
  const GuideUrl = `${process.env.REACT_APP_API_URL}/api/guiDocument/${id1}`;

  const [guide, setGuide] = useState([]);

  useEffect(() => {
    const getGuide = async () => {
      const response = await axios.get(GuideUrl);
      setGuide(response.data);
    };
    getGuide();
  }, [setGuide,GuideUrl]);


  const GuideId = gid?gid:guide.guideId;
 
 
 
  initGuiDocument.guideId=GuideId;

  console.log(GuideId)

  const backToGuiDocumentsList = () => {
    history.push(`/guide/guideprofile`);
  };

  return (
    <div>
      <GuideProfile />
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToGuiDocumentsList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveGuiDocumentClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
            {!actionsLoading && (
              <GuiDocumentEditForm
                actionsLoading={actionsLoading}
                guiDocument={guiDocumentForEdit || initGuiDocument}
                btnRef={btnRef}
                saveGuiDocument={saveGuiDocument}
                />
                )}
            </CardBody>
          </Card>
        </div>
      </div></div>
  );
}
