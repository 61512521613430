import * as requestFromServer from "./schdocumentsCrud";
import { schDocumentsSlice, callTypes } from "./schdocumentsSlice";

const { actions } = schDocumentsSlice;

export const fetchSchDocuments = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchDocuments(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schDocumentsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schDocuments";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchDocument = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schDocumentFetched({ schDocumentForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchDocumentById(id)
    .then((response) => {
      const schDocument = response.data;
      dispatch(actions.schDocumentFetched({ schDocumentForEdit: schDocument }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchDocument = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchDocument(id)
    .then((response) => {
      dispatch(actions.schDocumentDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchDocument = (schDocumentForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schDocumentForCreation.isActive = true;
  return requestFromServer
    .createSchDocument(schDocumentForCreation)
    .then((response) => {
      const { schDocument } = response.data;
      schDocument.id = schDocument.schDocumentId;
      dispatch(actions.schDocumentCreated({ schDocument }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchDocument = (schDocument) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchDocument(schDocument)
    .then(() => {
      dispatch(actions.schDocumentUpdated({ schDocument }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schDocument";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchDocuments = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchDocuments(ids)
    .then(() => {
      dispatch(actions.schDocumentsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schDocuments";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
