import {createSlice} from "@reduxjs/toolkit";

const initialGuideAddressesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideAddressForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guideAddressesSlice = createSlice({
  name: "guideAddresses",
  initialState: initialGuideAddressesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideAddressById
    guideAddressFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideAddressForEdit = action.payload.guideAddressForEdit;
      state.error = null;
    },
    // findGuideAddresses
    guideAddressesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuideAddress
    guideAddressCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guideAddress);
    },
    // updateGuideAddress
    guideAddressUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiAddressId === action.payload.guideAddress.guiAddressId) {
          return action.payload.guideAddress;
        }
        return entity;
      });
    },
    // deleteGuideAddress
    guideAddressDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiAddressId !== action.payload.guiAddressId);
    },
    // deleteGuideAddresses
    guideAddressesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiAddressId)
      );
    },
  }
});
