import axios from "axios";

export const SCH_DOCUMENTS_URL = `${process.env.REACT_APP_API_URL}/api/SchDocument`;

// CREATE =>  POST: add a new schDocument to the server
export function createSchDocument(schDocument) {
  return axios.post(SCH_DOCUMENTS_URL, schDocument);
}

// READ
export function getAllSchDocuments() {
  return axios.get(SCH_DOCUMENTS_URL);
}

export function getSchDocumentById(schDocumentId) {
  return axios.get(`${SCH_DOCUMENTS_URL}/${schDocumentId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchDocuments(queryParams) {
  return axios.post(`${SCH_DOCUMENTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchDocument(schDocument) {
  return axios.put(`${SCH_DOCUMENTS_URL}/${schDocument.schDocumentId}`, schDocument);
}

// // UPDATE Status
// export function updateStatusForSchDocuments(ids, status) {
//   return axios.post(`${SCH_DOCUMENTS_URL}/updateStatusForSchDocuments`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schDocument from the server
export function deleteSchDocument(schDocumentId) {
  return axios.delete(`${SCH_DOCUMENTS_URL}/${schDocumentId}`);
}

// DELETE SchDocuments by ids
export function deleteSchDocuments(ids) {
  return axios.post(`${SCH_DOCUMENTS_URL}/deleteSchDocuments`, { ids });
}
