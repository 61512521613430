import * as requestFromServer from "./schSynopsysesCrud";
import { schSynopsysesSlice, callTypes } from "./schSynopsysesSlice";

const { actions } = schSynopsysesSlice;

export const fetchSchSynopsyses = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchSynopsyses(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schSynopsysesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schSynopsyses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchSynopsys = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schSynopsysFetched({ schSynopsysForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchSynopsysById(id)
    .then((response) => {
      const schSynopsys = response.data;
      dispatch(actions.schSynopsysFetched({ schSynopsysForEdit: schSynopsys }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schSynopsys";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchSynopsys = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchSynopsys(id)
    .then((response) => {
      dispatch(actions.schSynopsysDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schSynopsys";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchSynopsys = (schSynopsysForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schSynopsysForCreation.isActive = true;
  return requestFromServer
    .createSchSynopsys(schSynopsysForCreation)
    .then((response) => {
      const { schSynopsys } = response.data;
      schSynopsys.id = schSynopsys.schSynopsysId;
      dispatch(actions.schSynopsysCreated({ schSynopsys }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schSynopsys";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchSynopsys = (schSynopsys) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchSynopsys(schSynopsys)
    .then(() => {
      dispatch(actions.schSynopsysUpdated({ schSynopsys }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schSynopsys";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchSynopsyses = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchSynopsyses(ids)
    .then(() => {
      dispatch(actions.schSynopsysesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schSynopsyses";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
