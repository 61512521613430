import {createSlice} from "@reduxjs/toolkit";

const initialSchSynopsysesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  schSynopsysForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const schSynopsysesSlice = createSlice({
  name: "schSynopsyses",
  initialState: initialSchSynopsysesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSchSynopsysById
    schSynopsysFetched: (state, action) => {
      state.actionsLoading = false;
      state.schSynopsysForEdit = action.payload.schSynopsysForEdit;
      state.error = null;
    },
    // findSchSynopsyses
    schSynopsysesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSchSynopsys
    schSynopsysCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.schSynopsys);
    },
    // updateSchSynopsys
    schSynopsysUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schSynopsysId === action.payload.schSynopsys.schSynopsysId) {
          return action.payload.schSynopsys;
        }
        return entity;
      });
    },
    // deleteSchSynopsys
    schSynopsysDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schSynopsysId !== action.payload.schSynopsysId);
    },
    // deleteSchSynopsyses
    schSynopsysesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schSynopsysId)
      );
    },
  }
});
