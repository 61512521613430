import axios from "axios";

export const GUI_DOCUMENTS_URL = `${process.env.REACT_APP_API_URL}/api/GuiDocument`;

// CREATE =>  POST: add a new guiDocument to the server
export function createGuiDocument(guiDocument) {
  return axios.post(GUI_DOCUMENTS_URL, guiDocument);
}

// READ
export function getAllGuiDocuments() {
  return axios.get(GUI_DOCUMENTS_URL);
}

export function getGuiDocumentById(guiDocumentId) {
  return axios.get(`${GUI_DOCUMENTS_URL}/${guiDocumentId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiDocuments(queryParams) {
  return axios.post(`${GUI_DOCUMENTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiDocument(guiDocument) {
  return axios.put(`${GUI_DOCUMENTS_URL}/${guiDocument.guiDocumentId}`, guiDocument);
}

// // UPDATE Status
// export function updateStatusForGuiDocuments(ids, status) {
//   return axios.post(`${GUI_DOCUMENTS_URL}/updateStatusForGuiDocuments`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiDocument from the server
export function deleteGuiDocument(guiDocumentId) {
  return axios.delete(`${GUI_DOCUMENTS_URL}/${guiDocumentId}`);
}

// DELETE GuiDocuments by ids
export function deleteGuiDocuments(ids) {
  return axios.post(`${GUI_DOCUMENTS_URL}/deleteGuiDocuments`, { ids });
}
