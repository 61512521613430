import {createSlice} from "@reduxjs/toolkit";

const initialScholarsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const scholarsSlice = createSlice({
  name: "scholars",
  initialState: initialScholarsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScholarById
    scholarFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarForEdit = action.payload.scholarForEdit;
      state.error = null;
    },
    // findScholars
    scholarsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createScholar
    scholarCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.scholar);
    },
    // updateScholar
    scholarUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.scholarId === action.payload.scholar.scholarId) {
          return action.payload.scholar;
        }
        return entity;
      });
    },
    // deleteScholar
    scholarDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.scholarId !== action.payload.scholarId);
    },
    // deleteScholars
    scholarsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.scholarId)
      );
    },
  }
});
