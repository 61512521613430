// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import {
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";


// Validation schema
const GuiJjtuScholarEditSchema = Yup.object().shape({
  allocationDate: Yup.date().required("Allocation Date is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/guide`;
const PageUrl1 = `${process.env.REACT_APP_API_URL}/api/Scholar`;

export function GuiJjtuScholarEditForm({ guiJjtuScholar, btnRef, saveGuiJjtuScholar }) {
  //const [menuForDropdown, setMenuForDropdown] = useState([]);
  const [menuForDropdown1, setMenuForDropdown1] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

  useEffect(() => {
    const getInnerPagesWithAxios1 = async () => {
      const response = await axios.get(PageUrl1);
      setMenuForDropdown1(response.data);
    };
    getInnerPagesWithAxios1();
  }, [setMenuForDropdown1]);


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={guiJjtuScholar}
        validationSchema={GuiJjtuScholarEditSchema}
        onSubmit={(values) => {
          try {
            values.guideId = parseInt(values.guideId);
            values.scholarId = parseInt(values.scholarId);
          }catch {}
          saveGuiJjtuScholar(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values,touched, errors  }) => (
          <>
            <Form className="form form-label-right">
              
              <div className="form-group">
              Guide Id: {guiJjtuScholar.guideId}
                {/* <Field
                  name="guideId"
                  component={Select}
                  placeholder="Guide Name"
                  label="Guide Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.guideId}>
                                {menu.guideName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
           
                <Field
                  name="scholarId"
                  component={Select}
                  placeholder="Scholar Name"
                  label="Scholar Name"
                  children={
                    menuForDropdown1 ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown1
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.scholarId}>
                                {menu.scholarName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> 
              </div>
              <div className="form-group">
                <TextField
                  id="date"
                  label="Allocation Date"
                  type="date"
                  name="allocationDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("allocationDate", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <Field
                  name="remark"
                  component={Input}
                  placeholder="Remark"
                  label="Remark"
                />
              </div>
           
             
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
