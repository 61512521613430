import {createSlice} from "@reduxjs/toolkit";

const initialGuiPublicationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guiPublicationForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guiPublicationsSlice = createSlice({
  name: "guiPublications",
  initialState: initialGuiPublicationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuiPublicationById
    guiPublicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.guiPublicationForEdit = action.payload.guiPublicationForEdit;
      state.error = null;
    },
    // findGuiPublications
    guiPublicationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuiPublication
    guiPublicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guiPublication);
    },
    // updateGuiPublication
    guiPublicationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiPublicationId === action.payload.guiPublication.guiPublicationId) {
          return action.payload.guiPublication;
        }
        return entity;
      });
    },
    // deleteGuiPublication
    guiPublicationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiPublicationId !== action.payload.guiPublicationId);
    },
    // deleteGuiPublications
    guiPublicationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiPublicationId)
      );
    },
  }
});
