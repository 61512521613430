import axios from "axios";

export const SCH_JOB_INFOS_URL = `${process.env.REACT_APP_API_URL}/api/SchJobInfo`;

// CREATE =>  POST: add a new schJobInfo to the server
export function createSchJobInfo(schJobInfo) {
  return axios.post(SCH_JOB_INFOS_URL, schJobInfo);
}

// READ
export function getAllSchJobInfos() {
  return axios.get(SCH_JOB_INFOS_URL);
}

export function getSchJobInfoById(schJobInfoId) {
  return axios.get(`${SCH_JOB_INFOS_URL}/${schJobInfoId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchJobInfos(queryParams) {
  return axios.post(`${SCH_JOB_INFOS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchJobInfo(schJobInfo) {
  return axios.put(`${SCH_JOB_INFOS_URL}/${schJobInfo.schJobInfoId}`, schJobInfo);
}

// // UPDATE Status
// export function updateStatusForSchJobInfos(ids, status) {
//   return axios.post(`${SCH_JOB_INFOS_URL}/updateStatusForSchJobInfos`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schJobInfo from the server
export function deleteSchJobInfo(schJobInfoId) {
  return axios.delete(`${SCH_JOB_INFOS_URL}/${schJobInfoId}`);
}

// DELETE SchJobInfos by ids
export function deleteSchJobInfos(ids) {
  return axios.post(`${SCH_JOB_INFOS_URL}/deleteSchJobInfos`, { ids });
}
