/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/schPayReceipts/schPayReceiptsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchPayReceiptEditForm } from "./SchPayReceiptEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import { Profile } from "../../profile/Profile";
import axios from "axios";

const initSchPayReceipt = {
  id: undefined,
  scholarId: 0,
  receiptNo: "",
  payDate: "2020-10-17T04:25:08.627Z",
  feeType: "",
  amount: 0,
  transactionNo: "",
  downlodReceipt: "",
  verifiedBy: 0,
  remark: "",
  uploadDoc: "",
  status: "",
};
export function SchPayReceiptEdit({
  history,
  match: {
    params: { id,sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schPayReceiptForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schPayReceipts.actionsLoading,
      schPayReceiptForEdit: state.schPayReceipts.schPayReceiptForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchPayReceipt(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar PayReceipt";
    if (schPayReceiptForEdit && id) {
      _title = `Edit Scholar PayReceipt`;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schPayReceiptForEdit, id]);

  const saveSchPayReceipt = (values) => {
    if (!id) {
      dispatch(actions.createSchPayReceipt(values)).then(() =>
        backToSchPayReceiptsList()
      );
    } else {
      dispatch(actions.updateSchPayReceipt(values)).then(() =>
        backToSchPayReceiptsList()
      );
    }
  };

  const btnRef = useRef();
  const saveSchPayReceiptClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const id1 = id===undefined?'':id
  const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schPayReceipt/${id1}`;

 const [profile, setProfile] = useState([]);
 useEffect(() => {
   const getScholar = async () => {
     const response = await axios.get(`${ScholarUrl}`);
     setProfile(response.data);
   };
   getScholar();
 }, [setProfile, ScholarUrl]);
 const ScholarId = sid?sid:profile.scholarId;
 
 
 
 initSchPayReceipt.scholarId=ScholarId;

  const backToSchPayReceiptsList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div>
      <Profile />
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchPayReceiptsList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchPayReceiptClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {!actionsLoading && (
                <SchPayReceiptEditForm
                  actionsLoading={actionsLoading}
                  schPayReceipt={schPayReceiptForEdit || initSchPayReceipt}
                  btnRef={btnRef}
                  saveSchPayReceipt={saveSchPayReceipt}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
