/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/schJobInfos/schJobInfosActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchJobInfoEditForm } from "./SchJobInfoEditForm";
import { Profile } from "../../profile/Profile";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import axios from "axios";

const initSchJobInfo = {
  id: undefined,
  scholarId: 0,
  companyName: "",
  jobTitle: "",
  jobType: "",
  startDate: "2020-10-17T07:37:21.178Z",
  endDate: "2020-10-17T07:37:21.178Z",
  expInYear: "",
  annexure: "",
};
export function SchJobInfoEdit({
  history,
  match: {
    params: { id,sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schJobInfoForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schJobInfos.actionsLoading,
      schJobInfoForEdit: state.schJobInfos.schJobInfoForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchJobInfo(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar JobInfo";
    if (schJobInfoForEdit && id) {
      _title = `Edit Scholar JobInfo'`;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schJobInfoForEdit, id]);

  const saveSchJobInfo = (values) => {
    if (!id) {
      dispatch(actions.createSchJobInfo(values)).then(() =>
        backToSchJobInfosList()
      );
    } else {
      dispatch(actions.updateSchJobInfo(values)).then(() =>
        backToSchJobInfosList()
      );
    }
  };

  const btnRef = useRef();
  const saveSchJobInfoClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };


  const id1 = id===undefined?'':id
  const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schJobInfo/${id1}`;

 const [profile, setProfile] = useState([]);
 useEffect(() => {
   const getScholar = async () => {
     const response = await axios.get(`${ScholarUrl}`);
     setProfile(response.data);
   };
   getScholar();
 }, [setProfile, ScholarUrl]);
 const ScholarId = sid?sid:profile.scholarId;
 
 
 
 initSchJobInfo.scholarId=ScholarId;



  const backToSchJobInfosList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div>
    <Profile/>
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchJobInfosList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchJobInfoClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {!actionsLoading && (
                <SchJobInfoEditForm
                  actionsLoading={actionsLoading}
                  schJobInfo={schJobInfoForEdit || initSchJobInfo}
                  btnRef={btnRef}
                  saveSchJobInfo={saveSchJobInfo}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
