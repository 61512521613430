import * as requestFromServer from "./schJobInfosCrud";
import { schJobInfosSlice, callTypes } from "./schJobInfosSlice";

const { actions } = schJobInfosSlice;

export const fetchSchJobInfos = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchJobInfos(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schJobInfosFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schJobInfos";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchJobInfo = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schJobInfoFetched({ schJobInfoForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchJobInfoById(id)
    .then((response) => {
      const schJobInfo = response.data;
      dispatch(actions.schJobInfoFetched({ schJobInfoForEdit: schJobInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchJobInfo = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchJobInfo(id)
    .then((response) => {
      dispatch(actions.schJobInfoDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchJobInfo = (schJobInfoForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schJobInfoForCreation.isActive = true;
  return requestFromServer
    .createSchJobInfo(schJobInfoForCreation)
    .then((response) => {
      const { schJobInfo } = response.data;
      schJobInfo.id = schJobInfo.schJobInfoId;
      dispatch(actions.schJobInfoCreated({ schJobInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchJobInfo = (schJobInfo) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchJobInfo(schJobInfo)
    .then(() => {
      dispatch(actions.schJobInfoUpdated({ schJobInfo }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schJobInfo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchJobInfos = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchJobInfos(ids)
    .then(() => {
      dispatch(actions.schJobInfosDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schJobInfos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
