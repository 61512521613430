// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import {
  Input,
  
} from "../../../../../../_metronic/_partials/controls";


// Validation schema
const GuiEducationEditSchema = Yup.object().shape({
  examName: Yup.string().required("Name is required"),
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/guide`;

export function GuiEducationEditForm({ guiEducation, btnRef, saveGuiEducation }) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={guiEducation}
        validationSchema={GuiEducationEditSchema}
        onSubmit={(values) => {
          try {
            values.guideId = parseInt(values.guideId);
          }catch {}
          saveGuiEducation(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              
              <div className="form-group">
              Guide Id: {guiEducation.guideId}
                {/* <Field
                  name="guideId"
                  component={Select}
                  placeholder="Guide Name"
                  label="Guide Menu"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.guideId}>
                                {menu.guideName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
                <Field
                  name="degreeType"
                  component={Input}
                  placeholder="degreeType"
                  label="degreeType"
                />
              </div>
              <div className="form-group">
                <Field
                  name="examName"
                  component={Input}
                  placeholder="Exam Name"
                  label="Exam Name"
                />
              </div>
              <div className="form-group">
                <Field
                  name="passingYear"
                  component={Input}
                  placeholder="Passing Year"
                  label="Passing Year"
                />
              </div>
              <div className="form-group">
                <Field
                  name="subjectStudied"
                  component={Input}
                  placeholder="Subject Studied"
                  label="Subject Studied"
                />
              </div>
              <div className="form-group">
                <Field
                  name="percentage"
                  component={Input}
                  placeholder="Percentage"
                  label="Percentage"
                />
              </div>
              <div className="form-group">
                <Field
                  name="university"
                  component={Input}
                  placeholder="University"
                  label="University"
                />
              </div>
             
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
