// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
//import axios from "axios";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import "date-fns";
//import DateFnsAdapter from "@date-io/date-fns";
import { Input } from "../../../../../../_metronic/_partials/controls";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

// Validation schema
const SchConferenceEditSchema = Yup.object().shape({
  confTitle: Yup.string().required("Title is required"),
  
});

//const PageUrl = `${process.env.REACT_APP_API_URL}/api/Scholar`;

export function SchConferenceEditForm({
  schConference,
  btnRef,
  saveSchConference,
}) {
  // const [menuForDropdown, setMenuForDropdown] = useState([]);

  // useEffect(() => {
  //   const getInnerPagesWithAxios = async () => {
  //     const response = await axios.get(PageUrl);
  //     setMenuForDropdown(response.data);
  //   };
  //   getInnerPagesWithAxios();
  // }, [setMenuForDropdown]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={schConference}
        validationSchema={SchConferenceEditSchema}
        onSubmit={(values) => {
          try {
            values.scholarId = parseInt(values.scholarId);
          } catch {}
          saveSchConference(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group">
              Scholar Id:  {schConference.scholarId}
                {/* <Field
                  name="scholarId"
                  component={Select}
                  placeholder="Scholar Name"
                  label="Scholar Name"
                  children={
                    menuForDropdown ? (
                      <>
                        <option value="0">None</option>
                        {menuForDropdown
                        
                          .filter((menu) => menu.isActive)
                          .map((menu, index) => {
                            return (
                              <option key={index} value={menu.scholarId}>
                                {menu.scholarName}
                              </option>
                            );
                          })}
                      </>
                    ) : (
                      <option value="0">None</option>
                    )
                  }
                /> */}
              </div>
              <div className="form-group">
                <Field
                  name="confTitle"
                  component={Input}
                  placeholder="Conf Title"
                  label="Conf Title"
                />
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  name="startDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("startDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  name="endDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFieldValue("endDate", e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Participated: </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Participated"
                    name="participated"
                    value={values.participated}
                    onChange={(e) => {
                      setFieldValue("participated", JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio color="primary" checked={values.participated} />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio color="primary" checked={!values.participated} />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Presented: </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Presented"
                    name="presented"
                    value={values.presented}
                    onChange={(e) => {
                      setFieldValue("presented", JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio color="primary" checked={values.presented} />
                      }
                      label="Yes"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio color="primary" checked={!values.presented} />
                      }
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-group">
                <Field
                  name="paperName"
                  component={Input}
                  placeholder="Paper Name"
                  label="Paper Name"
                />
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
