import * as requestFromServer from "./guiConfsCrud";
import { guiConfsSlice, callTypes } from "./guiConfsSlice";

const { actions } = guiConfsSlice;

export const fetchGuiConfs = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGuiConfs(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.guiConfsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiConfs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGuiConf = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.guiConfFetched({ guiConfForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGuiConfById(id)
    .then((response) => {
      const guiConf = response.data;
      dispatch(actions.guiConfFetched({ guiConfForEdit: guiConf }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find guiConf";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiConf = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiConf(id)
    .then((response) => {
      dispatch(actions.guiConfDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiConf";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGuiConf = (guiConfForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  guiConfForCreation.isActive = true;
  return requestFromServer
    .createGuiConf(guiConfForCreation)
    .then((response) => {
      const { guiConf } = response.data;
      guiConf.id = guiConf.guiConfId;
      dispatch(actions.guiConfCreated({ guiConf }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create guiConf";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGuiConf = (guiConf) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGuiConf(guiConf)
    .then(() => {
      dispatch(actions.guiConfUpdated({ guiConf }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update guiConf";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGuiConfs = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGuiConfs(ids)
    .then(() => {
      dispatch(actions.guiConfsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete guiConfs";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
