import axios from "axios";

export const SCH_SYNOPSYSES_URL = `${process.env.REACT_APP_API_URL}/api/SchSynopsys`;

// CREATE =>  POST: add a new schSynopsys to the server
export function createSchSynopsys(schSynopsys) {
  return axios.post(SCH_SYNOPSYSES_URL, schSynopsys);
}

// READ
export function getAllSchSynopsyses() {
  return axios.get(SCH_SYNOPSYSES_URL);
}

export function getSchSynopsysById(schSynopsysId) {
  return axios.get(`${SCH_SYNOPSYSES_URL}/${schSynopsysId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchSynopsyses(queryParams) {
  return axios.post(`${SCH_SYNOPSYSES_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchSynopsys(schSynopsys) {
  return axios.put(`${SCH_SYNOPSYSES_URL}/${schSynopsys.schSynopsysId}`, schSynopsys);
}

// // UPDATE Status
// export function updateStatusForSchSynopsyses(ids, status) {
//   return axios.post(`${SCH_SYNOPSYSES_URL}/updateStatusForSchSynopsyses`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schSynopsys from the server
export function deleteSchSynopsys(schSynopsysId) {
  return axios.delete(`${SCH_SYNOPSYSES_URL}/${schSynopsysId}`);
}

// DELETE SchSynopsyses by ids
export function deleteSchSynopsyses(ids) {
  return axios.post(`${SCH_SYNOPSYSES_URL}/deleteSchSynopsyses`, { ids });
}
