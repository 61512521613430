import * as requestFromServer from "./schEducationsCrud";
import { schEducationsSlice, callTypes } from "./schEducationsSlice";

const { actions } = schEducationsSlice;

export const fetchSchEducations = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchEducations(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.schEducationsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schEducations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchEducation = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.schEducationFetched({ schEducationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSchEducationById(id)
    .then((response) => {
      const schEducation = response.data;
      dispatch(actions.schEducationFetched({ schEducationForEdit: schEducation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find schEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchEducation = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchEducation(id)
    .then((response) => {
      dispatch(actions.schEducationDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSchEducation = (schEducationForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  schEducationForCreation.isActive = true;
  return requestFromServer
    .createSchEducation(schEducationForCreation)
    .then((response) => {
      const { schEducation } = response.data;
      schEducation.id = schEducation.schEducationId;
      dispatch(actions.schEducationCreated({ schEducation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create schEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSchEducation = (schEducation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSchEducation(schEducation)
    .then(() => {
      dispatch(actions.schEducationUpdated({ schEducation }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update schEducation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchEducations = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchEducations(ids)
    .then(() => {
      dispatch(actions.schEducationsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete schEducations";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
