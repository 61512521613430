import axios from "axios";

export const GUI_JOBINFOS_URL = `${process.env.REACT_APP_API_URL}/api/GuiJobInfo`;

// CREATE =>  POST: add a new guiJobInfo to the server
export function createGuiJobInfo(guiJobInfo) {
  return axios.post(GUI_JOBINFOS_URL, guiJobInfo);
}

// READ
export function getAllGuiJobInfos() {
  return axios.get(GUI_JOBINFOS_URL);
}

export function getGuiJobInfoById(guiJobInfoId) {
  return axios.get(`${GUI_JOBINFOS_URL}/${guiJobInfoId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGuiJobInfos(queryParams) {
  return axios.post(`${GUI_JOBINFOS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateGuiJobInfo(guiJobInfo) {
  return axios.put(`${GUI_JOBINFOS_URL}/${guiJobInfo.guiJobInfoId}`, guiJobInfo);
}

// // UPDATE Status
// export function updateStatusForGuiJobInfos(ids, status) {
//   return axios.post(`${GUI_JOBINFOS_URL}/updateStatusForGuiJobInfos`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the guiJobInfo from the server
export function deleteGuiJobInfo(guiJobInfoId) {
  return axios.delete(`${GUI_JOBINFOS_URL}/${guiJobInfoId}`);
}

// DELETE GuiJobInfos by ids
export function deleteGuiJobInfos(ids) {
  return axios.post(`${GUI_JOBINFOS_URL}/deleteGuiJobInfos`, { ids });
}
