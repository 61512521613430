import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SchConferencesUIHelpers";

const SchConferencesUIContext = createContext();

export function useSchConferencesUIContext() {
  return useContext(SchConferencesUIContext);
}

export const SchConferencesUIConsumer = SchConferencesUIContext.Consumer;

export function SchConferencesUIProvider({ schConferencesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newSchConferenceButtonClick: schConferencesUIEvents.newSchConferenceButtonClick,
    openEditSchConferencePage: schConferencesUIEvents.openEditSchConferencePage,
    openDeleteSchConferenceDialog: schConferencesUIEvents.openDeleteSchConferenceDialog,
    openDeleteSchConferencesDialog: schConferencesUIEvents.openDeleteSchConferencesDialog,
    openFetchSchConferencesDialog: schConferencesUIEvents.openFetchSchConferencesDialog,
  };

  return (
    <SchConferencesUIContext.Provider value={value}>
      {children}
    </SchConferencesUIContext.Provider>
  );
}
