import {createSlice} from "@reduxjs/toolkit";

const initialRtiUploadsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  rtiUploadForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const rtiUploadsSlice = createSlice({
  name: "rtiUploads",
  initialState: initialRtiUploadsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getRtiUploadById
    rtiUploadFetched: (state, action) => {
      state.actionsLoading = false;
      state.rtiUploadForEdit = action.payload.rtiUploadForEdit;
      state.error = null;
    },
    // findRtiUploads
    rtiUploadsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createRtiUpload
    rtiUploadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.rtiUpload);
    },
    // updateRtiUpload
    rtiUploadUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.rtiUploadId === action.payload.rtiUpload.rtiUploadId) {
          return action.payload.rtiUpload;
        }
        return entity;
      });
    },
    // deleteRtiUpload
    rtiUploadDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.rtiUploadId !== action.payload.rtiUploadId);
    },
    // deleteRtiUploads
    rtiUploadsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.rtiUploadId)
      );
    },
  }
});
