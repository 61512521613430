import axios from "axios";

export const SCH_PUBLICATIONS_URL = `${process.env.REACT_APP_API_URL}/api/SchPublication`;

// CREATE =>  POST: add a new schPublication to the server
export function createSchPublication(schPublication) {
  return axios.post(SCH_PUBLICATIONS_URL, schPublication);
}

// READ
export function getAllSchPublications() {
  return axios.get(SCH_PUBLICATIONS_URL);
}

export function getSchPublicationById(schPublicationId) {
  return axios.get(`${SCH_PUBLICATIONS_URL}/${schPublicationId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchPublications(queryParams) {
  return axios.post(`${SCH_PUBLICATIONS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchPublication(schPublication) {
  return axios.put(`${SCH_PUBLICATIONS_URL}/${schPublication.schPublicationId}`, schPublication);
}

// // UPDATE Status
// export function updateStatusForSchPublications(ids, status) {
//   return axios.post(`${SCH_PUBLICATIONS_URL}/updateStatusForSchPublications`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schPublication from the server
export function deleteSchPublication(schPublicationId) {
  return axios.delete(`${SCH_PUBLICATIONS_URL}/${schPublicationId}`);
}

// DELETE SchPublications by ids
export function deleteSchPublications(ids) {
  return axios.post(`${SCH_PUBLICATIONS_URL}/deleteSchPublications`, { ids });
}
