import {createSlice} from "@reduxjs/toolkit";

const initialGuideAwardsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  guideAwardForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const guideAwardsSlice = createSlice({
  name: "guideAwards",
  initialState: initialGuideAwardsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGuideAwardById
    guideAwardFetched: (state, action) => {
      state.actionsLoading = false;
      state.guideAwardForEdit = action.payload.guideAwardForEdit;
      state.error = null;
    },
    // findGuideAwards
    guideAwardsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGuideAward
    guideAwardCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.guideAward);
    },
    // updateGuideAward
    guideAwardUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.guiAwardId === action.payload.guideAward.guiAwardId) {
          return action.payload.guideAward;
        }
        return entity;
      });
    },
    // deleteGuideAward
    guideAwardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.guiAwardId !== action.payload.guiAwardId);
    },
    // deleteGuideAwards
    guideAwardsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.guiAwardId)
      );
    },
  }
});
