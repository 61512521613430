/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/schPublications/schPublicationsActions";
import { useSchPublicationsUIContext } from "../SchPublicationsUIContext";

export function SchPublicationDeleteDialog({ id, show, onHide }) {
  // SchPublications UI Context
  const schPublicationsUIContext = useSchPublicationsUIContext();
  const schPublicationsUIProps = useMemo(() => {
    return {
      setIds: schPublicationsUIContext.setIds,
      queryParams: schPublicationsUIContext.queryParams,
    };
  }, [schPublicationsUIContext]);

  // SchPublications Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.schPublications.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteSchPublication = () => {
    // server request for deleting schPublication by id
    dispatch(actions.deleteSchPublication(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchSchPublications(schPublicationsUIProps.queryParams));
      // clear selections list
      schPublicationsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          SchPublication Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this schPublication?</span>
        )}
        {isLoading && <span>SchPublication is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteSchPublication}
            className="btn btn-delete btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
