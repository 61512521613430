/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/schCourseWorkInfos/schCourseWorkInfosActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SchCourseWorkInfoEditForm } from "./SchCourseWorkInfoEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { KTUtil } from "../../../../../../_metronic/_assets/js/components/util";
import KTLayoutQuickForm from "../../../../Admin/utils/quick-form";
import { Profile } from "../../profile/Profile";
import axios from "axios";

const initSchCourseWorkInfo = {
  id: undefined,
  scholarId: 0,
  firstCwStartDate: "2020-10-17T04:18:29.988Z",
  fristCwEndDate: "2020-10-17T04:18:29.988Z",
  secondCwStartDate: "2020-10-17T04:18:29.988Z",
  secondCwEndDate: "2020-10-17T04:18:29.988Z",
  certificateIssueDate: "2020-10-17T04:18:29.988Z",
  markSheetIssuedDate: "2020-10-17T04:18:29.988Z",
};
export function SchCourseWorkInfoEdit({
  history,
  match: {
    params: { id,sid },
  },
}) {
  useLayoutEffect(() => {
    KTUtil.ready(function() {
      KTLayoutQuickForm.init("kt_quick_form");
    });
  }, []);
  // Subheader
  const subheader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, schCourseWorkInfoForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.schCourseWorkInfos.actionsLoading,
      schCourseWorkInfoForEdit: state.schCourseWorkInfos.schCourseWorkInfoForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSchCourseWorkInfo(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Scholar CourseWork Info";
    if (schCourseWorkInfoForEdit && id) {
      _title = `Edit Scholar CourseWork Info '`;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schCourseWorkInfoForEdit, id]);

  const saveSchCourseWorkInfo = (values) => {
    if (!id) {
      dispatch(actions.createSchCourseWorkInfo(values)).then(() =>
        backToSchCourseWorkInfosList()
      );
    } else {
      dispatch(actions.updateSchCourseWorkInfo(values)).then(() =>
        backToSchCourseWorkInfosList()
      );
    }
  };

  const btnRef = useRef();
  const saveSchCourseWorkInfoClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const id1 = id===undefined?'':id
  const ScholarUrl = `${process.env.REACT_APP_API_URL}/api/schCourseWorkInfo/${id1}`;

 const [profile, setProfile] = useState([]);
 useEffect(() => {
   const getScholar = async () => {
     const response = await axios.get(`${ScholarUrl}`);
     setProfile(response.data);
   };
   getScholar();
 }, [setProfile, ScholarUrl]);
 const ScholarId = sid?sid:profile.scholarId;
 
 
 
 initSchCourseWorkInfo.scholarId=ScholarId;

  const backToSchCourseWorkInfosList = () => {
    history.push(`/scholar/profile`);
  };

  return (
    <div><Profile/>
      <div
        id="kt_quick_form"
        className="offcanvas offcanvas-right offcanvas offcanvas-on offcanvas-sm"
      >
        <div className="offcanvas-content pr-5 mr-n5">
          <Card>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={backToSchCourseWorkInfosList}
                  className="btn btn-light"
                >
                  <i className="fa fa-times"></i>
                  Close
                </button>
                {/* <button className="btn btn-light ml-2">
                    <i className="fa fa-redo"></i>
                    Reset
                  </button> */}
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={saveSchCourseWorkInfoClick}
                >
                  Save
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
            {!actionsLoading && (
              <SchCourseWorkInfoEditForm
                actionsLoading={actionsLoading}
                schCourseWorkInfo={schCourseWorkInfoForEdit || initSchCourseWorkInfo}
                btnRef={btnRef}
                saveSchCourseWorkInfo={saveSchCourseWorkInfo}
                />
                )}
            </CardBody>
          </Card>
        </div>
      </div>
      </div>
  );
}
