import {createSlice} from "@reduxjs/toolkit";

const initialSchCourseWorkInfosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  schCourseWorkInfoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const schCourseWorkInfosSlice = createSlice({
  name: "schCourseWorkInfos",
  initialState: initialSchCourseWorkInfosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSchCourseWorkInfoById
    schCourseWorkInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.schCourseWorkInfoForEdit = action.payload.schCourseWorkInfoForEdit;
      state.error = null;
    },
    // findSchCourseWorkInfos
    schCourseWorkInfosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSchCourseWorkInfo
    schCourseWorkInfoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.schCourseWorkInfo);
    },
    // updateSchCourseWorkInfo
    schCourseWorkInfoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.schCourseWorkInfoId === action.payload.schCourseWorkInfo.schCourseWorkInfoId) {
          return action.payload.schCourseWorkInfo;
        }
        return entity;
      });
    },
    // deleteSchCourseWorkInfo
    schCourseWorkInfoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.schCourseWorkInfoId !== action.payload.schCourseWorkInfoId);
    },
    // deleteSchCourseWorkInfos
    schCourseWorkInfosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.schCourseWorkInfoId)
      );
    },
  }
});
