import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SchThesisesUIHelpers";

const SchThesisesUIContext = createContext();

export function useSchThesisesUIContext() {
  return useContext(SchThesisesUIContext);
}

export const SchThesisesUIConsumer = SchThesisesUIContext.Consumer;

export function SchThesisesUIProvider({ schThesisesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newSchThesisButtonClick: schThesisesUIEvents.newSchThesisButtonClick,
    openEditSchThesisPage: schThesisesUIEvents.openEditSchThesisPage,
    openDeleteSchThesisDialog: schThesisesUIEvents.openDeleteSchThesisDialog,
    openDeleteSchThesisesDialog: schThesisesUIEvents.openDeleteSchThesisesDialog,
    openFetchSchThesisesDialog: schThesisesUIEvents.openFetchSchThesisesDialog,
  };

  return (
    <SchThesisesUIContext.Provider value={value}>
      {children}
    </SchThesisesUIContext.Provider>
  );
}
