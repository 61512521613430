import axios from "axios";

export const SCH_JOB_INFOS_URL = `${process.env.REACT_APP_API_URL}/api/SchPayReceipt`;

// CREATE =>  POST: add a new schPayReceipt to the server
export function createSchPayReceipt(schPayReceipt) {
  return axios.post(SCH_JOB_INFOS_URL, schPayReceipt);
}

// READ
export function getAllSchPayReceipts() {
  return axios.get(SCH_JOB_INFOS_URL);
}

export function getSchPayReceiptById(schPayReceiptId) {
  return axios.get(`${SCH_JOB_INFOS_URL}/${schPayReceiptId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchPayReceipts(queryParams) {
  return axios.post(`${SCH_JOB_INFOS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSchPayReceipt(schPayReceipt) {
  return axios.put(`${SCH_JOB_INFOS_URL}/${schPayReceipt.schPayReceiptId}`, schPayReceipt);
}

// // UPDATE Status
// export function updateStatusForSchPayReceipts(ids, status) {
//   return axios.post(`${SCH_JOB_INFOS_URL}/updateStatusForSchPayReceipts`, {
//     ids,
//     status
//   });
// }

// DELETE => delete the schPayReceipt from the server
export function deleteSchPayReceipt(schPayReceiptId) {
  return axios.delete(`${SCH_JOB_INFOS_URL}/${schPayReceiptId}`);
}

// DELETE SchPayReceipts by ids
export function deleteSchPayReceipts(ids) {
  return axios.post(`${SCH_JOB_INFOS_URL}/deleteSchPayReceipts`, { ids });
}
